import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { dataSourcesActions } from "../../_store";
import { history } from '../../_helpers';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressSpinner } from 'primereact/progressspinner';
import DataSource from './DataSource';

const selectDataSources = createSelector(
  [state => state.dataSources.data, (_, selectedDataSources) => selectedDataSources],
  (dataSources, selectedDataSources) => {
    return dataSources.filter(dataSource => selectedDataSources.includes(dataSource.id));
  }
);

export const DataSourceManager = () => {
  const dispatch = useDispatch();
  const dataSourcesData = useSelector((state) => state.dataSources.data || []);

  const { status: getDataSourcesStatus, error: getDataSourcesError } = useSelector((state) => state.dataSources.getDataSources);
  const { status: createDataSourceStatus, error: createDataSourceError } = useSelector((state) => state.dataSources.createDataSource);

  const [dataSourceOptions, setDataSourceOptions] = useState([]);
  const [selectedDataSources, setSelectedDataSources] = useState([]);

  const reduxDataSources = useSelector(state => selectDataSources(state, selectedDataSources));

  useEffect(() => {
    if ((!getDataSourcesStatus || getDataSourcesStatus === 'idle') && dataSourcesData.length === 0) {
      dispatch(dataSourcesActions.getDataSources());
    }
  }, [getDataSourcesStatus, dataSourcesData, dispatch]);

  useEffect(() => {
    if (getDataSourcesStatus === 'succeeded' && dataSourcesData) {
      const options = dataSourcesData.map(ds => ({
        label: ds.name,
        value: ds.id
      }));
      setDataSourceOptions(options);

      const params = new URLSearchParams(window.location.search);
      const dataSourceIdsFromUrl = params.get('dataSourceIds') ? params.get('dataSourceIds').split(',') : [];

      let selected;
      if (dataSourceIdsFromUrl.length > 0) {
        const allIds = [...dataSourceIdsFromUrl];
        selected = options.filter(option => allIds.includes(option.value.toString())).map(option => option.value);
      } else {
        selected = options.map(option => option.value);
      }

      setSelectedDataSources(selected);
    } else if (getDataSourcesStatus === 'error') {
      setDataSourceOptions([]);  // Clear options or set to a default that indicates error
      setSelectedDataSources([]); // Reset selection
    }
  }, [dataSourcesData, getDataSourcesStatus]);

  const handleDataSourceDropdownChange = (e) => {
    setSelectedDataSources(e.value);
    const currentUrl = new URL(window.location);
    const params = new URLSearchParams(currentUrl.search);
    
    // Update URL with new selections
    if (e.value.length > 0) {
      params.set('dataSourceIds', e.value.join(','));
    } else {
      params.delete('dataSourceIds');
    }

    history.navigate(`${currentUrl.pathname}?${params.toString()}`, { replace: true });
  };

  const handleDataSourceAdd = () => {
    dispatch(dataSourcesActions.createDefaultDataSource());
  };
  
  const ErrorDisplay = ({ errors }) => {
    const validErrors = errors.filter(error => error);
    if (validErrors.length === 0) return null;
    return (
      <div className="text-danger">
        {validErrors.map((error, index) => (
          <small key={index} className="d-block">Error: {error}</small>
        ))}
      </div>
    );
  };

  const startToolbarContents = (
    <React.Fragment>      
      <MultiSelect 
        id="source-dropdown"
        value={selectedDataSources} 
        options={dataSourceOptions} 
        display="chip"
        selectAllLabel="All data sources"
        placeholder="Select a data source"
        onChange={handleDataSourceDropdownChange} 
        loading={getDataSourcesStatus === 'loading' }
        disabled={getDataSourcesStatus === 'loading' || getDataSourcesStatus === 'failed'}
        className='w-100'
      />
    </React.Fragment>
  );

  const endToolbarContents = (
    <React.Fragment>
      <Button label="Add" 
        onClick={handleDataSourceAdd} 
        text 
        raised 
        className="me-2" 
        disabled={createDataSourceStatus === 'loading'} 
        loading={createDataSourceStatus === 'loading'} 
      />
      {/* TODO: Implement reset functionality
      <Button label="Reset" onClick={handleReset} text raised severity="danger" /> 
      */}
    </React.Fragment>
  );
  
  return (
    <div className="d-flex flex-column flex-grow-1 pb-3 mt-3">
      <div className="mb-3 position-relative">
        <label className="position-absolute" style={{ top: 3, left: 30, fontSize: ".9em", fontWeight: "bold", color: "rgb(107, 114, 128)" }}>Data Sources</label>
        <Toolbar start={startToolbarContents} end={endToolbarContents} className="d-flex flex-nowrap toolbar-start-grow py-4" />
        <ErrorDisplay errors={[getDataSourcesError, createDataSourceError]} />
      </div>
      <div className="flex-grow-1 d-flex flex-column" style={{ height:0, minHeight: 0, overflowY: 'auto' }}>
        {getDataSourcesStatus === 'loading' ? (
          <div className="d-flex align-items-center justify-content-center h-100">
            <ProgressSpinner />
          </div>
        ) : reduxDataSources.length > 0 ? (
          reduxDataSources.map((dataSource) => (
            <DataSource key={dataSource.id} dataSource={dataSource} />
          ))
        ) : (
          <div className="d-flex align-items-center justify-content-center h-100">
            <h3>No data sources to display</h3>
          </div>
        )}
      </div>
    </div>
  );
};
