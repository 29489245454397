import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers";

const name = "preAuthorizations";

const initialState = {
  data: [],
  preAuthorizationDetails: null,
  getPreAuthorizations: {
    status: "idle",
    error: null,
  },
  getPreAuthorizationDetails: {
    status: "idle",
    error: null,
  },
  reviewPreAuthorization: {
    status: "idle",
    error: null,
    reviewType: null,
  },
};

// extra actions
const baseUrl = `${process.env.REACT_APP_API_URL ?? ""}/api`;
const getPreAuthorizations = createAsyncThunk(
  `${name}/getPreAuthorizations`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.get(`${baseUrl}/pre-authorizations`);
      return response;
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  },
);

const getPreAuthorizationDetails = createAsyncThunk(
  `${name}/getPreAuthorizationDetails`,
  async ({ preAuthorizationId }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.get(`${baseUrl}/pre-authorizations/${preAuthorizationId}`);
      return response;
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  },
);

const reviewPreAuthorization = createAsyncThunk(
  `${name}/approvePreAuthorization`,
  async ({ preAuthorizationId, preAuthorizationReview }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.patch(`${baseUrl}/pre-authorizations/${preAuthorizationId}/review`, preAuthorizationReview);
      return response;
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  }
);

const preAuthorizationsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // getPreAuthorizations
      .addCase(getPreAuthorizations.pending, (state) => {
        state.getPreAuthorizations = { status: 'loading', error: null };
      })
      .addCase(getPreAuthorizations.fulfilled, (state, action) => {
        state.data = action.payload.message;
        state.getPreAuthorizations.status = "succeeded";
      })
      .addCase(getPreAuthorizations.rejected, (state, action) => {
        const { error } = action.payload;
        state.getPreAuthorizations = { status: 'failed', error: error };
      })

      // getPreAuthorizationDetails
      .addCase(getPreAuthorizationDetails.pending, (state) => {
        state.getPreAuthorizationDetails = { status: 'loading', error: null };
      })
      .addCase(getPreAuthorizationDetails.fulfilled, (state, action) => {
        state.preAuthorizationDetails = action.payload.message;
        state.getPreAuthorizationDetails.status = "succeeded";
      })
      .addCase(getPreAuthorizationDetails.rejected, (state, action) => {
        const { error } = action.payload;
        state.getPreAuthorizationDetails = { status: 'failed', error: error };
      });

    // reviewPreAuthorization
    builder
      .addCase(reviewPreAuthorization.pending, (state, action) => {
        const { reviewType } = action.meta.arg.preAuthorizationReview;
        state.reviewPreAuthorization = { status: 'loading', error: null, reviewType: reviewType };
      })
      .addCase(reviewPreAuthorization.fulfilled, (state, action) => {
        const { statusLevel } = action.payload.message;
        state.preAuthorizationDetails.statusLevel = statusLevel;
        state.reviewPreAuthorization.status = 'succeeded';
      })
      .addCase(reviewPreAuthorization.rejected, (state, action) => {
        const { error } = action.payload;
        state.reviewPreAuthorization.status = 'failed';
        state.reviewPreAuthorization.error = error;
      });
  },
});

export const preAuthorizationsReducer = preAuthorizationsSlice.reducer;

// Bundle and export the actions
export const preAuthorizationsActions = {
  getPreAuthorizations,
  getPreAuthorizationDetails,
  reviewPreAuthorization,
};
