import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';

import { Input } from '../components/Input';
import {
  email_validation,
  password_validation,
} from '../utils/inputValidations';
import { history, isTokenExpired } from '../_helpers';
import { authActions } from '../_store';
import { Authentication } from './Authentication';
import { ErrorDisplay } from '../utils';

export function Login() {
  const dispatch = useDispatch();
  const { authUser, error: authError, navigateToNewPassword, userTokenAfterAuth } = useSelector(x => x.auth);
  const { error: refreshTokenError } = useSelector(x => x.auth.refreshToken);
  const { error: logoutError } = useSelector(x => x.auth.logout);

  const methods = useForm();
  let isSubmitting = methods.formState.isSubmitting;

  useEffect(() => {
    if (navigateToNewPassword) {
      history.navigate('/new-password-required');
      dispatch(authActions.resetNavigateToNewPassword());
      return;
    }

    if (userTokenAfterAuth != null) {
      localStorage.setItem('authUser', userTokenAfterAuth);
      dispatch(authActions.resetUserTokenAfterAuth());
      return;
    }
    
    // redirect to home if already logged in
    if (!isTokenExpired(authUser)) {
      history.navigate('/');
    }
  }, [authUser, navigateToNewPassword, userTokenAfterAuth, dispatch]);

  function onSubmit({ email, password }) {
    return dispatch(authActions.login({ email, password }));
  }

  return (
    <Authentication>
      <div className="auth-container card border-0 shadow strong rounded w-100">
        <div className="card-body p-5 text-center">
          <FormProvider {...methods}>
            <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
              <h2 className="mb-5">Login</h2>
              {/* email */}
              <Input
                {...email_validation}
                id={'signin-user-email'}
                label={'Email'}
              />
              {/* password */}
              <Input
                {...password_validation}
                id={'signin-user-password'}
                label={'Password'}
              />

              {/* 2 column grid layout */}
              <div className="row mb-4">
                <div className="col-md-12 d-flex justify-content-center">
                  <a href="#!" className="link-primary">
                    Forgot password?
                  </a>
                </div>
              </div>

              {/* Submit button */}
              <button
                type="submit"
                className="btn btn-primary btn-block btn-lg shadow"
                disabled={isSubmitting}
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                Login
              </button>
              <ErrorDisplay errors={[authError, refreshTokenError, logoutError]} />
            </form>
          </FormProvider>
        </div>
      </div>
    </Authentication>
  );
}
