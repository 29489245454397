import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import update from 'immutability-helper';
import { workflowsActions, rulesActions } from "../../_store";
import { history } from '../../_helpers';
import { Toolbar } from 'primereact/toolbar';
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { ProgressSpinner } from 'primereact/progressspinner';
import { normalizeJson } from '../../_helpers';
import { createSelector } from 'reselect';

import Workflow from './Workflow';

const selectWorkflowsWithRules = createSelector(
  [state => state.workflows.data, state => state.rules.ruleMap, (_, selectedWorkflows) => selectedWorkflows],
  (workflows, ruleMap, selectedWorkflows) => {
    return workflows.map(workflow => ({
      ...workflow,
      rules: (ruleMap[`workflow-${workflow.id}`]?.rules || workflow.rules || []).map(rule => constructRuleData(rule.id, ruleMap))
    })).filter(workflow => selectedWorkflows.includes(workflow.id));
  }
);

function formatWorkflow(workflow) {
  return {
    WorkflowName: workflow.workflowName,
    Rules: workflow.rules?.map(r => r && formatRule(r)) || [],
    GlobalParams: workflow.globalParams.map(p => p && formatParams(p)),
  };
}

function formatRule(rule) {
  return {
    RuleName: rule.ruleName,
    Enabled: rule.enabled,
    Expression: rule.expression,
    Actions: rule.actionsJson,
    SuccessEvent: rule.successEvent,
    ErrorMessage: rule.errorMessage,
    Operator: rule.operator,
    Rules: rule.rules.map(subRule => subRule && formatRule(subRule)),
    LocalParams: rule.localParams.map(p => p && formatParams(p)),
  };
}

function formatParams(params) {
  return {
    Name: params.name,
    Expression: params.expression,
  };
}

//workflow.rules
function constructRuleData(ruleId, ruleMap) {
  const rule = ruleMap[`rule-${ruleId}`];
  if (!rule) return null;

  // Recursively gather subrules, if any
  if (rule.rules && rule.rules.length > 0) {
    return {
      ...rule,
      rules: rule.rules.map(subRule => constructRuleData(subRule.id, ruleMap))
    };
  }

  return { ...rule };
}

export const RulesEngineEditor = () => {
  const dispatch = useDispatch();
  const workflowsData = useSelector((state) => state.workflows.data || []);
  const { status: getWorkflowStatus, error: getWorkflowError } = useSelector((state) => state.workflows.getWorkflows);
  const { status: createWorkflowStatus, error: createWorkflowError } = useSelector((state) => state.workflows.createWorkflow);
  const { error: updateWorkflowsSequenceError } = useSelector((state) => state.workflows.updateWorkflowsSequence);

  const [workflowOptions, setWorkflowOptions] = useState([]);

  const [selectedWorkflows, setSelectedWorkflows] = useState([]);
  const reduxWorkflows = useSelector(state => selectWorkflowsWithRules(state, selectedWorkflows));
  const [workflowsSeq, setWorkflowsSeq] = useState([]);

  const [workflowJson, setWorkflowJson] = useState('');

  // Ref to track if it's the first render
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
    else {
      return;
    }

    if (!getWorkflowStatus || getWorkflowStatus === 'idle') {
      dispatch(workflowsActions.getWorkflows());
    }
  }, [getWorkflowStatus, workflowsData.length, dispatch]);

  useEffect(() => {
    if (getWorkflowStatus === 'succeeded') {
      dispatch(rulesActions.initialize(workflowsData));  // Initialize rules with workflows data
      dispatch(workflowsActions.resetGetWorkflowStatus());
    }
  }, [getWorkflowStatus, workflowsData, dispatch]);

  useEffect(() => {
    return () => {
      // Reset isFirstRender when the component unmounts
      isFirstRender.current = true;
    };
  }, []);

  useEffect(() => {
    if (getWorkflowStatus !== 'failed' && workflowsData) {
      const options = workflowsData.map(wf => ({
        label: wf.workflowName,
        value: wf.id
      }));
      setWorkflowOptions(options);

      const params = new URLSearchParams(window.location.search);
      const workflowIdsFromUrl = params.get('workflowIds') ? params.get('workflowIds').split(',') : [];

      let selected;
      if (workflowIdsFromUrl.length > 0) {
        const allIds = [...workflowIdsFromUrl];
        selected = options.filter(option => allIds.includes(option.value.toString())).map(option => option.value);
      } else {
        selected = options.map(option => option.value);
      }

      setSelectedWorkflows(selected);
    } else if (getWorkflowStatus === 'error') {
      setWorkflowOptions([]);  // Clear options or set to a default that indicates error
      setSelectedWorkflows([]); // Reset selection
    }
  }, [workflowsData, getWorkflowStatus]);

  useEffect(() => {
    setWorkflowsSeq(reduxWorkflows);
  }, [reduxWorkflows]);

  useEffect(() => {
    const rawJson = JSON.stringify(workflowsSeq.map(wf => formatWorkflow(wf)));
    const normalizedJson = normalizeJson(rawJson);
    setWorkflowJson(normalizedJson);
  }, [workflowsSeq]);

  const handleWorkflowDropdownChange = (e) => {
    setSelectedWorkflows(e.value);
    const currentUrl = new URL(window.location);
    const params = new URLSearchParams(currentUrl.search);

    // Update URL with new selections
    if (e.value.length > 0) {
      params.set('workflowIds', e.value.join(','));
    } else {
      params.delete('workflowIds');
    }

    history.navigate(`${currentUrl.pathname}?${params.toString()}`, { replace: true });
  };

  const handleWorkflowAdd = () => {
    dispatch(workflowsActions.createDefaultWorkflow());
  };

  const startToolbarContents = (
    <React.Fragment>
      <MultiSelect
        id="workflow-dropdown"
        value={selectedWorkflows}
        options={workflowOptions}
        display="chip"
        placeholder="Select a Workflow"
        selectAllLabel="All workflows"
        onChange={handleWorkflowDropdownChange}
        loading={getWorkflowStatus === 'loading'}
        disabled={getWorkflowStatus === 'loading' || getWorkflowStatus === 'failed'}
        className='w-100'
      />
    </React.Fragment>
  );

  const endToolbarContents = (
    <React.Fragment>
      <Button label="Add"
        onClick={handleWorkflowAdd}
        text
        raised
        className="me-2"
        disabled={createWorkflowStatus === 'loading'}
        loading={createWorkflowStatus === 'loading'}
      />
      {/* TODO: Implement download, import, and reset functionality
      <Button label="Download" onClick={handleDownload} text raised className="me-2" />
      <Button label="Import" onClick={handleImport} text raised className="me-2" />
      <Button label="Reset" onClick={handleReset} text raised severity="danger" /> 
      */}
    </React.Fragment>
  );

  const ErrorDisplay = ({ errors }) => {
    // Filter out null or undefined errors and map over them
    const validErrors = errors.filter(error => error);
    if (validErrors.length === 0) return null;
    return (
      <div className="text-danger">
        {validErrors.map((error, index) => (
          <small key={index} className="d-block">Error: {error}</small> // Key is index because error messages might not be unique
        ))}
      </div>
    );
  };

  const moveWorkflow = useCallback((dragIndex, hoverIndex) => {
    setWorkflowsSeq(prevWorkflows =>
      update(prevWorkflows, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevWorkflows[dragIndex]],
        ],
      })
    );
  }, []);
  return (
    <div className="d-flex flex-column flex-grow-1 pb-3 mt-3">
      <div className="mb-3 position-relative">
        <label className="position-absolute" style={{ top: 3, left: 30, fontSize: ".9em", fontWeight: "bold", color: "rgb(107, 114, 128)" }}>Workflows</label>
        <Toolbar start={startToolbarContents} end={endToolbarContents} className="d-flex flex-nowrap toolbar-start-grow py-4" />
        <ErrorDisplay errors={[getWorkflowError, createWorkflowError, updateWorkflowsSequenceError]} />
      </div>
      <Splitter className="flex-grow-1 bg-transparent" style={{ height: 0, minHeight: 0 }}>
        <SplitterPanel className="d-flex flex-column flex-grow-1" size={70} minSize={50} style={{ overflowY: 'auto' }}>
          {getWorkflowStatus === 'loading' ? (
            <div className="d-flex align-items-center justify-content-center h-100">
              <ProgressSpinner />
            </div>
          ) : workflowsSeq.length > 0 ? (
            workflowsSeq.map((workflow, index) => (
              <Workflow key={workflow.id} index={index} workflow={workflow} moveWorkflow={moveWorkflow} workflowsSeq={workflowsSeq} />
            ))
          ) : (
            <div className="d-flex align-items-center justify-content-center h-100">
              <h3>No workflows to display</h3>
            </div>
          )}
        </SplitterPanel>
        <SplitterPanel className="d-flex flex-column bg-white" size={30} minSize={20} style={{ overflowY: 'auto' }}>
          <pre>{workflowJson}</pre>
        </SplitterPanel>
      </Splitter>
    </div>
  );
};
