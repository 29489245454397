export const applyFilters = (data, activeFiltering) => {
  if (!activeFiltering) return data;

  return data.filter((item) =>
    Object.entries(activeFiltering).every(([key, value]) => {
      if (value === undefined || value === null) return true;
      if (value instanceof Date) {
        return new Date(item[key]).toDateString() === value.toDateString();
      }
      return item[key].toString().toLowerCase().includes(value.toString().toLowerCase());
    })
  );
};