import { Login, NewPasswordRequired } from './auth';
import {
  PrivateRoute,
  RulesEngineEditor,
  DataSourceManager,
  LoginWelcome,
  ReportViewer,
  PreAuthorizations,
  PreAuthorizationForm
} from './components';

const AppRoutes = [
  {
    index: true,
    element: (
      <PrivateRoute>
        <LoginWelcome />
      </PrivateRoute>
    ),
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'new-password-required',
    element: (
      <NewPasswordRequired />
    ),
  },
  {
    path: "/rules-engine-editor",
    element: (
      <PrivateRoute groupsAllowed={['Administrators']}>
        <RulesEngineEditor />
      </PrivateRoute>
    ),
  },
  {
    path: "/source-manager",
    element: (
      <PrivateRoute groupsAllowed={['Administrators']}>
        <DataSourceManager />
      </PrivateRoute>
    ),
  },
  {
    path: "/reports/:reportId",
    element: (
      <PrivateRoute>
        <ReportViewer />
      </PrivateRoute>
    ),
  },
  {
    path: "/pre-authorizations",
    element: (
      <PrivateRoute groupsAllowed={['Administrators', 'TPA-Monitors', 'TPA-Dentists']}>
        <PreAuthorizations />
      </PrivateRoute>
    ),
  },
  {
    path: "/pre-authorizations/:preAuthorizationId",
    element: (
      <PrivateRoute groupsAllowed={['Administrators', 'TPA-Monitors', 'TPA-Dentists']}>
        <PreAuthorizationForm />
      </PrivateRoute>
    ),
  },
];

export default AppRoutes;
