import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';
import { dataSourcesActions, dataConnectionsActions } from "../../_store";
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import DataConnection from './DataConnection';
import { Dialog } from 'primereact/dialog';
import { TabView, TabPanel } from 'primereact/tabview';
import DatabaseSourceConfiguration from './DatabaseSourceConfiguration';
import DataSourceMappingsManager from './DataSourceMappingsManager';

// Memoized selectors
const selectDataConnectionById = createSelector(
  (state) => state.dataConnections.data,
  (_, dataConnectionId) => dataConnectionId,
  (dataConnectionsData, dataConnectionId) => {
    return dataConnectionsData.find(dc => dc.id === dataConnectionId) || [];
  }
);

const selectDataSourceChangeNameState = createSelector(
  state => state.dataSources.changeDataSourceNameStates,
  (_, dataSourceId) => dataSourceId,
  (changeDataSourceNameStates, dataSourceId) => changeDataSourceNameStates[dataSourceId] || { status: 'idle', error: null }
);

const selectDataSourceDeleteState = createSelector(
  state => state.dataSources.deleteDataSourceStates,
  (_, dataSourceId) => dataSourceId,
  (deleteDataSourceStates, dataSourceId) => deleteDataSourceStates[dataSourceId] || { status: 'idle', error: null }
);

const selectDataResultSetTypeState = createSelector(
  state => state.dataSources.changeDataResultSetTypeStates,
  (_, dataSourceId) => dataSourceId,
  (changeDataResultSetTypeStates, dataSourceId) => changeDataResultSetTypeStates[dataSourceId] || { status: 'idle', error: null }
);

const selectDataSourceConnectionChangeState = createSelector(
  state => state.dataSources.changeDataSourceConnectionStates,
  (_, dataSourceId) => dataSourceId,
  (changeDataSourceConnectionStates, dataSourceId) => changeDataSourceConnectionStates[dataSourceId] || { status: 'idle', error: null }
);

const selectDataSourceConnectionDeleteState = createSelector(
  state => state.dataSources.deleteDataSourceConnectionStates,
  (_, dataSourceId) => dataSourceId,
  (deleteDataSourceConnectionStates, dataSourceId) => deleteDataSourceConnectionStates[dataSourceId] || { status: 'idle', error: null }
);

const DATA_CONNECTION_TYPE = {
  DATABASE: 0,
};

const DataSource = ({ dataSource }) => {
  const dispatch = useDispatch();
  const dataSourceChangeNameState = useSelector(state => selectDataSourceChangeNameState(state, dataSource.id));
  const dataSourceDeleteState = useSelector(state => selectDataSourceDeleteState(state, dataSource.id));
  const dataResultSetTypeState = useSelector(state => selectDataResultSetTypeState(state, dataSource.id));
  const [inputDataSourceName, setInputDataSourceNameValue] = useState(dataSource.name);
  const [inputDsNameValidationError, setInputDsNameValidationError] = useState(null);
  const dataResultSetOptions = useSelector((state) => state.dataSources.dataResultSetTypes || []);
  const dataSourcesData = useSelector(state => state.dataSources.data);

  // Data Connection
  const dataConnection = useSelector(state => selectDataConnectionById(state, dataSource.dataConnectionId));
  const dataConnectionsData = useSelector((state) => state.dataConnections.data || []);
  const [dataConnectionOptions, setDataConnectionOptions] = useState([]);
  const dataConnectionTypeOptions = useSelector((state) => state.dataSources.dataConnectionTypes || []);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [editingConnection, setEditingConnection] = useState(null);
  const [addOrModifyDataConnection, setAddOrModifyDataConnection] = useState('Add');
  const dataConnectionChangeState = useSelector(state => selectDataSourceConnectionChangeState(state, dataSource.id));
  const dataConnectionDeleteState = useSelector(state => selectDataSourceConnectionDeleteState(state, dataSource.id));
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const [affectedDataSources, setAffectedDataSources] = useState([]);
  const [additionalCount, setAdditionalCount] = useState(0);

  // Database Source Configuration
  const [sqlStatement, setSqlStatement] = useState(dataSource.sqlStatement);
  const [inputParameters, setInputParameters] = useState(dataSource.inputParameters);

  useEffect(() => {
    setInputDataSourceNameValue(dataSource.name);
  }, [dataSource.name]);

  useEffect(() => {
    const options = dataConnectionsData.map(dc => ({
      label: dc.name,
      value: dc.id,
    }));
    setDataConnectionOptions(options);
  }, [dataConnectionsData]);

  const handleDataSourceNameChange = useCallback((e) => {
    const newName = e.target.value.replace(/[^a-zA-Z0-9\s-_]/g, ''); // Remove most of special characters
    setInputDsNameValidationError(null);
    setInputDataSourceNameValue(newName);
  }, []);

  const handleDataSourceNameBlur = useCallback((e) => {
    const inputValue = e.target.value.trim();
    setInputDataSourceNameValue(inputValue);
    if (inputValue.length < 1 || inputValue.length > 100) {
      setInputDsNameValidationError('Name must be between 1 and 100 characters');
      return;
    }
    if (inputValue === dataSource.name) return;

    dispatch(dataSourcesActions.changeDataSourceName({ dataSourceId: dataSource.id, newName: inputValue }));
  }, [dispatch, dataSource]);

  const handleDataSourceDelete = () => {
    dispatch(dataSourcesActions.deleteDataSource({ dataSourceId: dataSource.id }));
  };

  const handleDataResultSetTypeChange = (e) => {
    dispatch(dataSourcesActions.changeDataResultSetType({ dataSourceId: dataSource.id, newDataResulSetType: e.value }));
  };

  const handleAddConnection = () => {
    setEditingConnection(null);
    setAddOrModifyDataConnection('Add');
    setIsDialogVisible(true);
  };

  const handleModifyConnection = () => {
    if (!dataConnection) return;
    setEditingConnection(dataConnection);
    setAddOrModifyDataConnection('Modify');
    setIsDialogVisible(true);
  };

  const handleDataConnectionChange = (e) => {
    dispatch(dataSourcesActions.changeDataSourceConnection({ dataSourceId: dataSource.id, newConnectionId: e.value }));
  };

  const handleDataConnectionDelete = () => {
    const dataConnectionId = dataSource.dataConnectionId;
    if (!dataConnectionId) return;

    // Fetch all data sources, besides this data source, using the selected data connection
    const affected = dataSourcesData.filter(ds => ds.id !== dataSource.id && ds.dataConnection?.id === dataConnectionId);
    if (affected.length > 0) {
      setAffectedDataSources(affected.slice(0, 5));
      setAdditionalCount(affected.length - 5);
      setIsWarningVisible(true);
    } else {
      dispatch(dataConnectionsActions.deleteConnection({ dataSourceId: dataSource.id, dataConnectionId }));
    }
  };

  const confirmDelete = () => {
    const dataConnectionId = dataSource.dataConnectionId;
    setIsWarningVisible(false);
    dispatch(dataConnectionsActions.deleteConnection({ dataSourceId: dataSource.id, dataConnectionId }));
  };

  const hideWarning = () => {
    setIsWarningVisible(false);
  };

  return (
    <Panel
      id={`data-source-${dataSource.id}`}
      header={`Data Source: ${dataSource.name}`}
      toggleable
      className="mb-1 small-content-padding"
      pt={{
        content: {
          style: {
            padding: 0,
            minHeight: '600px',
          }
        }
      }}
    >
      <TabView>
        <TabPanel header="General" headerClassName="small">
          <label htmlFor={`data-source-name-${dataSource.id}`} className="small ms-2">Data Source Name</label>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column flex-grow-1">
              <InputText
                id={`data-source-name-${dataSource.id}`}
                disabled={dataSourceChangeNameState.status === 'loading'}
                placeholder={dataSourceChangeNameState.status === 'loading' ? 'Saving...' : ''}
                value={inputDataSourceName}
                onChange={handleDataSourceNameChange}
                onBlur={handleDataSourceNameBlur}
                invalid={dataSourceChangeNameState.status === 'failed'}
                className='p-inputtext-sm w-100'
              />
            </div>
            <Button
              onClick={handleDataSourceDelete}
              tooltip="Delete Data Source"
              text
              raised
              severity="danger"
              className="ms-3 py-2"
              icon="pi pi-trash"
              disabled={dataSourceDeleteState.status === 'loading'}
              loading={dataSourceDeleteState.status === 'loading'}
            >
            </Button>
          </div>
          {(dataSourceChangeNameState.error || dataSourceDeleteState.error || inputDsNameValidationError) && (
            <div className="d-flex text-danger justify-content-between">
              {inputDsNameValidationError && <small className="text-start">{inputDsNameValidationError}</small>}
              {dataSourceChangeNameState.error && <small className="text-start">{dataSourceChangeNameState.error}</small>}
              {dataSourceDeleteState.error && <small className="text-end">{dataSourceDeleteState.error}</small>}
            </div>
          )}
          <Divider align="left" className="mb-2">
            <div className="inline-flex align-items-center small">
              <b>Data Connection</b>
            </div>
          </Divider>
          <div className="d-flex justify-content-between px-3">
            <div className="d-flex flex-column flex-grow-1">
              <Dropdown
                id={`data-connection-${dataSource.id}`}
                options={dataConnectionOptions}
                value={dataSource.dataConnectionId}
                onChange={handleDataConnectionChange}
                disabled={dataConnectionChangeState.status === 'loading'}
                placeholder={dataConnectionChangeState.status === 'loading' ? 'Saving...' : 'Select a connection'}
                invalid={dataConnectionChangeState.status === 'failed'}
                className='w-100 p-inputtext-sm'
                style={{ fontSize: '0.875em' }}
              />
            </div>
            <Button
              onClick={handleAddConnection}
              tooltip="Add Connection"
              text
              raised
              className="ms-3 py-2"
              icon="pi pi-plus"
            />
            <Button
              onClick={handleModifyConnection}
              tooltip="Modify Connection"
              text
              raised
              className="ms-2 py-2"
              severity="info"
              icon="pi pi-pencil"
              disabled={!dataSource.dataConnectionId}
            />
            <Button
              onClick={handleDataConnectionDelete}
              tooltip="Delete Connection"
              text
              raised
              severity="danger"
              className="ms-2 py-2"
              icon="pi pi-trash"
              disabled={!dataSource.dataConnectionId || dataConnectionDeleteState.status === 'loading'}
              loading={dataConnectionDeleteState.status === 'loading'}
            />
          </div>
          {dataConnectionChangeState.error && <small className="d-block text-danger small">{dataConnectionChangeState.error}</small>}
          {dataConnectionDeleteState.error && <small className="d-block text-danger small">{dataConnectionDeleteState.error}</small>}
          <Divider align="left" className="mb-2">
            <div className="inline-flex align-items-center small">
              <b>Data Result Set</b>
            </div>
          </Divider>
          <div className="d-flex justify-content-between px-3">
            <div className="d-flex flex-column flex-grow-1">
              <Dropdown
                id={`data-result-set-${dataSource.id}`}
                placeholder={dataResultSetTypeState.status === 'loading' ? 'Saving...' : 'Select a data result set'}
                options={dataResultSetOptions}
                disabled={dataResultSetTypeState.status === 'loading'}
                value={dataSource.dataResultSetType}
                onChange={handleDataResultSetTypeChange}
                optionValue="value"
                invalid={dataResultSetTypeState.status === 'failed'}
                className='w-100 p-inputtext-sm'
              />
            </div>
          </div>
          {dataResultSetTypeState.error && <small className="d-block text-danger small">{dataResultSetTypeState.error}</small>}

          <DataConnection
            visible={isDialogVisible}
            onHide={() => setIsDialogVisible(false)}
            addOrModify={addOrModifyDataConnection}
            connectionTypeOptions={dataConnectionTypeOptions}
            connection={editingConnection}
          />

          <Dialog
            header="Delete data connection"
            visible={isWarningVisible}
            style={{ width: '50vw' }}
            modal
            footer={
              <div>
                <Button label="Cancel" icon="pi pi-times" onClick={hideWarning} className="p-button-text" />
                <Button label="Delete" icon="pi pi-check" onClick={confirmDelete} autoFocus />
              </div>
            }
            onHide={hideWarning}
          >
            <p>The following data sources will lose their data connection as well:</p>
            <ul>
              {affectedDataSources.map(ds => <li key={ds.id}>{ds.name}</li>)}
            </ul>
            {additionalCount > 0 && <p>... And {additionalCount} other data sources</p>}
          </Dialog>
        </TabPanel>
        <TabPanel header="Data Configuration" headerClassName="small">
          {dataConnection ? (
            dataConnection.dataConnectionType === DATA_CONNECTION_TYPE.DATABASE ? (
              <DatabaseSourceConfiguration
                dataSourceId={dataSource.id}
                sqlStatement={sqlStatement}
                setSqlStatement={setSqlStatement}
                inputParameters={inputParameters}
                setInputParameters={setInputParameters}
              />
            ) : (
              <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                <small className="text-muted">Not supported yet</small>
              </div>
            )
          ) : (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
              <small className="text-muted">Please select a data connection</small>
            </div>
          )}
        </TabPanel>
        <TabPanel header="Mappings" headerClassName="small">
          {dataConnection ? (
            dataConnection.dataConnectionType === DATA_CONNECTION_TYPE.DATABASE ? (
              dataSource.sqlStatement ? (
                <DataSourceMappingsManager dataSourceId={dataSource.id} />
              ) : (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                  <small className="text-muted">No SQL statement found</small>
                </div>
              )
            ) : (
              <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                <small className="text-muted">Select a supported data connection first</small>
              </div>
            )
          ) : (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
              <small className="text-muted">Select a data connection first</small>
            </div>
          )}
        </TabPanel>
      </TabView>
    </Panel>
  );
};

export default React.memo(DataSource);