import { useState, useEffect } from "react";
import { Container } from "reactstrap";
import { NavMenu } from "./NavMenu";
import { useLocation } from "react-router-dom";

export const Layout = (props) => {
  const location = useLocation();
  const [showNavMenu, setShowNavMenu] = useState(false);
  const hiddenNavRoutes = ["/login", "/new-password-required"];

  useEffect(() => {
    setShowNavMenu(!hiddenNavRoutes.includes(location.pathname));
  }, [location.pathname]);

  return (
    <>
      {showNavMenu && <NavMenu />}
      <Container fluid> {props.children}</Container>
    </>
  );
};
