import { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';

const DatabaseConnection = ({ connection, onChange, onError }) => {
  const [serverName, setServerName] = useState(connection?.serverName || '');
  const [port, setPort] = useState(connection?.port);
  const [userName, setUserName] = useState(connection?.userName || '');
  const [password, setPassword] = useState(connection?.password || '');
  const [useIamDbAuth, setUseIamDbAuth] = useState(connection?.useIamDbAuth || false);
  const [databaseName, setDatabaseName] = useState(connection?.databaseName || '');
  const [otherConnectionSettings, setOtherConnectionSettings] = useState(connection?.otherConnectionSettings || '');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const newErrors = {};
    if (!userName) newErrors.userName = 'Username is required';
    if (!useIamDbAuth && !password) newErrors.password = 'Password is required';

    setErrors(newErrors);
    onError(newErrors);
    onChange({ serverName, port, userName, password, useIamDbAuth, databaseName, otherConnectionSettings });
  }, [serverName, port, userName, password, useIamDbAuth, databaseName, otherConnectionSettings, onChange, onError]);

  const toggleUseIamDbAuthCheck = (e) => {
    setUseIamDbAuth(e.checked);
    if (e.checked) {
      setPassword('');
    }
  };

  return (
    <div className="container d-flex flex-column gap-2">
      <div className="inline-flex flex-column">
        <label htmlFor="serverName" className="x-small">Server Name</label>
        <InputText
          id="serverName"
          value={serverName}
          onChange={(e) => setServerName(e.target.value)}
          className="w-100 small"
        />
      </div>
      <div className="inline-flex flex-column">
        <label htmlFor="port" className="x-small">Port</label>
        <InputNumber
          id="port"
          value={port}
          onChange={(e) => setPort(e.value)}
          useGrouping={false}
          maxFractionDigits={0}
          className="w-100"
          inputClassName="small"
        />
      </div>
      <div className="inline-flex flex-column">
        <label htmlFor="userName" className="x-small">User Name</label>
        <InputText
          id="userName"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          className="w-100 small"
        />
        {errors.userName && <small className="text-danger">{errors.userName}</small>}
      </div>
      <div className="inline-flex flex-column">
        <label htmlFor="password" className="x-small">Password</label>
        <Password
          id="password"
          value={password}
          disabled={useIamDbAuth}
          onChange={(e) => setPassword(e.target.value)}
          toggleMask
          feedback={false}
          className="w-100 small"
        />
        {errors.password && <small className="text-danger">{errors.password}</small>}
      </div>
      <div className="inline-flex flex-row">
        <Checkbox
          id="useIamDbAuth"
          checked={useIamDbAuth}
          onChange={toggleUseIamDbAuthCheck}
          className="small"
        />
        <label htmlFor="useIamDbAuth" className="x-small ms-2 align-bottom">Use IAM DB Authentication</label>
      </div>
      <div className="inline-flex flex-column">
        <label htmlFor="databaseName" className="x-small">Database Name</label>
        <InputText
          id="databaseName"
          value={databaseName}
          onChange={(e) => setDatabaseName(e.target.value)}
          className="w-100 small"
        />
      </div>
      <div className="inline-flex flex-column">
        <label htmlFor="otherConnectionSettings" className="x-small">Other connection settings (semicolon delimited)</label>
        <InputTextarea
          id="otherConnectionSettings"
          value={otherConnectionSettings}
          onChange={(e) => setOtherConnectionSettings(e.target.value)}
          className="w-100 small"
          rows={2}
          style={{ resize: 'none' }}
        />
      </div>
    </div>
  );
};

export default DatabaseConnection;
