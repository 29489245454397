import { useEffect, useState } from 'react';
import { useForm, Controller, useFieldArray, useWatch } from 'react-hook-form';
import { Panel } from 'primereact/panel';
import { Checkbox } from 'primereact/checkbox';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

import './DentalClaimForm.css';

export const DentalClaimForm = ({
  formValues,
  submitButton = { label: 'Submit', onClick: null },  // onClick = null here suggests that the default behavior to submit the form will be used
  rejectButton = null,
  reviewPreAuthorization = {},
  readOnly = false,
  attachments = null,
  extraFormProps = {
    setTotalFeeAutomatically: true,
    compareTotalFeesBetweenInputAndUpdates: false,
  },
}) => {
  const convertMissingTeethInfo = (missingTeethInfo) => {
    if (missingTeethInfo === null) {
      return null;
    }

    const teethInfoArray = new Array(32).fill(false);
    missingTeethInfo.forEach((toothNumber) => {
      // Adjust 1-based index to 0-based for the array
      teethInfoArray[toothNumber - 1] = true;
    });

    return teethInfoArray;
  };

  formValues = {
    ...formValues,
    missingTeethInfo: convertMissingTeethInfo(formValues.missingTeethInfo),
  };

  const currentDate = new Date();
  const emptyProcedure = {
    id: null,
    preAuthorizationFormId: formValues.id,
    date: null,
    area: '',
    toothSystem: '',
    toothNumber: '',
    toothSurface: '',
    procedureCode: '',
    diagnosisPointer: '',
    quantity: null,
    description: '',
    fee: null,
  };

  const { control, handleSubmit, setValue: setFormValue, getValues: getFormValues, register } = useForm({
    values: formValues,
    defaultValues: {
      statementOfActualServices: false,
      requestForPredeterminationPreauthorization: false,
      epsdtTitleXix: false,
      preAuthorizationNumber: '',
      mainPayerName: '',
      mainPayerAddress1: '',
      mainPayerAddress2: '',
      mainPayerCity: '',
      mainPayerState: '',
      mainPayerZipCode: '',
      otherCoverageDental: false,
      otherCoverageMedical: false,
      otherCoverageSubscriberFirstName: '',
      otherCoverageSubscriberLastName: '',
      otherCoverageSubscriberDob: null,
      otherCoverageSubscriberGenderM: false,
      otherCoverageSubscriberGenderF: false,
      otherCoverageSubscriberGenderU: false,
      otherCoverageSubscriberId: '',
      otherCoverageSubscriberPlanGroupNumber: '',
      otherCoverageRelationshipSelf: false,
      otherCoverageRelationshipSpouse: false,
      otherCoverageRelationshipDependant: false,
      otherCoverageRelationshipOther: false,
      otherCoveragePayerName: '',
      otherCoveragePayerAddress1: '',
      otherCoveragePayerAddress2: '',
      otherCoveragePayerCity: '',
      otherCoveragePayerState: '',
      otherCoveragePayerZipCode: '',
      subscriberFirstName: '',
      subscriberLastName: '',
      subscriberAddress1: '',
      subscriberAddress2: '',
      subscriberCity: '',
      subscriberState: '',
      subscriberZipCode: '',
      subscriberDob: null,
      subscriberGenderM: false,
      subscriberGenderF: false,
      subscriberGenderU: false,
      subscriberId: '',
      subscriberPlanGroupNumber: '',
      subscriberEmployerName: '',
      patientRelationshipSelf: false,
      patientRelationshipSpouse: false,
      patientRelationshipDependant: false,
      patientRelationshipOther: false,
      patientFirstName: '',
      patientLastName: '',
      patientAddress1: '',
      patientAddress2: '',
      patientCity: '',
      patientState: '',
      patientZipCode: '',
      patientDob: null,
      patientGenderM: false,
      patientGenderF: false,
      patientGenderU: false,
      patientId: '',
      procedures: formValues.procedures ?? Array.from({ length: 10 }, () => ({ ...emptyProcedure })),
      missingTeethInfo: formValues.missingTeethInfo ?? new Array(32).fill(false),
      diagnosisCodeListQualifier: null,
      diagnosisCode1: '',
      diagnosisCode2: '',
      diagnosisCode3: '',
      diagnosisCode4: '',
      otherFees1: null,
      otherFees2: null,
      totalFee: null,
      remarks: '',
      patientGuardianAuthorizationSignature: 'Signature on File',
      patientGuardianAuthorizationDate: new Date(),
      subscriberAuthorizationSignature: 'Signature on File',
      subscriberAuthorizationDate: new Date(),
      placeOfTreatment: null,
      enclosures: false,
      orthodonticsNo: false,
      orthodonticsYes: false,
      dateAppliancePlaced: null,
      monthsOfTreatment: null,
      prothesisReplacementNo: false,
      prothesisReplacementYes: false,
      dateOfPriorPlacement: null,
      treatmentResultingOccupational: false,
      treatmentResultingAuto: false,
      treatmentResultingOther: false,
      dateOfAccident: null,
      autoAccidentState: '',
      billingProviderFirstName: '',
      billingProviderLastName: '',
      billingProviderAddress1: '',
      billingProviderAddress2: '',
      billingProviderCity: '',
      billingProviderState: '',
      billingProviderZipCode: '',
      billingProviderNpi: '',
      billingProviderLicenseNumber: '',
      billingProviderSsnTin: '',
      billingProviderPhone: '',
      billingProviderAdditionalProviderId: '',
      treatingProviderSignature: '',
      treatingProviderCertifyDate: new Date(),
      treatingProviderNpi: '',
      treatingProviderLicenseNumber: '',
      treatingProviderAddress1: '',
      treatingProviderAddress2: '',
      treatingProviderCity: '',
      treatingProviderState: '',
      treatingProviderZipCode: '',
      treatingProviderSpecialtyCode: '',
      treatingProviderPhone: '',
      treatingProviderAdditionalProviderId: '',
      statusLevel: null,
    },
  });

  const { fields: procedures, update: updateProcedure } = useFieldArray({
    control,
    name: 'procedures'
  });

  const handleFormSubmit = (data) => {
    if (submitButton.onClick) {
      submitButton.onClick(data);
    }
  };

  const handleFormReject = (e) => {
    e.preventDefault();
    if (rejectButton.onClick) {
      rejectButton.onClick(getFormValues());
    }
  };

  const checkboxEditor = ({ labelBefore, checkBox, labelAfter }) => {
    return (
      <>
        {labelBefore && (
          <label htmlFor={checkBox.id} className={labelBefore.className}>
            {labelBefore.label}
          </label>
        )}
        <Controller
          name={checkBox.id}
          control={control}
          render={({ field }) => (
            <Checkbox
              inputId={field.name}
              checked={field.value}
              className={checkBox.className}
              disabled={readOnly}
              {...field}
            />
          )}
        />
        {labelAfter && (
          <label htmlFor={checkBox.id} className={labelAfter.className}>
            {labelAfter.label}
          </label>
        )}
      </>
    );
  };

  const inputTextEditor = ({ labelBefore, inputText, labelAfter }) => {
    return (
      <>
        {labelBefore && (
          <label htmlFor={inputText.id} className={labelBefore.className}>
            {labelBefore.label}
          </label>
        )}
        <Controller
          name={inputText.id}
          control={control}
          defaultValue=''
          render={({ field }) => {
            field.value ??= '';
            return <InputText
              id={field.name}
              value={field.value || ''}
              className={inputText.className || 'p-2 w-100'}
              {...field}
              pt={{
                root: {
                  style: {
                    fontSize: inputText.fontSize || '1.0em',
                    fontWeight: inputText.fontWeight || 'bold',
                  }
                }
              }}
              readOnly={readOnly}
            />;
          }}
        />
        {labelAfter && (
          <label htmlFor={inputText.id} className={labelAfter.className}>
            {labelAfter.label}
          </label>
        )}
      </>
    );
  };

  const inputTextAreaEditor = ({ labelBefore, inputTextArea, labelAfter }) => {
    return (
      <>
        {labelBefore && (
          <label htmlFor={inputTextArea.id} className={labelBefore.className}>
            {labelBefore.label}
          </label>
        )}
        <Controller
          name={inputTextArea.id}
          control={control}
          defaultValue=''
          render={({ field }) => {
            field.value ??= '';
            <InputTextarea
              id={field.name}
              value={field.value}
              className={inputTextArea.className || 'p-2 w-100'}
              rows={2}
              {...field}
              pt={{
                root: {
                  style: {
                    fontSize: inputTextArea.fontSize || '1em',
                    fontWeight: inputTextArea.fontWeight || 'bold',
                    resize: 'none',
                  }
                }
              }}
              readOnly={readOnly}
            />;
          }}
        />
        {labelAfter && (
          <label htmlFor={inputTextArea.id} className={labelAfter.className}>
            {labelAfter.label}
          </label>
        )}
      </>
    );
  };

  const inputNumberEditor = ({ labelBefore, inputNumber, labelAfter }) => {
    return (
      <>
        {labelBefore && (
          <label htmlFor={inputNumber.id} className={labelBefore.className}>
            {labelBefore.label}
          </label>
        )}
        <Controller
          name={inputNumber.id}
          control={control}
          render={({ field }) => (
            <InputNumber
              inputId={field.name}
              value={field.value}
              className={inputNumber.className}
              inputClassName={inputNumber.inputClassName || 'p-2 w-100 fw-bold'}
              mode={inputNumber.mode}
              currency={inputNumber.currency}
              min={inputNumber.min || 0}
              onValueChange={field.onChange}
              pt={{
                root: {
                  style: {
                    fontSize: inputNumber.fontSize || '1.21212em',
                    width: inputNumber.width,
                  }
                }
              }}
              readOnly={readOnly}
            />
          )}
        />
        {labelAfter && (
          <label htmlFor={inputNumber.id} className={labelAfter.className}>
            {labelAfter.label}
          </label>
        )}
      </>
    );
  };

  const inputMaskEditor = ({ labelBefore, inputMask, labelAfter }) => {
    return (
      <>
        {labelBefore && (
          <label htmlFor={inputMask.id} className={labelBefore.className}>
            {labelBefore.label}
          </label>
        )}
        <Controller
          name={inputMask.id}
          control={control}
          render={({ field }) => (
            <InputMask
              id={field.name}
              value={field.value}
              mask={inputMask.mask}
              className={inputMask.className || 'p-2 w-100'}
              {...field}
              pt={{
                root: {
                  style: {
                    fontSize: inputMask.fontSize || '1.0em',
                    fontWeight: inputMask.fontWeight || 'bold',
                  }
                }
              }}
              readOnly={readOnly}
            />
          )}
        />
        {labelAfter && (
          <label htmlFor={inputMask.id} className={labelAfter.className}>
            {labelAfter.label}
          </label>
        )}
      </>
    );
  };

  const calendarEditor = ({ labelBefore, calendar, labelAfter }) => {
    return (
      <>
        {labelBefore && (
          <label htmlFor={calendar.id} className={labelBefore.className}>
            {labelBefore.label}
          </label>
        )}
        <Controller
          name={calendar.id}
          control={control}
          render={({ field }) => {
            let dateValue = field.value;

            if (typeof dateValue === 'string') {
              const [year, month, day] = dateValue.split('-');
              dateValue = new Date(year, month - 1, day);
            }
            field.value = dateValue;

            return <Calendar
              id={field.name}
              value={field.value}
              onChange={field.onChange}
              dateFormat={calendar.dateFormat || 'mm/dd/yy'}
              showIcon
              className={calendar.className || 'w-100 datepicker-p-1'}
              inputClassName={calendar.inputClassName || 'p-2 fw-bold'}
              panelClassName={calendar.panelClassName || 'small'}
              maxDate={calendar.maxDate || currentDate}
              pt={{
                button: {
                  style: {
                    paddingTop: calendar.paddingTop || '2px',
                    paddingBottom: calendar.paddingBottom || '2px',
                  },
                },
              }}
              readOnlyInput={readOnly}
              disabled={readOnly}
            />;
          }}
        />
        {labelAfter && (
          <label htmlFor={calendar.id} className={labelAfter.className}>
            {labelAfter.label}
          </label>
        )}
      </>
    );
  };

  const dropdownEditor = ({ labelBefore, dropdown, labelAfter }) => {
    return (
      <>
        {labelBefore && (
          <label htmlFor={dropdown.id} className={labelBefore.className}>
            {labelBefore.label}
          </label>
        )}
        <Controller
          name={dropdown.id}
          control={control}
          render={({ field }) => (
            <Dropdown
              id={field.name}
              value={field.value}
              options={dropdown.options}
              onChange={field.onChange}
              className={dropdown.className}
              panelClassName={dropdown.panelClassName}
              disabled={readOnly}
            />
          )}
        />
        {labelAfter && (
          <label htmlFor={dropdown.id} className={labelAfter.className}>
            {labelAfter.label}
          </label>
        )}
      </>
    );
  };

  const calendarCellEditor = (options, fieldArrayName) => {
    return (
      <Controller
        name={`${fieldArrayName}.${options.rowIndex}.${options.column.props.field}`}
        control={control}
        render={({ field }) => {
          let dateValue = field.value;

          if (typeof dateValue === 'string') {
            const [year, month, day] = dateValue.split('-');
            dateValue = new Date(year, month - 1, day);
          }
          field.value = dateValue;

          return <Calendar
            id={field.name}
            value={field.value}
            onChange={(e) => {
              setFormValue(field.name, e.value, { shouldDirty: true, shouldValidate: true });
            }}
            dateFormat="mm/dd/yy"
            className="w-100 datepicker-p-1"
            inputClassName='p-2'
            panelClassName='small d-none'
            maxDate={currentDate}
            pt={{
              button: {
                style: {
                  paddingTop: '2px',
                  paddingBottom: '2px',
                },
              },
            }}
          />;
        }}
      />
    );
  };

  const dateCellTemplate = (rowData) => {
    if (!rowData.date) return null;

    let dateValue = rowData.date;
    if (typeof dateValue === 'string') {
      const [year, month, day] = dateValue.split('-');
      dateValue = new Date(year, month - 1, day);
    }

    return dateValue.toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const textCellEditor = (options, fieldArrayName) => {
    return (
      <Controller
        name={`${fieldArrayName}.${options.rowIndex}.${options.column.props.field}`}
        control={control}
        defaultValue=''
        render={({ field }) => {
          field ??= '';
          <InputText
            id={field.name}
            value={field.value ?? ''}
            onChange={(e) => {
              setFormValue(field.name, e.target.value);
            }}
            className="p-2 w-100"
            pt={{
              root: {
                style: {
                  fontSize: '1em'
                }
              }
            }}
          />;
        }}
      />
    );
  };

  const numeriCellEditor = (options, fieldArrayName, extraProps = {}) => {
    return (
      <Controller
        name={`${fieldArrayName}.${options.rowIndex}.${options.column.props.field}`}
        control={control}
        render={({ field }) => (
          <InputNumber
            id={field.name}
            value={field.value}
            onValueChange={(e) => {
              setFormValue(field.name, e.value);
            }}
            mode={extraProps.mode}
            currency={extraProps.currency}
            min={extraProps.min || 0}
            pt={{
              input: {
                root: {
                  style: {
                    fontSize: '1.1em',
                    padding: '0.3rem',
                    width: extraProps.width,
                  }
                }
              },
              ...extraProps
            }}
          />
        )}
      />
    );
  };

  const updateProcedureOnCellEditHide = (options, fieldArrayName) => {
    const colName = options.column.props.field;
    const newValue = getFormValues(`${fieldArrayName}.${options.rowIndex}.${colName}`);

    if (procedures[options.rowIndex][colName] !== newValue) {
      const updatedProcedure = { ...procedures[options.rowIndex], [colName]: newValue };
      updateProcedure(options.rowIndex, updatedProcedure);
    }
  };

  const currencyTemplate = (value) => {
    if (value === null) return null;
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

  const teethNumbersTopRow = Array.from({ length: 16 }, (_, i) => i + 1);
  const teethNumbersBottomRow = Array.from({ length: 16 }, (_, i) => 32 - i);

  const missingTeethInfoCheckbox = (number, index) => (
    <div key={number} className="missing-tooth-checkbox">
      <input type="checkbox" id={`missingTeethInfo_${index}`} {...register(`missingTeethInfo.${index}`)} />
      <label htmlFor={`missingTeethInfo_${index}`}>{number}</label>
    </div>
  );

  const qualifierOptions = [
    { label: 'AB', value: 'AB' },
  ];

  const areTotalFeesEqual = (a, b) => {
    if (a === null && b === null) return true;
    if (a !== null && b !== null) return parseFloat(a).toFixed(2) === parseFloat(b).toFixed(2);
    return false;
  };

  const [totalFee, setTotalFee] = useState(getFormValues('totalFee'));
  const watchedOtherFees1 = useWatch({ control, name: 'otherFees1' });
  const watchedOtherFees2 = useWatch({ control, name: 'otherFees2' });

  const [backgroundTotalFee, setBackgroundTotalFee] = useState('bg-transparent');
  const [colorTotalFee, setColorTotalFee] = useState('text-dark');

  useEffect(() => {
    const allNull = procedures.every(proc => proc.fee === null) && watchedOtherFees1 === null && watchedOtherFees2 === null;

    if (allNull) {
      setTotalFee(null);
    } else {
      const proceduresSum = procedures.reduce((acc, procedure) => acc + (parseFloat(procedure.fee) || 0), 0);
      const totalSum = proceduresSum + (parseFloat(watchedOtherFees1) || 0) + (parseFloat(watchedOtherFees2) || 0);
      setTotalFee(totalSum);
    }
  }, [procedures, watchedOtherFees1, watchedOtherFees2]);

  useEffect(() => {
    if (!extraFormProps.compareTotalFeesBetweenInputAndUpdates) return;

    const formTotalFee = getFormValues('totalFee');
    if (areTotalFeesEqual(totalFee, formTotalFee)) {
      setBackgroundTotalFee('bg-success');
      setColorTotalFee('text-white');
    }
    else {
      setBackgroundTotalFee('bg-warning');
      setColorTotalFee('text-dark');
    }
  }, [totalFee, getFormValues('totalFee')]);

  const panelPt = {
    header: () => ({
      className: 'p-3 small',
    }),
    content: { className: 'p-2 x-small' },
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="d-flex flex-column h-100" style={{ margin: '0 -.75rem' }} >
      <div className="d-flex flex-column flex-grow-1 h-100" style={{ padding: '0 .75rem', overflow: 'auto' }} >
        <div className="row my-3 gx-0" style={{ minWidth: '1124px' }} >
          <div className="col-6 d-flex flex-column">
            <Panel header="Header Information" pt={panelPt}>
              <div className='row gx-0'>
                <div className="col-12">
                  <label>1. Type of Transaction</label>
                  {checkboxEditor({
                    checkBox: { id: 'requestForPredeterminationPreauthorization', className: 'ms-4' },
                    labelAfter: { label: 'Request for Predetermination/Preauthorization', className: 'ms-1' }
                  })}
                </div>
                <div className="col-12 mt-3">
                  {checkboxEditor({
                    checkBox: { id: 'statementOfActualServices' },
                    labelAfter: { label: 'Statement of Actual Services', className: 'ms-1' }
                  })}
                  {checkboxEditor({
                    checkBox: { id: 'epsdtTitleXix', className: 'ms-4' },
                    labelAfter: { label: 'EPSDT/Title XIX', className: 'ms-1' }
                  })}
                </div>
                <Divider className="my-2" />
                <div className="col-auto my-auto">
                  <label htmlFor="preAuthorizationNumber" className="mb-0">
                    2. Predetermination/Preauthorization Number
                  </label>
                </div>
                <div className="col ms-2">
                  {inputTextEditor({
                    inputText: { id: 'preAuthorizationNumber' }
                  })}
                </div>
              </div>
            </Panel>
            <Panel header="Dental Benefit Plan Information" className="mt-1" pt={panelPt}>
              <div className='row gx-0'>
                <div className="col-12">
                  <label>3. Company/Plan Name, Address, City, State, Zip Code</label>
                </div>
                <div className="col-12 mt-1">
                  {inputTextEditor({
                    labelBefore: { label: 'Company/Plan Name' },
                    inputText: { id: 'mainPayerName' }
                  })}
                </div>
                <div className="col-12 mt-1">
                  {inputTextEditor({
                    labelBefore: { label: 'Address 1' },
                    inputText: { id: 'mainPayerAddress1' }
                  })}
                </div>
                <div className="col-12 mt-1">
                  {inputTextEditor({
                    labelBefore: { label: 'Address 2' },
                    inputText: { id: 'mainPayerAddress2' }
                  })}
                </div>
                <div className="col-5 mt-1 pe-1">
                  {inputTextEditor({
                    labelBefore: { label: 'City' },
                    inputText: { id: 'mainPayerCity' }
                  })}
                </div>
                <div className="col-3 mt-1 px-1">
                  {inputTextEditor({
                    labelBefore: { label: 'State' },
                    inputText: { id: 'mainPayerState' }
                  })}
                </div>
                <div className="col-4 mt-1 ps-1">
                  {inputTextEditor({
                    labelBefore: { label: 'Zip Code' },
                    inputText: { id: 'mainPayerZipCode' }
                  })}
                </div>
              </div>
            </Panel>
            <Panel header="Other Coverage" className="mt-1" pt={panelPt}>
              <div className='row gx-0'>
                <div className="col-12">
                  {checkboxEditor({
                    labelBefore: { label: '4. Dental?' },
                    checkBox: { id: 'otherCoverage', className: 'ms-1' },
                  })}
                  {checkboxEditor({
                    labelBefore: { label: 'Medical?', className: 'ms-3' },
                    checkBox: { id: 'otherCoverageMedical', className: 'ms-1' },
                  })}
                </div>
                <Divider className="my-2" />
                <div className="col-12">
                  <label>5. Name of Policyholder/Subscriber for #4</label>
                </div>
                <div className="col-6 mt-1 pe-1">
                  {inputTextEditor({
                    labelBefore: { label: 'First Name' },
                    inputText: { id: 'otherCoverageSubscriberFirstName' }
                  })}
                </div>
                <div className="col-6 mt-1 ps-1">
                  {inputTextEditor({
                    labelBefore: { label: 'Last Name' },
                    inputText: { id: 'otherCoverageSubscriberLastName' }
                  })}
                </div>
                <Divider className="my-2" />
                <div className="col-4 pe-1" style={{ width: '40%' }}>
                  {calendarEditor({
                    labelBefore: { label: '6. Date of Birth (MM/DD/CCYY)' },
                    calendar: { id: 'otherCoverageSubscriberDob' }
                  })}
                </div>
                <div className="col-3 px-1 border-start border-end" style={{ width: '23.333333333%' }}>
                  <div className="d-flex flex-column h-100">
                    <div className="d-flex">
                      <label>7. Gender</label>
                    </div>
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <div className="d-flex align-items-center">
                        {checkboxEditor({
                          checkBox: { id: 'otherCoverageSubscriberGenderM' },
                          labelAfter: { label: 'M', className: 'ms-1' }
                        })}
                      </div>
                      <div className="d-flex align-items-center">
                        {checkboxEditor({
                          checkBox: { id: 'otherCoverageSubscriberGenderF' },
                          labelAfter: { label: 'F', className: 'ms-1' }
                        })}
                      </div>
                      <div className="d-flex align-items-center">
                        {checkboxEditor({
                          checkBox: { id: 'otherCoverageSubscriberGenderU' },
                          labelAfter: { label: 'U', className: 'ms-1' }
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4 ps-1" style={{ width: '36.66666667%' }}>
                  {inputTextEditor({
                    labelBefore: { label: '8. Policyholder/Subscriber ID' },
                    inputText: { id: 'otherCoverageSubscriberId' }
                  })}
                </div>
                <Divider className="my-2" />
                <div className="col-4 pe-1" style={{ width: '40%' }}>
                  {inputTextEditor({
                    labelBefore: { label: '9. Plan/Group Number' },
                    inputText: { id: 'otherCoverageSubscriberPlanGroupNumber' }
                  })}
                </div>
                <div className="col-8 px-1 border-start" style={{ width: '60%' }}>
                  <div className="d-flex flex-column h-100">
                    <div className="d-flex">
                      <label>10. Patient&apos;s Relationship to Person named in #5</label>
                    </div>
                    <div className="d-flex flex-grow-1 justify-content-between">
                      <div className="d-flex align-items-center">
                        {checkboxEditor({
                          checkBox: { id: 'otherCoverageRelationshipSelf' },
                          labelAfter: { label: 'Self', className: 'ms-1' }
                        })}
                      </div>
                      <div className="d-flex align-items-center">
                        {checkboxEditor({
                          checkBox: { id: 'otherCoverageRelationshipSpouse' },
                          labelAfter: { label: 'Spouse', className: 'ms-1' }
                        })}
                      </div>
                      <div className="d-flex align-items-center">
                        {checkboxEditor({
                          checkBox: { id: 'otherCoverageRelationshipDependant' },
                          labelAfter: { label: 'Dependant', className: 'ms-1' }
                        })}
                      </div>
                      <div className="d-flex align-items-center">
                        {checkboxEditor({
                          checkBox: { id: 'otherCoverageRelationshipOther' },
                          labelAfter: { label: 'Other', className: 'ms-1' }
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Divider className="my-2" />
              <div className='row gx-0'>
                <div className="col-12">
                  <label>11. Other Insurance Company/Dental Benefit Plan, Address, City, State, Zip Code</label>
                </div>
                <div className="col-12 mt-1">
                  {inputTextEditor({
                    labelBefore: { label: 'Other Insurance Company/Dental Benefit Plan Name' },
                    inputText: { id: 'otherCoveragePayerName' }
                  })}
                </div>
                <div className="col-12 mt-1">
                  {inputTextEditor({
                    labelBefore: { label: 'Address 1' },
                    inputText: { id: 'otherCoveragePayerAddress1' }
                  })}
                </div>
                <div className="col-12 mt-1">
                  {inputTextEditor({
                    labelBefore: { label: 'Address 2' },
                    inputText: { id: 'otherCoveragePayerAddress2' }
                  })}
                </div>
                <div className="col-5 mt-1 pe-1">
                  {inputTextEditor({
                    labelBefore: { label: 'City' },
                    inputText: { id: 'otherCoveragePayerCity' }
                  })}
                </div>
                <div className="col-3 mt-1 px-1">
                  {inputTextEditor({
                    labelBefore: { label: 'State' },
                    inputText: { id: 'otherCoveragePayerState' }
                  })}
                </div>
                <div className="col-4 mt-1 ps-1">
                  {inputTextEditor({
                    labelBefore: { label: 'Zip Code' },
                    inputText: { id: 'otherCoveragePayerZipCode' }
                  })}
                </div>
              </div>
            </Panel>
          </div>
          <div className="col-6 d-flex flex-column">
            {attachments === null ? null : (
              <Panel header="Attachments" pt={panelPt}>
                {attachments.length === 0 ? (
                  <p className="m-0 py-2">No attachments available</p>
                ) : (
                  <ul className="list-group" style={{ maxHeight: '118px', overflowY: 'auto' }}>
                    {attachments.map((attachment, index) => (
                      <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                        <a href={attachment.url} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                          {attachment.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </Panel>
            )}
            <div className="flex-grow-1 d-flex flex-column justify-content-end mt-1">
              <Panel header="Policyholder / Subscriber Information" pt={panelPt}>
                <div className='row gx-0'>
                  <div className="col-12">
                    <label>12. Policyholder/Subscriber Name, Address, City, State, Zip Code</label>
                  </div>
                  <div className="col-6 mt-1 pe-1">
                    {inputTextEditor({
                      labelBefore: { label: 'First Name' },
                      inputText: { id: 'subscriberFirstName' }
                    })}
                  </div>
                  <div className="col-6 mt-1 ps-1">
                    {inputTextEditor({
                      labelBefore: { label: 'Last Name' },
                      inputText: { id: 'subscriberLastName' }
                    })}
                  </div>
                  <div className="col-12 mt-1">
                    {inputTextEditor({
                      labelBefore: { label: 'Address 1' },
                      inputText: { id: 'subscriberAddress1' }
                    })}
                  </div>
                  <div className="col-12 mt-1">
                    {inputTextEditor({
                      labelBefore: { label: 'Address 2' },
                      inputText: { id: 'subscriberAddress2' }
                    })}
                  </div>
                  <div className="col-5 mt-1 pe-1">
                    {inputTextEditor({
                      labelBefore: { label: 'City' },
                      inputText: { id: 'subscriberCity' }
                    })}
                  </div>
                  <div className="col-3 mt-1 px-1">
                    {inputTextEditor({
                      labelBefore: { label: 'State' },
                      inputText: { id: 'subscriberState' }
                    })}
                  </div>
                  <div className="col-4 mt-1 ps-1">
                    {inputTextEditor({
                      labelBefore: { label: 'Zip Code' },
                      inputText: { id: 'subscriberZipCode' }
                    })}
                  </div>
                  <Divider className="my-2" />
                  <div className="col-4 pe-1" style={{ width: '40%' }}>
                    {calendarEditor({
                      labelBefore: { label: '13. Date of Birth (MM/DD/CCYY)' },
                      calendar: { id: 'subscriberDob' }
                    })}
                  </div>
                  <div className="col-3 px-1 border-start border-end" style={{ width: '23.333333333%' }}>
                    <div className="d-flex flex-column h-100">
                      <div className="d-flex">
                        <label>14. Gender</label>
                      </div>
                      <div className="d-flex flex-grow-1 justify-content-between">
                        <div className="d-flex align-items-center">
                          {checkboxEditor({
                            checkBox: { id: 'subscriberGenderM' },
                            labelAfter: { label: 'M', className: 'ms-1' }
                          })}
                        </div>
                        <div className="d-flex align-items-center">
                          {checkboxEditor({
                            checkBox: { id: 'subscriberGenderF' },
                            labelAfter: { label: 'F', className: 'ms-1' }
                          })}
                        </div>
                        <div className="d-flex align-items-center">
                          {checkboxEditor({
                            checkBox: { id: 'subscriberGenderU' },
                            labelAfter: { label: 'U', className: 'ms-1' }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 ps-1" style={{ width: '36.66666667%' }}>
                    {inputTextEditor({
                      labelBefore: { label: '15. Policyholder/Subscriber ID' },
                      inputText: { id: 'subscriberId' }
                    })}
                  </div>
                  <Divider className="my-2" />
                  <div className="col-4 pe-1" style={{ width: '40%' }}>
                    {inputTextEditor({
                      labelBefore: { label: '16. Plan/Group Number' },
                      inputText: { id: 'subscriberPlanGroupNumber' }
                    })}
                  </div>
                  <div className="col-8 px-1 border-start" style={{ width: '60%' }}>
                    {inputTextEditor({
                      labelBefore: { label: '17. Employer Name' },
                      inputText: { id: 'subscriberEmployerName' }
                    })}
                  </div>
                </div>
              </Panel>
              <Panel header="Patient Information" className='mt-1' pt={panelPt}>
                <div className='row gx-0'>
                  <div className="col-8 px-1" style={{ width: '60%' }}>
                    <div className="d-flex flex-column h-100">
                      <div className="d-flex">
                        <label>18. Relationship to Policyholder/Subscriber in #12</label>
                      </div>
                      <div className="d-flex flex-grow-1 justify-content-between">
                        <div className="d-flex align-items-center">
                          {checkboxEditor({
                            checkBox: { id: 'patientRelationshipSelf' },
                            labelAfter: { label: 'Self', className: 'ms-1' }
                          })}
                        </div>
                        <div className="d-flex align-items-center">
                          {checkboxEditor({
                            checkBox: { id: 'patientRelationshipSpouse' },
                            labelAfter: { label: 'Spouse', className: 'ms-1' }
                          })}
                        </div>
                        <div className="d-flex align-items-center">
                          {checkboxEditor({
                            checkBox: { id: 'patientRelationshipDependant' },
                            labelAfter: { label: 'Dependant', className: 'ms-1' }
                          })}
                        </div>
                        <div className="d-flex align-items-center">
                          {checkboxEditor({
                            checkBox: { id: 'patientRelationshipOther' },
                            labelAfter: { label: 'Other', className: 'ms-1' }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 ps-1 border-start" style={{ width: '40%' }}>
                    <label>19. Reserved For Future Use</label>
                  </div>
                  <Divider className="my-2" />
                  <div className="col-12">
                    <label>20. Name, Address, City, State, Zip Code</label>
                  </div>
                  <div className="col-6 mt-1 pe-1">
                    {inputTextEditor({
                      labelBefore: { label: 'First Name' },
                      inputText: { id: 'patientFirstName' }
                    })}
                  </div>
                  <div className="col-6 mt-1 ps-1">
                    {inputTextEditor({
                      labelBefore: { label: 'Last Name' },
                      inputText: { id: 'patientLastName' }
                    })}
                  </div>
                  <div className="col-12 mt-1">
                    {inputTextEditor({
                      labelBefore: { label: 'Address 1' },
                      inputText: { id: 'patientAddress1' }
                    })}
                  </div>
                  <div className="col-12 mt-1">
                    {inputTextEditor({
                      labelBefore: { label: 'Address 2' },
                      inputText: { id: 'patientAddress2' }
                    })}
                  </div>
                  <div className="col-5 mt-1 pe-1">
                    {inputTextEditor({
                      labelBefore: { label: 'City' },
                      inputText: { id: 'patientCity' }
                    })}
                  </div>
                  <div className="col-3 mt-1 px-1">
                    {inputTextEditor({
                      labelBefore: { label: 'State' },
                      inputText: { id: 'patientState' }
                    })}
                  </div>
                  <div className="col-4 mt-1 ps-1">
                    {inputTextEditor({
                      labelBefore: { label: 'Zip Code' },
                      inputText: { id: 'patientZipCode' }
                    })}
                  </div>
                  <Divider className="my-2" />
                  <div className="col-4 pe-1" style={{ width: '40%' }}>
                    {calendarEditor({
                      labelBefore: { label: '21. Date of Birth (MM/DD/CCYY)' },
                      calendar: { id: 'patientDob' }
                    })}
                  </div>
                  <div className="col-3 px-1 border-start border-end" style={{ width: '23.333333333%' }}>
                    <div className="d-flex flex-column h-100">
                      <div className="d-flex">
                        <label>22. Gender</label>
                      </div>
                      <div className="d-flex flex-grow-1 justify-content-between">
                        <div className="d-flex align-items-center">
                          {checkboxEditor({
                            checkBox: { id: 'patientGenderM' },
                            labelAfter: { label: 'M', className: 'ms-1' }
                          })}
                        </div>
                        <div className="d-flex align-items-center">
                          {checkboxEditor({
                            checkBox: { id: 'patientGenderF' },
                            labelAfter: { label: 'F', className: 'ms-1' }
                          })}
                        </div>
                        <div className="d-flex align-items-center">
                          {checkboxEditor({
                            checkBox: { id: 'patientGenderU' },
                            labelAfter: { label: 'U', className: 'ms-1' }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 ps-1" style={{ width: '36.66666667%' }}>
                    {inputTextEditor({
                      labelBefore: { label: '23. Patient ID/Account #' },
                      inputText: { id: 'patientId' }
                    })}
                  </div>
                </div>
              </Panel>
            </div>
          </div>
          <div className="col-12 mt-1">
            <Panel header="Record of Services Provided" pt={panelPt}>
              <div className='row gx-0'>
                <div className="col-12">
                  <DataTable
                    value={procedures}
                    editMode={!readOnly ? 'cell' : null}
                    pt={{
                      column: {
                        headerCell: {
                          style: {
                            padding: '.5rem',
                          },
                        },
                        bodyCell: {
                          style: {
                            padding: '.5rem',
                          },
                        },
                      }
                    }}
                  >
                    <Column
                      field="id"
                      hidden={true}
                    />

                    <Column
                      body={(_, options) => options.rowIndex + 1}
                      header=""
                      style={{ width: '32px' }} alignHeader='center'
                    />

                    <Column
                      field="date"
                      header="24. Procedure Date (MM/DD/CCYY)"
                      body={dateCellTemplate}
                      style={{ width: '150px' }}
                      alignHeader='center'
                      editor={(options) => calendarCellEditor(options, 'procedures')}
                      onBeforeCellEditHide={(options) => updateProcedureOnCellEditHide(options, 'procedures')}
                    />

                    <Column
                      field="area"
                      header="25. Area of Oral Cavity"
                      style={{ width: '80px' }}
                      alignHeader='center'
                      editor={(options) => textCellEditor(options, 'procedures')}
                      onBeforeCellEditHide={(options) => updateProcedureOnCellEditHide(options, 'procedures')}
                    />

                    <Column
                      field="toothSystem"
                      header="26. Tooth System"
                      style={{ width: '60px' }}
                      alignHeader='center'
                      editor={(options) => textCellEditor(options, 'procedures')}
                      onBeforeCellEditHide={(options) => updateProcedureOnCellEditHide(options, 'procedures')}
                    />

                    <Column
                      field="toothNumber"
                      header="27. Tooth Number(s) or Letter(s)"
                      style={{ width: '160px' }}
                      alignHeader='center'
                      editor={(options) => textCellEditor(options, 'procedures')}
                      onBeforeCellEditHide={(options) => updateProcedureOnCellEditHide(options, 'procedures')}
                    />

                    <Column
                      field="toothSurface"
                      header="28. Tooth Surface"
                      style={{ width: '80px' }}
                      alignHeader='center'
                      editor={(options) => textCellEditor(options, 'procedures')}
                      onBeforeCellEditHide={(options) => updateProcedureOnCellEditHide(options, 'procedures')}
                    />

                    <Column
                      field="procedureCode"
                      header="29. Procedure Code"
                      style={{ width: '90px' }}
                      alignHeader='center'
                      editor={(options) => textCellEditor(options, 'procedures')}
                      onBeforeCellEditHide={(options) => updateProcedureOnCellEditHide(options, 'procedures')}
                    />

                    <Column
                      field="diagnosisPointer"
                      header="29a. Diag. Pointer"
                      style={{ width: '90px' }}
                      alignHeader='center'
                      editor={(options) => textCellEditor(options, 'procedures')}
                      onBeforeCellEditHide={(options) => updateProcedureOnCellEditHide(options, 'procedures')}
                    />

                    <Column
                      field="quantity"
                      header="29b. Qty"
                      style={{ width: '50px' }}
                      alignHeader='center'
                      editor={(options) => numeriCellEditor(options, 'procedures', { mode: 'decimal', min: 0, width: '40px' })}
                      onBeforeCellEditHide={(options) => updateProcedureOnCellEditHide(options, 'procedures')}
                    />

                    <Column
                      field="description"
                      header="30. Description"
                      alignHeader='center'
                      editor={(options) => textCellEditor(options, 'procedures')}
                      onBeforeCellEditHide={(options) => updateProcedureOnCellEditHide(options, 'procedures')}
                    />

                    <Column
                      field="fee"
                      header="31. Fee"
                      body={(rowData) => currencyTemplate(rowData.fee)}
                      style={{ width: '80px' }}
                      alignHeader='center'
                      editor={(options) => numeriCellEditor(options, 'procedures', { mode: 'currency', currency: 'USD', min: 0, width: '70px' })}
                      onBeforeCellEditHide={(options) => updateProcedureOnCellEditHide(options, 'procedures')}
                    />
                  </DataTable>
                </div>
                <Divider className="my-2" />
                <div className="col-5 pe-1" style={{ width: '43.5%' }}>
                  <label className="mt-1" style={{ marginBottom: '.15rem' }}>33. Missing Teeth Information</label>
                  <Divider className="my-2" />
                  <div className="teeth-grid">
                    {teethNumbersTopRow.map((number, i) => missingTeethInfoCheckbox(number, i))}
                  </div>
                  <Divider className="my-2" />
                  <div className="teeth-grid">
                    {teethNumbersBottomRow.map((number, i) => missingTeethInfoCheckbox(number, 31 - i))}
                  </div>
                </div>
                <div className="col-5 border-start px-1" style={{ width: '40%' }}>
                  {dropdownEditor({
                    labelBefore: { label: '34. Diagnosis Code List Qualifier', className: 'ms-1' },
                    dropdown: { id: 'diagnosisCodeListQualifier', className: 'ms-1 fw-bold qualifier-dropdown', panelClassName: 'qualifier-dropdown-panel', options: qualifierOptions }
                  })}
                  <Divider className="mb-2 mt-1" />
                  <div className="row gx-1 ps-1">
                    <div className="col-2">
                      <label>34a. Diagnosis Code(s)</label>
                    </div>
                    <div className="col-5">
                      <div className="row gx-0">
                        <div className='col-12 d-flex align-items-center'>
                          {inputTextEditor({
                            labelBefore: { label: 'A', className: 'p-1' },
                            inputText: { id: 'diagnosisCode1', className: "p-2 flex-grow-1 w-100" },
                          })}
                        </div>
                        <div className='col-12 d-flex align-items-center'>
                          {inputTextEditor({
                            labelBefore: { label: 'C', className: 'p-1' },
                            inputText: { id: 'diagnosisCode3', className: "p-2 flex-grow-1 w-100" },
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="row gx-0">
                        <div className='col-12 d-flex align-items-center'>
                          {inputTextEditor({
                            labelBefore: { label: 'B', className: 'p-1' },
                            inputText: { id: 'diagnosisCode2', className: "p-2 flex-grow-1 w-100" },
                          })}
                        </div>
                        <div className='col-12 d-flex align-items-center'>
                          {inputTextEditor({
                            labelBefore: { label: 'D', className: 'p-1' },
                            inputText: { id: 'diagnosisCode4', className: "p-2 flex-grow-1 w-100" },
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 border-start ps-1" style={{ width: '16.5%' }}>
                  <div className="row gx-0">
                    <div className="col-6">
                      <label>31a. Other Fee(s)</label>
                    </div>
                    <div className="col-6">
                      <div className="row gx-0">
                        <div className="col-12">
                          {inputNumberEditor({
                            inputNumber: { id: 'otherFees1', mode: 'currency', currency: 'USD' }
                          })}
                        </div>
                        <div className="col-12">
                          {inputNumberEditor({
                            inputNumber: { id: 'otherFees2', mode: 'currency', currency: 'USD' }
                          })}
                        </div>
                      </div>
                    </div>
                    <Divider className="my-2" />
                    <div className="col-6 pt-1">
                      32. Total Fee
                    </div>
                    <div className={`col-6 ${backgroundTotalFee} ${colorTotalFee} ps-1 py-1 rounded`}>
                      <b>
                        {extraFormProps.setTotalFeeAutomatically ? currencyTemplate(totalFee) : currencyTemplate(getFormValues('totalFee'))}
                      </b>
                    </div>
                  </div>
                </div>
                <Divider className="my-2" />
                <div className="col-12">
                  {inputTextAreaEditor({
                    labelBefore: { label: '35. Remarks' },
                    inputTextArea: { id: 'remarks' }
                  })}
                </div>
              </div>
            </Panel>
          </div>
          <div className="col-6 mt-1">
            <Panel header="Authorizations" pt={panelPt}>
              <div className='row gx-1'>
                <label>36. Patient/Guardian Authorization</label>
                <div className="col-7">
                  {inputTextEditor({
                    labelBefore: { label: 'Patient/Guardian Signature' },
                    inputText: { id: 'patientGuardianAuthorizationSignature' }
                  })}
                </div>
                <div className="col-5">
                  {calendarEditor({
                    labelBefore: { label: 'Date' },
                    calendar: { id: 'patientGuardianAuthorizationDate' }
                  })}
                </div>
                <Divider className="my-2" />
                <label>37. Subscriber Authorization</label>
                <div className="col-7">
                  {inputTextEditor({
                    labelBefore: { label: 'Subscriber Signature' },
                    inputText: { id: 'subscriberAuthorizationSignature' }
                  })}
                </div>
                <div className="col-5">
                  {calendarEditor({
                    labelBefore: { label: 'Date' },
                    calendar: { id: 'subscriberAuthorizationDate' }
                  })}
                </div>
              </div>
            </Panel>
            <Panel header="Billing Dentist or Dental Entity" className="mt-1" pt={panelPt}>
              <div className='row gx-0'>
                <label>48. Name, Adress, City, State, Zip Code</label>
                <div className="col-6 mt-1 pe-1">
                  {inputTextEditor({
                    labelBefore: { label: 'First Name' },
                    inputText: { id: 'billingProviderFirstName' }
                  })}
                </div>
                <div className="col-6 mt-1 ps-1">
                  {inputTextEditor({
                    labelBefore: { label: 'Last Name' },
                    inputText: { id: 'billingProviderLastName' }
                  })}
                </div>
                <div className="col-12 mt-1">
                  {inputTextEditor({
                    labelBefore: { label: 'Address 1' },
                    inputText: { id: 'billingProviderAddress1' }
                  })}
                </div>
                <div className="col-12 mt-1">
                  {inputTextEditor({
                    labelBefore: { label: 'Address 2' },
                    inputText: { id: 'billingProviderAddress2' }
                  })}
                </div>
                <div className="col-5 mt-1 pe-1">
                  {inputTextEditor({
                    labelBefore: { label: 'City' },
                    inputText: { id: 'billingProviderCity' }
                  })}
                </div>
                <div className="col-3 mt-1 px-1">
                  {inputTextEditor({
                    labelBefore: { label: 'State' },
                    inputText: { id: 'billingProviderState' }
                  })}
                </div>
                <div className="col-4 mt-1 ps-1">
                  {inputTextEditor({
                    labelBefore: { label: 'Zip Code' },
                    inputText: { id: 'billingProviderZipCode' }
                  })}
                </div>
                <Divider className="my-2" />
                <div className="col-4 pe-1">
                  {inputTextEditor({
                    labelBefore: { label: '49. NPI' },
                    inputText: { id: 'billingProviderNpi' }
                  })}
                </div>
                <div className="col-4 px-1 border-start">
                  {inputTextEditor({
                    labelBefore: { label: '50. License Number' },
                    inputText: { id: 'billingProviderLicenseNumber' }
                  })}
                </div>
                <div className="col-4 ps-1 border-start">
                  {inputTextEditor({
                    labelBefore: { label: '51. SSN or TIN' },
                    inputText: { id: 'billingProviderSsnTin' }
                  })}
                </div>
                <Divider className="my-2" />
                <div className="col-6 pe-1">
                  {inputMaskEditor({
                    labelBefore: { label: '52. Phone Number' },
                    inputMask: { id: 'billingProviderPhone', mask: '(999) 999-9999' }
                  })}
                </div>
                <div className="col-6 ps-1 border-start">
                  {inputTextEditor({
                    labelBefore: { label: '52a. Additional Provider ID' },
                    inputText: { id: 'billingProviderAdditionalProviderId' }
                  })}
                </div>
              </div>
            </Panel>
          </div>
          <div className="col-6 mt-1">
            <Panel header="Ancillary Claim/Treatment Information" pt={panelPt}>
              <div className='row gx-0'>
                <div className="col-8">
                  {inputNumberEditor({
                    labelBefore: { label: '38. Place of Treatment' },
                    inputNumber: { id: 'placeOfTreatment', className: 'ms-2', width: '40px' },
                  })}
                </div>
                <div className="col-4 border-start ps-1 d-flex align-items-center">
                  {checkboxEditor({
                    labelBefore: { label: '39. Enclosures (Y or N)' },
                    checkBox: { id: 'enclosures', className: 'ms-1' },
                  })}
                </div>
                <Divider className="my-2" />
                <div className="col-8 mt-1">
                  <label>40. Is Treatment for Orthodontics?</label>
                  <br />
                  <div className="d-flex justify-content-around">
                    <span>
                      {checkboxEditor({
                        checkBox: { id: 'orthodonticsNo' },
                        labelAfter: { label: 'No (Skip 41-42)', className: 'ms-1' }
                      })}
                    </span>
                    <span>
                      {checkboxEditor({
                        checkBox: { id: 'orthodonticsYes' },
                        labelAfter: { label: 'Yes (Complete 41-42)', className: 'ms-1' }
                      })}
                    </span>
                  </div>
                </div>
                <div className="col-4 border-start ps-1">
                  {calendarEditor({
                    labelBefore: { label: '41. Initial Placement Date' },
                    calendar: { id: 'dateAppliancePlaced' }
                  })}
                </div>
                <Divider className="my-2" />
                <div className="col-4">
                  {inputNumberEditor({
                    labelBefore: { label: '42. Months of Treatment', className: 'w-100' },
                    inputNumber: { id: 'monthsOfTreatment', className: 'ms-2', width: '40px' },
                  })}
                </div>
                <div className="col-4 mt-1 border-start ps-1">
                  <label>43. Prothesis Replacement</label>
                  <br />
                  <div className="d-flex justify-content-around">
                    <span>
                      {checkboxEditor({
                        checkBox: { id: 'prothesisReplacementNo' },
                        labelAfter: { label: 'No', className: 'ms-1' }
                      })}
                    </span>
                    <span>
                      {checkboxEditor({
                        checkBox: { id: 'prothesisReplacementYes' },
                        labelAfter: { label: 'Yes (Compl. 44)', className: 'ms-1' }
                      })}
                    </span>
                  </div>
                </div>
                <div className="col-4 border-start ps-1">
                  {calendarEditor({
                    labelBefore: { label: '44. Date of Prior Placement' },
                    calendar: { id: 'dateOfPriorPlacement' }
                  })}
                </div>
                <Divider className="my-2" />
                <div className="col-12 mt-1">
                  <label>45. Treatment Resulting from</label>
                  <br />
                  <div className="d-flex justify-content-around">
                    <span>
                      {checkboxEditor({
                        checkBox: { id: 'treatmentResultingOccupational' },
                        labelAfter: { label: 'Occupation illness/injury', className: 'ms-1' }
                      })}
                    </span>
                    <span>
                      {checkboxEditor({
                        checkBox: { id: 'treatmentResultingAuto' },
                        labelAfter: { label: 'Auto accident', className: 'ms-1' }
                      })}
                    </span>
                    <span>
                      {checkboxEditor({
                        checkBox: { id: 'treatmentResultingOther' },
                        labelAfter: { label: 'Other accident', className: 'ms-1' }
                      })}
                    </span>
                  </div>
                </div>
                <Divider className="my-2" />
                <div className="col-8 pe-1">
                  {calendarEditor({
                    labelBefore: { label: '46. Date of Accident (MM/DD/CCYY)' },
                    calendar: { id: 'dateOfAccident', className: 'w-75 datepicker-p-1' },
                  })}
                </div>
                <div className="col-4 border-start ps-1">
                  {inputTextEditor({
                    labelBefore: { label: '47. Auto Accident State' },
                    inputText: { id: 'autoAccidentState' }
                  })}
                </div>
              </div>
            </Panel>
            <Panel header="Treating Dentist and Treatment Location Information" className="mt-1" pt={panelPt}>
              <div className='row gx-0'>
                <label>53. Certify procedures as indicated by date are in progress or have been completed.</label>
                <div className="col-7 pe-1">
                  {inputTextEditor({
                    labelBefore: { label: 'Treating Dentist Signature' },
                    inputText: { id: 'treatingProviderSignature' }
                  })}
                </div>
                <div className="col-5 ps-1">
                  {calendarEditor({
                    labelBefore: { label: 'Date' },
                    calendar: { id: 'treatingProviderCertifyDate' }
                  })}
                </div>
                <Divider className="my-2" />
                <div className="col-6 pe-1">
                  {inputTextEditor({
                    labelBefore: { label: '54. NPI Dentist Name' },
                    inputText: { id: 'treatingProviderNpi' }
                  })}
                </div>
                <div className="col-6 ps-1 border-start">
                  {inputTextEditor({
                    labelBefore: { label: '55. License Number' },
                    inputText: { id: 'treatingProviderLicenseNumber' }
                  })}
                </div>
                <Divider className="my-2" />
                <div className="col-6 pe-1">
                  <label>56. Address, City, State, Zip Code</label>
                  <div className="row gx-0">
                    <div className="col-12 mt-1">
                      {inputTextEditor({
                        labelBefore: { label: 'Address 1' },
                        inputText: { id: 'treatingProviderAddress1' }
                      })}
                    </div>
                    <div className="col-12 mt-1">
                      {inputTextEditor({
                        labelBefore: { label: 'Address 2' },
                        inputText: { id: 'treatingProviderAddress2' }
                      })}
                    </div>
                    <div className="col-5 mt-1 pe-1">
                      {inputTextEditor({
                        labelBefore: { label: 'City' },
                        inputText: { id: 'treatingProviderCity' }
                      })}
                    </div>
                    <div className="col-3 mt-1 px-1">
                      {inputTextEditor({
                        labelBefore: { label: 'State' },
                        inputText: { id: 'treatingProviderState' }
                      })}
                    </div>
                    <div className="col-4 mt-1 ps-1">
                      {inputTextEditor({
                        labelBefore: { label: 'Zip Code' },
                        inputText: { id: 'treatingProviderZipCode' }
                      })}
                    </div>
                  </div>
                </div>
                <div className="col-6 ps-1 border-start">
                  {inputTextEditor({
                    labelBefore: { label: '56a. Provider Specialty Code' },
                    inputText: { id: 'treatingProviderSpecialtyCode' }
                  })}
                </div>
                <Divider className="my-2" />
                <div className="col-6 pe-1">
                  {inputMaskEditor({
                    labelBefore: { label: '57. Phone Number' },
                    inputMask: { id: 'treatingProviderPhone', mask: '(999) 999-9999' }
                  })}
                </div>
                <div className="col-6 ps-1 border-start">
                  {inputTextEditor({
                    labelBefore: { label: '58. Additional Provider ID' },
                    inputText: { id: 'treatingProviderAdditionalProviderId' }
                  })}
                </div>
              </div>
            </Panel>
          </div>
        </div>
      </div>
      <div className="bg-light p-3 d-flex justify-content-between">
        <div className='d-flex align-items-center'>
          <div className="text-danger">
            {reviewPreAuthorization.error && <span>{reviewPreAuthorization.error}</span>}
          </div>
          <div className="text-success">
            {reviewPreAuthorization.status === 'succeeded' && (
              <span>Pre-Authorization {reviewPreAuthorization.reviewType === 'approve' ? 'approved' : 'rejected'} successfully</span>
            )}
          </div>
        </div>
        <div>
          <Button
            type="submit"
            label={submitButton.label}
            icon="pi pi-check"
            className="p-button-success"
            loading={submitButton.loading}
            disabled={submitButton.disabled}
            tooltip={submitButton.tooltip}
            tooltipOptions={{ showOnDisabled: true, position: 'left' }}
          />
          {rejectButton && (
            <Button
              label={rejectButton.label}
              icon="pi pi-times"
              className="p-button-danger ms-2"
              onClick={handleFormReject}
              loading={rejectButton.loading}
              disabled={rejectButton.disabled}
              tooltip={rejectButton.tooltip}
              tooltipOptions={{ showOnDisabled: true, position: 'top' }}
            />
          )}
        </div>
      </div>

    </form>
  );
};
