import { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { applyFilters, formatField } from '../helpers';

const PIVOT_LABEL = 'pivot_label';

const GridBase = ({
  data,
  mappings,
  filtering,
  pagination,
  pivotColumn,
}) => {
  const [viewData, setViewData] = useState([]);

  // Apply default pagination settings if pagination is null or undefined
  pagination = pagination ?? { showPagination: true, pageSize: 10, pageSizeOptions: [5, 10, 25, 50] };

  if (!data || data.length === 0)
    return <div className={"text-center"}>No data available for this report</div>;

  if (!mappings || Object.entries(mappings).length == 0)
    return <div className={"text-center"}>No mappings available for this report</div>;

  useEffect(() => {
    let filteredData = applyFilters(data, filtering);
    if (pivotColumn && pivotColumn.trim() !== "") {
      const pivoted = pivotData(filteredData, pivotColumn, mappings);
      setViewData(pivoted);
    } else {
      setViewData(filteredData);
    }
  }, [data, filtering, pivotColumn]);

  const pivotData = (data, pivotColumn, mappings) => {
    const pivoted = [];
    const pivotMapping = mappings[pivotColumn];
    const pivotValues = data.map(item => formatField(item[pivotColumn], pivotMapping.fieldType, pivotMapping.reportingProperties));

    Object.values(mappings).forEach(mapping => {
      const column = mapping.columnName;

      if (pivotColumn !== column) {
        const newRow = { [PIVOT_LABEL]: mapping.displayName };

        pivotValues.forEach((pv, index) => {
          const dataRow = data[index];
          newRow[pv] = formatField(dataRow[column], mapping.fieldType, mapping.reportingProperties);
        });
        pivoted.push(newRow);
      }
    });

    return pivoted;
  };

  const getDataType = (fieldType) => {
    switch (fieldType) {
      case 'dateonly':
        return 'date';
      case 'byte':
      case 'int16':
      case 'int32':
      case 'int64':
      case 'float':
      case 'double':
        return 'number';
      default:
        return 'string';
    }
  };

  const generateRegularColumns = () => {
    return Object.values(mappings).map(mapping => (
      <Column
        key={mapping.id}
        field={mapping.columnName}
        header={mapping.displayName}
        sortable
        dataType={getDataType(mapping.fieldType)}
        body={(rowData) => formatField(rowData[mapping.columnName], mapping.fieldType, mapping.reportingProperties)}
      />
    ));
  };

  const generatePivotedColumns = () => {
    if (viewData.length === 0) return null;

    const pivotKeys = Object.keys(viewData[0]).filter(key => key !== PIVOT_LABEL);

    return [
      <Column key={PIVOT_LABEL} field={PIVOT_LABEL} header="" style={{ minWidth: '300px' }} />,
      ...pivotKeys.map(pivotKey => (
        <Column key={pivotKey} field={pivotKey} header={pivotKey} />
      )),
    ];
  };

  return (
    <DataTable
      value={viewData}
      rowHover
      removableSort
      scrollable
      scrollHeight="flex"
      paginator={pagination.showPagination}
      rows={pagination.pageSize}
      rowsPerPageOptions={pagination.pageSizeOptions}
      emptyMessage="No data found"
      tableClassName="small"
      paginatorClassName="small"
      pt={{
        column: {
          headerCell: {
            style: {
              padding: '.6rem',
            },
          },
          bodyCell: {
            style: {
              padding: '.6rem',
            },
          },
        }
      }}
    >
      {pivotColumn && pivotColumn.trim() !== "" ? generatePivotedColumns() : generateRegularColumns()}
    </DataTable>
  );
};

export default GridBase;
