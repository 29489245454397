import { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Menubar } from 'primereact/menubar';
import { PrimeIcons } from 'primereact/api';
import { ProgressSpinner } from 'primereact/progressspinner';

import { authActions, reportsActions } from '../_store';
import { history, isTokenExpired, isUserGroupAllowed } from '../_helpers';

export function NavMenu() {
  const dispatch = useDispatch();
  const { authUser } = useSelector((state) => state.auth);
  const { status: reportsStatus } = useSelector((x) => x.reports.getAllReports);
  const { reportsList } = useSelector((x) => x.reports);

  const isFirstFetchRef = useRef(true);
  useEffect(() => {
    if (!isTokenExpired(authUser) && isFirstFetchRef.current) {
      dispatch(reportsActions.getAllReports());
      isFirstFetchRef.current = false;
    } else if (isTokenExpired(authUser)) {
      isFirstFetchRef.current = true;
    }
  }, [authUser, dispatch]);

  const handleLogout = () => {
    localStorage.removeItem('authUser');
    return dispatch(authActions.logout());
  };

  const start = <div className="p-menubar-logo">
    <picture>
      <source
        media="(max-width: 720px)"
        srcSet='/logo_horizontal-91x32.png 720w'
        sizes="720px"
      />
      <img src="/logo_horizontal-159x56.png" />
    </picture>
  </div>;

  const items = [
    {
      label: 'Home',
      icon: PrimeIcons.HOME,
      command: () => { history.navigate('/'); },
    },
    !isTokenExpired(authUser) && {
      label: (
        <span>
          Reports
          {
            reportsStatus === 'loading'
            && <ProgressSpinner style={{ width: '20px', height: '20px', marginLeft: '10px', animationDuration: '4.0s' }} strokeWidth="4" />
          }
        </span>
      ),
      icon: PrimeIcons.FILE,
      items: reportsList.map((report) => ({
        label: report.name,
        command: () => { history.navigate(`/reports/${report.id}`); },
      })) || [],
    },
    !isTokenExpired(authUser) && isUserGroupAllowed(authUser, ['Administrators', 'TPA-Monitors', 'TPA-Dentists']) && {
      label: 'Pre-Auth',
      icon: PrimeIcons.FILE_CHECK,
      command: () => { history.navigate('/pre-authorizations'); },
    },
    !isTokenExpired(authUser) && isUserGroupAllowed(authUser, ['Administrators']) && {
      label: 'Admin',
      icon: PrimeIcons.COG,
      items: [
        {
          label: 'Rules Engine Editor',
          icon: PrimeIcons.FILE_CHECK,
          command: () => { history.navigate('/rules-engine-editor'); },
        },
        {
          label: 'Data Sources',
          icon: PrimeIcons.DATABASE,
          command: () => { history.navigate('/source-manager'); },
        }
      ]
    },
    {
      label: 'Logout',
      icon: PrimeIcons.SIGN_OUT,
      command: handleLogout
    }
  ].filter(Boolean); // Filter out falsey values (null or undefined)

  return (
    <header>
      <Menubar
        model={items}
        start={start}
        pt={{
          root: { style: { display: 'flex', justifyContent: 'space-between' } }
        }}
      />
    </header>
  );
}
