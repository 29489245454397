import { jwtDecode } from 'jwt-decode';

export const isUserGroupAllowed = (authUser, groupsAllowed) => {
  if (groupsAllowed.length === 0) return true;
  const userGroups = getUserGroupsFromCognitoToken(authUser);
  return userGroups.some(group => groupsAllowed.includes(group));
};

const getUserGroupsFromCognitoToken = (authUser) => {
  const decodedToken = jwtDecode(authUser.idToken);
  const roles = decodedToken['cognito:groups'] || [];
  return roles;
};

export const getUserFullNameFromCognitoToken = (authUser) => {
  const decodedToken = jwtDecode(authUser.idToken);
  return `${decodedToken['given_name']} ${decodedToken['family_name']}`;
};
