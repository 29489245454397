export const isTokenExpired = (authUser) => {
  if (!authUser?.expiryTime) return true;

  const currentTime = new Date().getTime();
  const expiryTime = new Date(authUser.expiryTime).getTime();
  return currentTime > expiryTime;
};

export const isTokenAboutToExpire = (authUser) => {
  if (!authUser?.expiryTime) return false;

  const currentTime = new Date().getTime();
  const minutesBeforeExpire = 5;
  const timeAhead = currentTime + minutesBeforeExpire * 60000;
  const expiryTime = new Date(authUser.expiryTime).getTime();
  return timeAhead >= expiryTime;
};