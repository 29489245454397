import { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from './_store';
import { isTokenExpired, isTokenAboutToExpire } from './_helpers';
import { SessionExpiryDialog } from './auth';

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { authUser, refreshToken, logout } = useSelector((state) => state.auth);
  const [isSessionExpiryDialogVisible, setSessionExpiryDialogVisible] = useState(false);
  const lastInteractionTimeRef = useRef(Date.now());
  const refreshIntervalRef = useRef(null);
  const tokenCheckIntervalRef = useRef(null);

  // Function to refresh the token
  const refreshTokenIfNeeded = useCallback(() => {
    // Start token refresh only if the user is logged in and the session expiry dialog is not visible
    if (!isTokenExpired(authUser) && !isSessionExpiryDialogVisible && logout.status === 'idle') {
      const { refreshToken: token } = authUser;
      dispatch(authActions.refreshToken({ token }));
    }
  }, [authUser, dispatch, isSessionExpiryDialogVisible]);

  // Setup user interaction event listeners
  useEffect(() => {
    const updateLastInteractionTime = () => {
      lastInteractionTimeRef.current = Date.now();
    };

    const events = ['click', 'keydown', 'mousemove', 'scroll', 'touchstart', 'touchmove'];
    events.forEach(event => window.addEventListener(event, updateLastInteractionTime));

    return () => {
      events.forEach(event => window.removeEventListener(event, updateLastInteractionTime));
    };
  }, []);

  // Handle token refresh based on user interaction
  useEffect(() => {
    refreshIntervalRef.current = setInterval(() => {
      const timeElapsed = Date.now() - lastInteractionTimeRef.current;
      if (timeElapsed <= 5 * 60 * 1000) { // If interaction was within the last 5 minutes
        refreshTokenIfNeeded();
      }
    }, 3 * 60 * 1000); // Check every 3 minutes

    return () => refreshIntervalRef.current && clearInterval(refreshIntervalRef.current);
  }, [refreshTokenIfNeeded]);

  // Handle token status monitoring and session expiry dialog
  useEffect(() => {
    const checkTokenStatus = () => {
      if (isTokenExpired(authUser)) {
        dispatch(authActions.logout());
        isSessionExpiryDialogVisible && setSessionExpiryDialogVisible(false);
      } else if (isTokenAboutToExpire(authUser) && !refreshToken.error) {
        setSessionExpiryDialogVisible(true);
      }
    };

    if (!isTokenExpired(authUser)) {
      if (!tokenCheckIntervalRef.current) {
        tokenCheckIntervalRef.current = setInterval(checkTokenStatus, 10 * 1000); // Check every 10 seconds
      }
    } else if (tokenCheckIntervalRef.current) {
      clearInterval(tokenCheckIntervalRef.current);
      tokenCheckIntervalRef.current = null;
      isSessionExpiryDialogVisible && setSessionExpiryDialogVisible(false);
    }

    return () => {
      if (tokenCheckIntervalRef.current) {
        clearInterval(tokenCheckIntervalRef.current);
        tokenCheckIntervalRef.current = null;
      }
    };
  }, [authUser, dispatch, refreshToken.error]);

  // Handle token refresh success or error
  useEffect(() => {
    if (refreshToken.status === 'success') {
      localStorage.setItem('authUser', JSON.stringify(authUser));
      dispatch(authActions.resetRefreshTokenStatus());
      isSessionExpiryDialogVisible && setSessionExpiryDialogVisible(false);
    } else if (refreshToken.status === 'error') {
      if (!isSessionExpiryDialogVisible) {
        dispatch(authActions.logout());
      }
      isSessionExpiryDialogVisible && setSessionExpiryDialogVisible(false);
    }
  }, [refreshToken.status, authUser, dispatch, isSessionExpiryDialogVisible]);

  // Handle logout status
  useEffect(() => {
    if (logout.status === 'success') {
      localStorage.removeItem('authUser');
      dispatch(authActions.resetLogoutStatus());
      isSessionExpiryDialogVisible && setSessionExpiryDialogVisible(false);
    }
  }, [logout.status, dispatch]);

  const handleSessionExpiryDialogLogout = () => {
    dispatch(authActions.logout());
  };

  return (
    <>
      {children}
      <SessionExpiryDialog
        visible={isSessionExpiryDialogVisible}
        onLogout={handleSessionExpiryDialogLogout}
      />
    </>
  );
};
