import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers";

const name = "rules";

const initialState = {
  ruleMap: {},  // Map to store direct references to rule objects by their IDs
  // getRules: {},  // getRules not used because we are fetching rules for workflows at windows.slice.js and passed down to this component
  createRuleStates: {},
  changeRuleNameStates: {},
  changeRuleEnabledStates: {},
  deleteRuleStates: {},
  changeRuleOperatorStates: {},
  changeRuleExpressionStates: {},
  changeRuleActionsStates: {},
  changeSuccessEventStates: {},
  changeErrorMessageStates: {},
};

// extra actions
const baseUrl = `${process.env.REACT_APP_API_URL ?? ""}/api`;
const createDefaultRule = createAsyncThunk(
  `${name}/createDefaultRule`,
  async ({ parentType, parentId, parentKey }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.post(`${baseUrl}/rules/create-default`, { parentType, parentId });
      return { parentKey, response};
    } catch (error) {
      return rejectWithValue({ parentKey, error: error.message });
    }
  },
);
const changeRuleName = createAsyncThunk(
  `${name}/changeRuleName`,
  async ({ ruleId, newName }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.patch(`${baseUrl}/rules/${ruleId}`, { ruleName: newName, propertiesUpdated: ["ruleName"] });
      return { ruleId, response };
    } catch (error) {
      return rejectWithValue({ ruleId, error: error.message });
    }
  },
);
const changeRuleEnabled = createAsyncThunk(
  `${name}/changeRuleEnabled`,
  async ({ ruleId, newEnabled }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.patch(`${baseUrl}/rules/${ruleId}`, { enabled: newEnabled, propertiesUpdated: ["enabled"] });
      return { ruleId, response };
    } catch (error) {
      return rejectWithValue({ ruleId, error: error.message });
    }
  },
);
const deleteRule = createAsyncThunk(
  `${name}/deleteRule`,
  async ({ ruleId }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.delete(`${baseUrl}/rules/${ruleId}`);
      return { ruleId, response };
    } catch (error) {
      return rejectWithValue({ ruleId, error: error.message });
    }
  },
);
const changeRuleOperator = createAsyncThunk(
  `${name}/changeRuleOperator`,
  async ({ ruleId, newOperator }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.patch(`${baseUrl}/rules/${ruleId}`, { operator: newOperator, propertiesUpdated: ["operator"] });
      return { ruleId, response };
    } catch (error) {
      return rejectWithValue({ ruleId, error: error.message });
    }
  },
);
const changeRuleExpression = createAsyncThunk(
  `${name}/changeRuleExpression`,
  async ({ ruleId, newExpression }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.patch(`${baseUrl}/rules/${ruleId}`, { expression: newExpression, propertiesUpdated: ["expression"] });
      return { ruleId, response };
    } catch (error) {
      return rejectWithValue({ ruleId, error: error.message });
    }
  }
);
const changeRuleActions = createAsyncThunk(
  `${name}/changeRuleActions`,
  async ({ ruleId, newRuleActions }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.patch(`${baseUrl}/rules/${ruleId}`, { actionsJson: newRuleActions, propertiesUpdated: ["actions"] });
      return { ruleId, response };
    } catch (error) {
      return rejectWithValue({ ruleId, error: error.message });
    }
  }
);
const changeSuccessEvent = createAsyncThunk(
  `${name}/changeSuccessEvent`,
  async ({ ruleId, newSuccessEvent }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.patch(`${baseUrl}/rules/${ruleId}`, { successEvent: newSuccessEvent, propertiesUpdated: ["successEvent"] });
      return { ruleId, response };
    } catch (error) {
      return rejectWithValue({ ruleId, error: error.message });
    }
  }
);
const changeErrorMessage = createAsyncThunk(
  `${name}/changeErrorMessage`,
  async ({ ruleId, newErrorMessage }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.patch(`${baseUrl}/rules/${ruleId}`, { errorMessage: newErrorMessage, propertiesUpdated: ["errorMessage"] });
      return { ruleId, response };
    } catch (error) {
      return rejectWithValue({ ruleId, error: error.message });
    }
  }
);

const rulesSlice = createSlice({
  name,
  initialState,
  reducers: {
    initialize: (state, action) => {
      const workflowsData = action.payload;
      workflowsData.forEach(workflow => {
        const workflowKey = `workflow-${workflow.id}`;
        const rules = initializeRulesData(workflow.rules, workflowKey, state.ruleMap);
        state.ruleMap[workflowKey] = {...workflow, rules};
      });
    },
    addWorkflowToRulesMap: (state, action) => {
      const { workflowKey, workflow } = action.payload;
      state.ruleMap[workflowKey] = {
        ...workflow,
        rules: workflow.rules || []
      };
    },
    clearRulesForWorkflow: (state, action) => {
      const workflowId = action.payload;
      const workflowKey = `workflow-${workflowId}`;
      for (const rule of state.ruleMap[workflowKey].rules) {
        recursivelyRemoveRule(state, `rule-${rule.id}`);
      }
      delete state.ruleMap[workflowKey];
    },
  },
  extraReducers: (builder) => {
    builder
      // createDefaultRule
      .addCase(createDefaultRule.pending, (state, action) => {
        const { parentKey } = action.meta.arg;
        state.createRuleStates[parentKey] = { status: "loading", error: null };
      })
      .addCase(createDefaultRule.fulfilled, (state, action) => {
        const { parentKey, response } = action.payload;
        const { rule: newRule, parent } = response.message;
        const existingParent = state.ruleMap[parentKey];
        const existingRules = existingParent.rules || [];
        const updatedRules = [...existingRules, newRule];
        state.ruleMap[parentKey] = {
          ...parent,
          rules: updatedRules
        };
        
        state.ruleMap[`rule-${newRule.id}`] = newRule;

        state.createRuleStates[parentKey].status = "succeeded";
      })
      .addCase(createDefaultRule.rejected, (state, action) => {
        const { parentKey, error } = action.payload;
        state.createRuleStates[parentKey] = { status: 'failed', error: error };
      })
      
      // changeRuleName
      .addCase(changeRuleName.pending, (state, action) => {
        state.changeRuleNameStates[action.meta.arg.ruleId] = { status: 'loading', error: null };
      })
      .addCase(changeRuleName.fulfilled, (state, action) => {
        const { id: ruleId, ruleName: newName } = action.payload.response.message;
        const ruleKey = `rule-${ruleId}`;
        if (state.ruleMap[ruleKey]) {
          state.ruleMap[ruleKey].ruleName = newName;
        }
        state.changeRuleNameStates[ruleId] = { status: 'succeeded', error: null };
      })
      .addCase(changeRuleName.rejected, (state, action) => {
        const { ruleId, error } = action.payload;
        state.changeRuleNameStates[ruleId] = { status: 'failed', error: error };
      })

      // changeRuleEnabled
      .addCase(changeRuleEnabled.pending, (state, action) => {
        state.changeRuleEnabledStates[action.meta.arg.ruleId] = { status: 'loading', error: null };
      })
      .addCase(changeRuleEnabled.fulfilled, (state, action) => {
        const { id: ruleId, enabled: newEnabled } = action.payload.response.message;
        if (state.ruleMap[`rule-${ruleId}`]) {
          state.ruleMap[`rule-${ruleId}`].enabled = newEnabled;
        }
        state.changeRuleEnabledStates[ruleId] = { status: 'succeeded', error: null };
      })
      .addCase(changeRuleEnabled.rejected, (state, action) => {
        const { ruleId, error } = action.payload;
        state.changeRuleEnabledStates[ruleId] = { status: 'failed', error: error };
      })

      // deleteRule
      .addCase(deleteRule.pending, (state, action) => {
        state.deleteRuleStates[action.meta.arg.ruleId] = { status: 'loading', error: null };
      })
      .addCase(deleteRule.fulfilled, (state, action) => {
        const { ruleId } = action.payload;
        recursivelyRemoveRule(state, `rule-${ruleId}`);

        state.deleteRuleStates[ruleId] = { status: 'succeeded', error: null };
      })
      .addCase(deleteRule.rejected, (state, action) => {
        const { ruleId, error } = action.payload;
        state.deleteRuleStates[ruleId] = { status: 'failed', error: error };
      })

      // changeRuleOperator
      .addCase(changeRuleOperator.pending, (state, action) => {
        state.changeRuleOperatorStates[action.meta.arg.ruleId] = { status: 'loading', error: null };
      })
      .addCase(changeRuleOperator.fulfilled, (state, action) => {
        const { id: ruleId, operator: newOperator } = action.payload.response.message;
        if (state.ruleMap[`rule-${ruleId}`]) {
          state.ruleMap[`rule-${ruleId}`].operator = newOperator;
        }
        state.changeRuleOperatorStates[ruleId] = { status: 'succeeded', error: null };
      })
      .addCase(changeRuleOperator.rejected, (state, action) => {
        const { ruleId, error } = action.payload;
        state.changeRuleOperatorStates[ruleId] = { status: 'failed', error: error };
      })

      // changeRuleExpression
      .addCase(changeRuleExpression.pending, (state, action) => {
        state.changeRuleExpressionStates[action.meta.arg.ruleId] = { status: 'loading', error: null };
      })
      .addCase(changeRuleExpression.fulfilled, (state, action) => {
        const { id: ruleId, expression: newExpression } = action.payload.response.message;
        if (state.ruleMap[`rule-${ruleId}`]) {
          state.ruleMap[`rule-${ruleId}`].expression = newExpression;
        }
        state.changeRuleExpressionStates[ruleId] = { status: 'succeeded', error: null };
      })
      .addCase(changeRuleExpression.rejected, (state, action) => {
        const { ruleId, error } = action.payload;
        state.changeRuleExpressionStates[ruleId] = { status: 'failed', error: error };
      })
      
      // changeRuleActions
      .addCase(changeRuleActions.pending, (state, action) => {
        state.changeRuleActionsStates[action.meta.arg.ruleId] = { status: 'loading', error: null };
      })
      .addCase(changeRuleActions.fulfilled, (state, action) => {
        const { id: ruleId, actionsJson: newRuleActions } = action.payload.response.message;
        if (state.ruleMap[`rule-${ruleId}`]) {
          state.ruleMap[`rule-${ruleId}`].actionsJson = newRuleActions;
        }
        state.changeRuleActionsStates[ruleId] = { status: 'succeeded', error: null };
      })
      .addCase(changeRuleActions.rejected, (state, action) => {
        const { ruleId, error } = action.payload;
        state.changeRuleActionsStates[ruleId] = { status: 'failed', error: error };
      })
      
      // changeSuccessEvent
      .addCase(changeSuccessEvent.pending, (state, action) => {
        state.changeSuccessEventStates[action.meta.arg.ruleId] = { status: 'loading', error: null };
      })
      .addCase(changeSuccessEvent.fulfilled, (state, action) => {
        const { id: ruleId, successEvent: newSuccessEvent } = action.payload.response.message;
        if (state.ruleMap[`rule-${ruleId}`]) {
          state.ruleMap[`rule-${ruleId}`].successEvent = newSuccessEvent;
        }
        state.changeSuccessEventStates[ruleId] = { status: 'succeeded', error: null };
      })
      .addCase(changeSuccessEvent.rejected, (state, action) => {
        const { ruleId, error } = action.payload;
        state.changeSuccessEventStates[ruleId] = { status: 'failed', error: error };
      })
      
      // changeErrorMessage
      .addCase(changeErrorMessage.pending, (state, action) => {
        state.changeErrorMessageStates[action.meta.arg.ruleId] = { status: 'loading', error: null };
      })
      .addCase(changeErrorMessage.fulfilled, (state, action) => {
        const { id: ruleId, errorMessage: newErrorMessage } = action.payload.response.message;
        if (state.ruleMap[`rule-${ruleId}`]) {
          state.ruleMap[`rule-${ruleId}`].errorMessage = newErrorMessage;
        }
        state.changeErrorMessageStates[ruleId] = { status: 'succeeded', error: null };
      })
      .addCase(changeErrorMessage.rejected, (state, action) => {
        const { ruleId, error } = action.payload;
        state.changeErrorMessageStates[ruleId] = { status: 'failed', error: error };
      });
  }
});

// Helper functions
const initializeRulesData = (rules, parentPrefix, ruleMap) => {
  return rules.map(rule => {
    // Create a unique identifier for this rule that includes its type
    const currentRuleKey = `rule-${rule.id}`;
    
    // Construct a new rule object including the parentKey
    const enhancedRule = {
      ...rule,
      parentKey: parentPrefix,  // Adding parentKey to the new object
      rules: rule.rules && rule.rules.length > 0
        ? initializeRulesData(rule.rules, currentRuleKey, ruleMap)
        : []
    };

    // Add this enhanced rule to the ruleMap using its unique key
    ruleMap[currentRuleKey] = enhancedRule;
    return enhancedRule;
  });
};

function recursivelyRemoveRule(state, ruleKey) {
  const rule = state.ruleMap[ruleKey];
  if (!rule) return;

  // If the rule has sub-rules, call this function recursively on each
  if (rule.rules && rule.rules.length) {
    rule.rules.forEach(subRule => {
      recursivelyRemoveRule(state, `rule-${subRule.id}`);
    });
  }

  // Remove this rule from its parent's rules array
  if (rule.parentKey && state.ruleMap[rule.parentKey] && state.ruleMap[rule.parentKey].rules) {
    const parentRules = state.ruleMap[rule.parentKey].rules;
    const index = parentRules.findIndex(r => r.id === rule.id);
    if (index !== -1) {
      parentRules.splice(index, 1);
    }
  }

  // Finally, delete the rule from the ruleMap
  delete state.ruleMap[ruleKey];
}

export const { initialize, addWorkflowToRulesMap, clearRulesForWorkflow } = rulesSlice.actions;
export const rulesReducer = rulesSlice.reducer;

// Bundle and export the actions
export const rulesActions = {
  initialize,
  addWorkflowToRulesMap,
  clearRulesForWorkflow,
  createDefaultRule,
  changeRuleName,
  changeRuleEnabled,
  deleteRule,
  changeRuleOperator,
  changeRuleExpression,
  changeRuleActions,
  changeSuccessEvent,
  changeErrorMessage,
};