import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { isTokenExpired, isUserGroupAllowed } from '../_helpers';

export const PrivateRoute = ({ children, groupsAllowed = [] }) => {
  const { authUser } = useSelector((state) => state.auth);
  const location = useLocation();

  // Determine if the user should be redirected
  const shouldRedirectToLogin = isTokenExpired(authUser);

  if (shouldRedirectToLogin) {
    // Redirect to login page with return URL if not authenticated
    return <Navigate to="/login" state={{ from: location }} />;
  }

  const shouldRedirectToHome = !isUserGroupAllowed(authUser, groupsAllowed);
  if (shouldRedirectToHome) {
    // Redirect to home page if not authorized
    return <Navigate to="/" />;
  }

  // Render children if the user is authorized
  return <>{children}</>;
};
