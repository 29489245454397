import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { dataSourceMappingsActions } from '../../_store';

const selectMappingsByDataSourceId = createSelector(
  (state) => state.dataSourceMappings.data,
  (_, dataSourceId) => dataSourceId,
  (mappings, dataSourceId) => mappings[dataSourceId] || []
);

const selectFetchStatusByDataSourceId = createSelector(
  (state) => state.dataSourceMappings.fetchMappingsFromConnectionStatusStates,
  (_, dataSourceId) => dataSourceId,
  (fetchStatuses, dataSourceId) => fetchStatuses[dataSourceId] || { status: 'idle', error: null }
);

const selectSaveStatusByDataSourceId = createSelector(
  (state) => state.dataSourceMappings.saveMappingsStatusStates,
  (_, dataSourceId) => dataSourceId,
  (saveStatuses, dataSourceId) => saveStatuses[dataSourceId] || { status: 'idle', error: null }
);

const customfilterTypeOptionsByFieldType = {
  dateonly: ['DateRange'],
  // Add more field types as needed...
};

const getFilterTypesForFieldType = (fieldType) => {
  const customOptions = customfilterTypeOptionsByFieldType[fieldType] || [];
  return [null, 'Default', 'Dropdown', ...customOptions]
    .map((type) => ({ label: type || '(null)', value: type }));
};

const DataSourceMappingsManager = ({ dataSourceId }) => {
  const dispatch = useDispatch();
  const mappings = useSelector((state) => selectMappingsByDataSourceId(state, dataSourceId));
  const mappingTypes = useSelector((state) => state.dataSourceMappings.mappingTypes);
  const fetchStatusFromConnectionStatus = useSelector((state) => selectFetchStatusByDataSourceId(state, dataSourceId));
  const saveStatus = useSelector((state) => selectSaveStatusByDataSourceId(state, dataSourceId));
  const [localMappings, setLocalMappings] = useState(mappings);

  const [jsonErrors, setJsonErrors] = useState({});

  useEffect(() => {
    setLocalMappings(mappings);
  }, [mappings]);

  const handleEditorValueChange = (rowIndex, updatedFields) => {
    const updatedMappings = [...localMappings];
    updatedMappings[rowIndex] = { ...updatedMappings[rowIndex], ...updatedFields };
    setLocalMappings(updatedMappings);
  };

  const inputTextEditor = (props) => (
    <InputText
      value={props.rowData[props.field]}
      onChange={(e) => {
        props.rowData[props.field] = e.target.value;
        handleEditorValueChange(props.rowIndex, { [props.field]: e.target.value });
      }}
    />
  );

  const inputTextareaEditor = (props) => (
    <InputTextarea
      value={props.rowData[props.field] || ''}
      autoResize
      rows={2}
      className={'p-2 w-100'}
      onChange={(e) => {
        props.rowData[props.field] = e.target.value;
        handleEditorValueChange(props.rowIndex, { [props.field]: e.target.value });
      }}
      pt={{
        root: {
          style: {
            resize: 'none',
          }
        }
      }}
    />
  );

  const fieldTypeDropdownEditor = (props) => {
    const options = mappingTypes.map((type) => ({ label: type, value: type }));

    return (
      <Dropdown
        value={props.rowData[props.field]}
        options={options}
        onChange={(e) => {
          props.rowData[props.field] = e.value;
          handleEditorValueChange(props.rowIndex, {
            [props.field]: e.value,
            filterType: null,
          });
        }}
        placeholder='Select Field Type'
      />
    );
  };

  const filterTypeDropdownEditor = (props) => {
    const options = getFilterTypesForFieldType(props.rowData.fieldType);

    return (
      <Dropdown
        value={props.rowData[props.field]}
        options={options}
        onChange={(e) => {
          const parsedValue = typeof e.value === 'object' ? e.value.value : e.value;
          props.rowData[props.field] = parsedValue;
          handleEditorValueChange(props.rowIndex, { [props.field]: parsedValue });
        }}
        placeholder='Select Filter Type'
      />
    );
  };

  const jsonEditor = (props) => (
    <div>
      <InputTextarea
        value={JSON.stringify(props.rowData[props.field], null, 2) || ''}
        autoResize
        rows={4}
        onChange={(e) => {
          const updatedErrors = { ...jsonErrors, [props.rowIndex]: null };

          try {
            const parsedValue = JSON.parse(e.target.value);
            props.rowData[props.field] = parsedValue;
            handleEditorValueChange(props.rowIndex, { reportingProperties: parsedValue });
          } catch (error) {
            updatedErrors[props.rowIndex] = 'Invalid JSON format';
          }

          setJsonErrors(updatedErrors);
        }}
        pt={{
          root: {
            style: {
              resize: 'none',
            }
          }
        }}
      />
      {jsonErrors[props.rowIndex] && <div className="text-danger">{jsonErrors[props.rowIndex]}</div>}
    </div>
  );

  const moveUp = (rowIndex) => {
    if (rowIndex > 0) {
      // Create a new array to maintain immutability
      const newMappings = [...localMappings];

      // Swap the items immutably by creating new objects
      const temp = { ...newMappings[rowIndex - 1], seq: rowIndex + 1 };
      newMappings[rowIndex - 1] = { ...newMappings[rowIndex], seq: rowIndex };
      newMappings[rowIndex] = temp;

      // Update the localMappings with the new array
      setLocalMappings(newMappings);
    }
  };

  const moveDown = (rowIndex) => {
    if (rowIndex < localMappings.length - 1) {
      // Create a new array to maintain immutability
      const newMappings = [...localMappings];

      // Swap the items immutably by creating new objects
      const temp = { ...newMappings[rowIndex + 1], seq: rowIndex + 1 };
      newMappings[rowIndex + 1] = { ...newMappings[rowIndex], seq: rowIndex + 2 };
      newMappings[rowIndex] = temp;

      // Update the localMappings with the new array
      setLocalMappings(newMappings);
    }
  };

  const sequenceTemplate = (_, { rowIndex }) => (
    <div style={{ fontSize: "0.5em" }}>
      <Button
        icon="pi pi-arrow-up"
        className="p-button-text"
        onClick={() => moveUp(rowIndex)}
        disabled={rowIndex === 0}
        aria-label="Move Up"
      />
      <Button
        icon="pi pi-arrow-down"
        className="p-button-text"
        onClick={() => moveDown(rowIndex)}
        disabled={rowIndex === localMappings.length - 1}
        aria-label="Move Down"
      />
    </div>
  );

  const handleUpdateFromConnection = () => {
    dispatch(dataSourceMappingsActions.fetchMappingsFromConnection({ dataSourceId: dataSourceId }));
  };

  const handleDeleteAllMappings = () => {
    dispatch(dataSourceMappingsActions.deleteAllMappings({ dataSourceId: dataSourceId }));
  };

  const handleDeleteMapping = (columnName) => {
    dispatch(dataSourceMappingsActions.deleteMapping({ dataSourceId: dataSourceId, columnName }));
  };

  const handleSaveMappings = () => {
    dispatch(dataSourceMappingsActions.saveMappings({ dataSourceId: dataSourceId, mappings: localMappings }));
  };

  return (
    <div className="small">
      <DataTable
        value={localMappings}
        rowHover
        editMode="cell"
        scrollable
        scrollHeight="800px"
        emptyMessage="No mappings found"
        resizableColumns
      >
        <Column field="id" hidden alignHeader='center' />
        <Column
          field="columnName"
          header="Column Name"
          frozen
          alignFrozen='left'
          alignHeader='center'
          bodyStyle={{ backgroundColor: 'rgb(249, 250, 251)', fontWeight: 'bold' }}
        />
        <Column
          field="fieldType"
          header="Field Type"
          editor={(props) => fieldTypeDropdownEditor(props)}
          style={{ width: '150px' }}
          alignHeader='center'
        />
        <Column field="displayName" header="Display Name" editor={inputTextEditor} alignHeader='center' />
        <Column field="filterName" header="Filter Name" editor={inputTextEditor} alignHeader='center' />
        <Column
          field="filterType"
          header="Filter Type"
          editor={(props) => filterTypeDropdownEditor(props)}
          style={{ width: '150px' }}
          alignHeader='center'
        />
        <Column
          field="reportingProperties"
          header="Reporting Properties"
          editor={jsonEditor}
          alignHeader='center'
          body={(rowData) => JSON.stringify(rowData.reportingProperties, null, 2)}
        />
        <Column field="mask" header="Mask" editor={inputTextareaEditor} alignHeader='center' />
        <Column field="seq" header="Sequence" hidden alignHeader='center' />

        <Column body={sequenceTemplate} header="" style={{ width: '60px', padding: ".5rem" }} alignHeader='center' />

        <Column
          body={(rowData) => (
            <Button
              icon="pi pi-trash"
              severity="danger"
              className="p-button-text"
              onClick={() => handleDeleteMapping(rowData.columnName)}
              aria-label="Delete"
            />
          )}
          header=""
          style={{ width: '60px', padding: ".5rem" }}
          alignHeader='center'
        />
      </DataTable>

      <div className="row mt-3">
        {saveStatus.status === 'succeeded' && (
          <div className="col d-flex justify-content-start text-success">
            Mappings saved successfully
          </div>
        )}
        <div className="col d-flex justify-content-end small">
          <Button
            label="Update from Connection"
            onClick={handleUpdateFromConnection}
            disabled={saveStatus.status === 'loading' || fetchStatusFromConnectionStatus.status === 'loading'}
            className='me-2'
            severity='info'
            outlined
          />
          <Button
            label="Clear"
            onClick={handleDeleteAllMappings}
            disabled={saveStatus.status === 'loading' || fetchStatusFromConnectionStatus.status === 'loading'}
            className='me-2'
            severity='danger'
            outlined
          />
          <Button
            label="Save"
            onClick={handleSaveMappings}
            disabled={saveStatus.status === 'loading' || fetchStatusFromConnectionStatus.status === 'loading'}
          />
        </div>
      </div>
      {fetchStatusFromConnectionStatus.error && (
        <div className="text-danger mt-3" role="alert">
          Error fetching mappings: {fetchStatusFromConnectionStatus.error}
        </div>
      )}
      {saveStatus.error && (
        <div className="text-danger mt-3" role="alert">
          Error saving mappings: {saveStatus.error}
        </div>
      )}
    </div>
  );
};

export default DataSourceMappingsManager;
