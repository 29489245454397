import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../_store';

export function LoginWelcome() {
  const dispatch = useDispatch();
  const { userName } = useSelector((state) => state.auth.userInfo);

  useEffect(() => {
    dispatch(authActions.getUserInfo());
  }, [dispatch]);

  return (
    <div className="d-flex flex-column flex-grow-1 align-items-center justify-content-center">
      <img src="/logo.png" className="img-fluid" style={{ maxWidth: "80%" }} title="M2Rs Solutions" />
      <h1 className='display-1 fw-bold text-center mt-3' style={{ color: "#26849f" }}>Automated Claim Adjudication</h1>
      <p className='fs-3'>Welcome, <b>{userName}</b></p>
    </div>
  );
}