import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';

import { Input } from '../components/Input';
import { password_validation } from '../utils/inputValidations';
import { history } from '../_helpers';
import { authActions } from '../_store';
import { Authentication } from './Authentication';

import { debounce } from "lodash";

export const NewPasswordRequired = () => {
  const dispatch = useDispatch();
  const { error: authError, passwordPolicy: authPasswordPolicy, newPasswordData, userNewPassTokenAfterAuth, navigateToLogin } = useSelector(x => x.auth);

  useEffect(() => {
    // Redirect to last page when there is not challenge information
    if (!newPasswordData || newPasswordData.challengeName !== 'NEW_PASSWORD_REQUIRED') {
      history.navigate('/');
      return;
    }

    if (navigateToLogin) {
      history.navigate('/login');
      dispatch(authActions.resetNavigateToLogin());
      return;
    }

    if (userNewPassTokenAfterAuth != null) {
      localStorage.setItem('authUser', userNewPassTokenAfterAuth);
      dispatch(authActions.resetNewPassTokenAfterAuth());
      history.navigate('/');
      return;
    }
  }, [newPasswordData, userNewPassTokenAfterAuth, navigateToLogin, dispatch]);

  const methods = useForm();
  let isSubmitting = methods.formState.isSubmitting;

  function onSubmit({ newPassword, confirmPassword }) {
    if (newPassword !== confirmPassword) {
      dispatch(authActions.setErrorMessage({ message: 'Passwords do not match.' }));
      return;
    }

    const { username, session } = newPasswordData;
    return dispatch(authActions.completeNewPasswordRequired({ username, session, newPassword }));
  }

  const handleInputChangeDebounced = debounce(password => {
    return dispatch(authActions.checkPasswordPolicy({ password: password }));
  }, 300);

  function checkPasswordPolicy(e) {
    const newPassword = e.target.value;
    handleInputChangeDebounced(newPassword);
  }

  return (
    <Authentication>
      <div className="auth-container card border-0 shadow strong rounded w-100">
        <div className="card-body p-5 text-center">
          <FormProvider {...methods}>
            <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
              <h2 className="mb-5">New Password required</h2>
              {/* New password */}
              <Input
                {...password_validation}
                id={'new-user-password'}
                name={'newPassword'}
                label={'New Password'}
                onInputChange={checkPasswordPolicy}
              />
              {/* Confirm password */}
              <Input
                {...password_validation}
                id={'confirm-user-password'}
                name={'confirmPassword'}
                label={'Confirm Password'}
              />

              {/* Submit button */}
              <button
                type="submit"
                className="btn btn-primary btn-block btn-lg shadow"
                disabled={isSubmitting}
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                Change Password
              </button>
              <ul className="list-group d-flex flex-column align-items-start mt-4">
                <li className="list-group-item border-0 p-0">
                  {authPasswordPolicy.minlength
                    ? <i className="pi pi-check" style={{'color':'green'}}></i>
                    : <i className="pi pi-times" style={{'color':'red'}}></i>}
                  <span className="ms-1">Minimum 6 Characters</span>
                </li>
                <li className="list-group-item border-0 p-0">
                  {authPasswordPolicy.uppercase
                    ? <i className="pi pi-check" style={{'color':'green'}}></i>
                    : <i className="pi pi-times" style={{'color':'red'}}></i>}
                  <span className="ms-1">At least 1 Uppercase</span>
                </li>
                <li className="list-group-item border-0 p-0">
                  {authPasswordPolicy.lowercase
                    ? <i className="pi pi-check" style={{'color':'green'}}></i>
                    : <i className="pi pi-times" style={{'color':'red'}}></i>}
                  <span className="ms-1">At least 1 Lowercase</span>

                </li>
                <li className="list-group-item border-0 p-0">
                  {authPasswordPolicy.number
                    ? <i className="pi pi-check" style={{'color':'green'}}></i>
                    : <i className="pi pi-times" style={{'color':'red'}}></i>}
                  <span className="ms-1">At least 1 Number</span>
                </li>
              </ul>
              {authError && (
                <div className="alert alert-danger alert-dismissible fade show mt-4">
                  {authError.message}
                </div>
              )}
            </form>
          </FormProvider>
        </div>
      </div>
    </Authentication>
  );
};
