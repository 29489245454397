import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDrag, useDrop } from 'react-dnd';
import { createSelector } from 'reselect';
import { workflowsActions, rulesActions } from "../../_store";
import { Panel } from 'primereact/panel';
import { Button } from "primereact/button";
import { InputText  } from 'primereact/inputtext';
import Rule from './Rule';
import RulesEngineExecutorDialog from './RulesEngineExecutorDialog';

// Memoized selectors
const selectWorkflowChangeNameState = createSelector(
  state => state.workflows.changeWorkflowNameStates,
  (_, workflowId) => workflowId,
  (changeWorkflowNameStates, workflowId) => changeWorkflowNameStates[workflowId] || { status: 'idle', error: null }
);

const selectWorkflowDeleteState = createSelector(
  state => state.workflows.deleteWorkflowStates,
  (_, workflowId) => workflowId,
  (deleteWorkflowStates, workflowId) => deleteWorkflowStates[workflowId] || { status: 'idle', error: null }
);

const selectCreateRuleState = createSelector(
  state => state.rules.createRuleStates,
  (_, workflowId) => workflowId,
  (createRuleStates, workflowId) => createRuleStates[`workflow-${workflowId}`] || { status: 'idle', error: null }
);

const selectRulesData = createSelector(
  state => state.rules.ruleMap, 
  (_, workflowId) => workflowId,
  (ruleMap, workflowId) => ruleMap[`workflow-${workflowId}`]?.rules || []
);

const Workflow = ({ workflow, index, moveWorkflow, workflowsSeq }) => {
  const dispatch = useDispatch();
  const workflowChangeNameState = useSelector(state => selectWorkflowChangeNameState(state, workflow.id));
  const workflowDeleteState = useSelector(state => selectWorkflowDeleteState(state, workflow.id));
  const [inputValue, setInputValue] = useState(workflow.workflowName);
  const [validationError, setValidationError] = useState(null);
  const workflowRef = useRef(null);

  // Rules related states
  const rulesData = useSelector(state => selectRulesData(state, workflow.id));
  const createRuleState = useSelector(state => selectCreateRuleState(state, workflow.id));

  // Rules Engine Executor dialog
  const [showRulesEngineExecutorDialog, setRulesEngineExecutorShowDialog] = useState(false);
  const toggleRulesEngineExecutorDialog = () => setRulesEngineExecutorShowDialog(!showRulesEngineExecutorDialog);

  useEffect(() => {
    setInputValue(workflow.workflowName);
  }, [workflow.workflowName]);

  const handleWorkflowNameChange = (e) => {
    const newName = e.target.value;
    setValidationError(null);
    setInputValue(newName);
  };

  const handleWorkflowNameBlur = (e) => {
    const newWorkflowName = e.target.value.trim();
    setInputValue(newWorkflowName);
    if (newWorkflowName.length < 1 || newWorkflowName.length > 100) {
      setValidationError('Name must be between 1 and 100 characters');
      return;
    }

    if (newWorkflowName === workflow.workflowName) return;
    dispatch(workflowsActions.changeWorkflowName({ workflowId: workflow.id, newName: newWorkflowName }));
  };

  const handleWorkflowDelete = () => {
    dispatch(workflowsActions.deleteWorkflow({ workflowId: workflow.id }));
  };

  const handleAddRule = () => {
    dispatch(rulesActions.createDefaultRule({ parentType: 'workflow', parentId: workflow.id, parentKey: `workflow-${workflow.id}` }));
  };

  const [{ isDragging }, drag] = useDrag({
    type: 'workflow-panel',
    item: () => ({ type: 'workflow-panel', id: workflow.id, index, initialIndex: index }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult && item.initialIndex !== dropResult.finalIndex) {
        const updatedWorkflowsWithIndex = workflowsSeq.map((wf, index) => ({
          workflowId: wf.id,
          newSeq: index + 1
        }));

        dispatch(workflowsActions.updateWorkflowsSequence(
          {
            workflowMoved: {workflowId: item.id, initialIndex: item.initialIndex, finalIndex: dropResult.finalIndex},
            workflowsToUpdate: updatedWorkflowsWithIndex,
          }
        ));
      }
    },
  });

  const [, drop] = useDrop({
    accept: 'workflow-panel',
    hover(item, monitor) {
      if (!workflowRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = workflowRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveWorkflow(dragIndex, hoverIndex);
      item.index = hoverIndex; // Update the index for dragged item
    },
    drop: () => ({
      finalIndex: index,
    }),
  });

  drag(drop(workflowRef));

  const panelHeaderTemplate = (options) => {
    return (
      <div ref={workflowRef} className={options.className} style={{ cursor: 'grab' }}>
        {options.titleElement}
        {options.iconsElement}
      </div>
    );
  };

  return (
    <Panel 
      id={`workflow-${workflow.id}`} 
      header={`Workflow: ${workflow.workflowName}`}
      headerTemplate={panelHeaderTemplate}
      toggleable
      className="mb-1 small-content-padding"
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <label htmlFor={`workflow-name-${workflow.id}`} className="small ms-2">Workflow Name</label>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column flex-grow-1">            
          <InputText
            id={`workflow-name-${workflow.id}`}
            disabled={ workflowChangeNameState.status === 'loading' }
            placeholder={ workflowChangeNameState.status === 'loading' ? 'Saving...' : '' }
            value={ inputValue }
            onChange={handleWorkflowNameChange}
            onBlur={handleWorkflowNameBlur}
            invalid={ workflowChangeNameState.status === 'failed' }
            className='p-inputtext-sm w-100'
          />
        </div>
        <Button             
          onClick={handleWorkflowDelete}
          tooltip="Delete Workflow"
          text
          raised
          severity="danger"
          className="ms-3 py-2"
          icon="pi pi-trash"
          disabled={workflowDeleteState.status === 'loading'}
          loading={workflowDeleteState.status === 'loading'}
        >
        </Button>
      </div>
      {(workflowChangeNameState.error || workflowDeleteState.error || validationError) && (
        <div className="d-flex text-danger justify-content-between">
          {validationError && <small className="text-start">{validationError}</small>}
          {workflowChangeNameState.error && <small className="text-start">{workflowChangeNameState.error}</small>}
          {workflowDeleteState.error && <small className="text-end">{workflowDeleteState.error}</small>}
        </div>
      )}
      <div className="my-3">
        <div className="d-flex align-items-center">
          <h3 className="mb-0">Rules</h3>
          <Button 
            onClick={handleAddRule}
            label="New Rule" 
            text
            raised
            className="ms-3 py-2"
            size="small"
            style={{ fontSize: '0.85rem' }}
          />
          <div className="flex-grow-1"></div>
          <Button
            label="Open Rules Engine Executor"
            className="ms-2 py-2"
            onClick={toggleRulesEngineExecutorDialog}
            text
            raised
            size="small"
            style={{ fontSize: '0.85rem' }}
          />
          <RulesEngineExecutorDialog
            visible={showRulesEngineExecutorDialog}
            onHide={toggleRulesEngineExecutorDialog}
            workflow={workflow}
          />
        </div>
        {createRuleState.error && <small className="d-block text-danger">Error: {createRuleState.error}</small>}
      </div>
      {rulesData && rulesData.length > 0 ? (
        rulesData && rulesData.map((rule, idx) => (
          <Rule key={idx} ruleId={rule.id} />
        ))
      ) : (
        <div className="d-flex justify-content-center">
          No rules to display
        </div>
      )}
    </Panel>
  );
};

export default React.memo(Workflow);
