import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers";

const name = "dataConnections";

const initialState = {
  data: [], // Array of data connections
  testConnection: {
    status: "idle",
    error: null,
  },
  createConnection: {
    status: "idle",
    error: null,
  },
  updateConnection: {
    status: "idle",
    error: null,
  },
  deleteConnection: { 
    status: 'idle', 
    error: null 
  },
};

// extra actions
const baseUrl = `${process.env.REACT_APP_API_URL ?? ""}/api`;
const testConnection = createAsyncThunk(
  `${name}/testConnection`,
  async ({ connectionTest }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.post(`${baseUrl}/data-connections/test`, connectionTest);
      return response;
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  },
);
const createConnection = createAsyncThunk(
  `${name}/createConnection`,
  async ({ connection }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.post(`${baseUrl}/data-connections/create`, connection);
      return response;
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  },
);
const updateConnection = createAsyncThunk(
  `${name}/updateConnection`,
  async ({ dataConnectionId, connectionUpdate }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.patch(`${baseUrl}/data-connections/${dataConnectionId}`, connectionUpdate);
      return { dataConnectionId, response } ;
    } catch (error) {
      return rejectWithValue({ dataConnectionId, error: error.message });
    }
  },
);
const deleteConnection = createAsyncThunk(
  `${name}/deleteConnection`,
  async ({ dataSourceId, dataConnectionId }, { rejectWithValue }) => {
    try {
      // This API request will unbind the connections from the data sources and delete the data connection itself
      const response = await fetchWrapper.delete(`${baseUrl}/data-connections/${dataConnectionId}`);
      return { dataSourceId, dataConnectionId, response };
    } catch (error) {
      return rejectWithValue({ dataConnectionId, error: error.message });
    }
  },
);

const dataConnectionsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setDataConnections(state, action) {
      state.data = action.payload;
    },
    resetCreateConnection: (state) => {
      state.createConnection = initialState.createConnection;
    },
    resetUpdateConnection: (state) => {
      state.updateConnection = initialState.updateConnection;
    },
    resetTestConnection: (state) => {
      state.testConnection = initialState.testConnection;
    },
  },
  extraReducers: (builder) => {
    builder
    // testConnection
      .addCase(testConnection.pending, (state) => {
        state.testConnection.status = "loading";
        state.testConnection.error = null;
      })
      .addCase(testConnection.fulfilled, (state, action) => {
        const { testResult, success } = action.payload.message;
        if (success) {
          state.testConnection.status = "succeeded";
          state.testConnection.error = null;
        } else {
          state.testConnection.status = "failed";
          state.testConnection.error = testResult;
        }
      })
      .addCase(testConnection.rejected, (state, action) => {
        state.testConnection.status = "failed";
        state.testConnection.error = action.payload.error;
      })
      
      // createConnection
      .addCase(createConnection.pending, (state) => {
        state.createConnection.status = "loading";
        state.createConnection.error = null;
      })
      .addCase(createConnection.fulfilled, (state, action) => {
        const { dataConnection } = action.payload.message;
        // Find the correct insertion index
        const insertIndex = binarySearchInsertIndex(
          state.data, 
          dataConnection, 
          (a, b) => a.name.localeCompare(b.name)
        );

        // Insert the new connection at the correct index
        state.data.splice(insertIndex, 0, dataConnection);
        state.createConnection.status = "succeeded";
      })
      .addCase(createConnection.rejected, (state, action) => {
        state.createConnection.status = "failed";
        state.createConnection.error = action.payload.error;
      })
      
      // updateConnection
      .addCase(updateConnection.pending, (state) => {
        state.updateConnection.status = "loading";
        state.updateConnection.error = null;
      })
      .addCase(updateConnection.fulfilled, (state, action) => {
        const dataConnectionId = action.payload.dataConnectionId;
        const updatedDataConnection = action.payload.response.message;
        
        state.data = state.data.map(dataConnection =>
          dataConnection.id === dataConnectionId ? updatedDataConnection : dataConnection
        );
        state.updateConnection.status = 'succeeded';
        state.updateConnection.error = null;
      })
      .addCase(updateConnection.rejected, (state, action) => {
        const { error } = action.payload;
        state.updateConnection.status = 'failed';
        state.updateConnection.error = error;
      })
      
      // deleteConnection
      .addCase(deleteConnection.pending, (state) => {
        state.deleteConnection = { status: "loading", error: null };
      })
      .addCase(deleteConnection.fulfilled, (state, action) => {
        const dataConnectionId = action.payload.dataConnectionId;

        // Remove the connection from the state
        state.data = state.data.filter(dc => dc.id !== dataConnectionId);

        state.deleteConnection = { status: "succeeded", error: null };
      })
      .addCase(deleteConnection.rejected, (state, action) => {
        const { error } = action.payload;
        state.deleteConnection = { status: "failed", error };
      });
  },
});

// Binary search function to find the correct insertion index
const binarySearchInsertIndex = (array, newItem, compareFunction) => {
  let low = 0;
  let high = array.length;

  while (low < high) {
    const mid = Math.floor((low + high) / 2);
    if (compareFunction(array[mid], newItem) < 0) {
      low = mid + 1;
    } else {
      high = mid;
    }
  }

  return low;
};

export const { setDataConnections, resetCreateConnection, resetUpdateConnection, resetTestConnection } = dataConnectionsSlice.actions;
export const dataConnectionsReducer = dataConnectionsSlice.reducer;

// Bundle and export the actions
export const dataConnectionsActions = {
  setDataConnections,
  resetCreateConnection,
  resetUpdateConnection,
  createConnection,
  updateConnection,
  deleteConnection,
  testConnection,
  resetTestConnection,
};
