import { useEffect, useState, memo } from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';

const ReviewConfirmationDialog = ({ isVisible, onHide, currentAction, handleReviewSubmit }) => {
  const [reason, setReason] = useState('');

  useEffect(() => {
    if (isVisible) {
      setReason('');
    }
  }, [isVisible]);

  const onSubmit = () => {
    handleReviewSubmit(reason);
    onHide();
  };

  const customConfirmationDialogFooter = () => {
    const parsedReason = reason.trim() !== '' ? reason.trim() : null;
    return (
      <div>
        <Button label="Cancel" className="p-button-secondary" onClick={onHide} />
        <Button
          label={currentAction === 'approve' ? 'Approve' : 'Reject'}
          className="p-button-primary ms-2"
          onClick={onSubmit}
          disabled={currentAction === 'reject' && !parsedReason}
          tooltip={currentAction === 'reject' && !parsedReason ? 'Please provide a reason for rejection' : null}
          severity={currentAction === 'approve' ? 'success' : 'danger'}
        />
      </div>
    );
  };

  return (
    <ConfirmDialog
      visible={isVisible}
      onHide={onHide}
      message={
        <div>
          <p>Please provide a reason for {currentAction === 'approve' ? 'approval (Optional)' : 'rejection (Required)'}:</p>
          <InputTextarea
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            rows={5}
            cols={50}
            pt={{
              root: {
                style: {
                  resize: 'none',
                }
              }
            }}
          />
        </div>
      }
      header={`${currentAction === 'approve' ? 'Approval' : 'Rejection'} Confirmation`}
      footer={customConfirmationDialogFooter}
    />
  );
};

export default memo(ReviewConfirmationDialog);
