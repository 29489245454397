import { useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { workflowsActions } from "../../_store";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const selectRunRulesEngineState = createSelector(
  state => state.workflows.runRulesEngineStates,
  (_, workflowId) => workflowId,
  (runRulesEngineStates, workflowId) => runRulesEngineStates[workflowId] || { status: 'idle', error: null }
);

const selectInputText = createSelector(
  (state, workflowId) => state.workflows.ruleEngineInputText[workflowId],
  (inputText) => inputText || ''
);

const RulesEngineExecutorDialog = ({ visible, onHide, workflow }) => {
  const dispatch = useDispatch();

  const inputText = useSelector(state => selectInputText(state, workflow.id));
  const reResults = useSelector((state) => state.workflows.ruleEngineResults[workflow.id]);
  const runRulesEngineState = useSelector(state => selectRunRulesEngineState(state, workflow.id));

  const handleInputTextChange = useCallback((e) => {
    dispatch(workflowsActions.setInputTextForRulesEngine({ workflow, inputText: e.target.value }));
  }, [dispatch, workflow]);

  const runRulesEngine = () => {
    if (!inputText) return;
    dispatch(workflowsActions.runRulesEngine({ workflow, inputText }));
  };

  return (
    <Dialog
      header={`Execute Rules for workflow: ${workflow.workflowName}`}
      headerClassName='bg-light'
      visible={visible}
      style={{ width: '60vw', height: '75vh'}}
      onHide={onHide}
      draggable={false}
      modal
      footer={
        <div>
          <Button 
            label="Run Rules Engine" 
            onClick={runRulesEngine}
            loading={runRulesEngineState.status === 'loading'}
            disabled={runRulesEngineState.status === 'loading' || !inputText.trim()}
            className="p-button-text" 
          />
        </div>
      }
    >
      <div className='d-flex flex-column h-100'>
        <h6 className='mt-3'>Input Data:</h6>
        <InputTextarea
          value={inputText}
          onChange={handleInputTextChange}
          autoResize
          className='flex-grow-1 h-50 small'
          style={{ resize: 'none' }}
        />
        <h6 className='mt-3'>Results:</h6>
        <DataTable rowHover value={reResults} scrollable stripedRows className='flex-grow-1 h-50 x-small'>
          <Column field="ruleName" header="Rule Name" style={{ minWidth: '8em' }}></Column>
          <Column field="isSuccess" header="Is Success" body={rowData => rowData.isSuccess ? 'Yes' : 'No'} style={{ minWidth: '8em' }}></Column>
          <Column field="exceptionMessage" header="Exception Message"></Column>
        </DataTable>
        {runRulesEngineState.error && <small className='text-danger'>{runRulesEngineState.error}</small>}
      </div>
    </Dialog>
  );
};

export default RulesEngineExecutorDialog;
