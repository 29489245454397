import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { dataSourcesActions } from '../../_store';
import { replaceEmptyWithNull } from '../../_helpers';

const selectDataSourceExecuteQueryState = createSelector(
  state => state.dataSources.executeQueryStates,
  (_, dataSourceId) => dataSourceId,
  (executeQueryStates, dataSourceId) => executeQueryStates[dataSourceId] || { status: 'idle', error: null }
);

const selectDataSourceChangeSqlStatementState = createSelector(
  state => state.dataSources.changeDataSourceSqlStatementStates,
  (_, dataSourceId) => dataSourceId,
  (changeDataSourceSqlStatementStates, dataSourceId) => changeDataSourceSqlStatementStates[dataSourceId] || { status: 'idle', error: null }
);

const preprocessDataTable = (data) => {
  return data.map(item => {
    let newItem = { ...item };
    for (let key in newItem) {
      if (typeof newItem[key] === 'object' && newItem[key] !== null) {
        newItem[key] = JSON.stringify(newItem[key]);
      }
    }
    return newItem;
  });
};

const DatabaseSourceConfiguration = ({ dataSourceId, sqlStatement, setSqlStatement, inputParameters, setInputParameters }) => {
  const dispatch = useDispatch();
  const [queryResults, setQueryResults] = useState([]);
  const [first, setFirst] = useState(0);
  const executeQueryState = useSelector(state => selectDataSourceExecuteQueryState(state, dataSourceId));
  const changeSqlStatementState = useSelector(state => selectDataSourceChangeSqlStatementState(state, dataSourceId));

  useEffect(() => {
    if (executeQueryState.status === 'succeeded') {
      setQueryResults(preprocessDataTable(executeQueryState.results));
    }
  }, [executeQueryState]);

  const handleSqlStatementChange = (e) => {
    setSqlStatement(e.target.value);

    dispatch(dataSourcesActions.resetExecuteQueryState({ dataSourceId }));
    dispatch(dataSourcesActions.resetChangeDataSourceSqlStatementState({ dataSourceId }));
  };

  const handleInputParametersChange = (e) => {
    setInputParameters(e.target.value);

    dispatch(dataSourcesActions.resetExecuteQueryState({ dataSourceId }));
    dispatch(dataSourcesActions.resetChangeDataSourceSqlStatementState({ dataSourceId }));
  };

  const onPage = (event) => {
    setFirst(event.first);
  };

  const executeQuery = () => {
    if (sqlStatement.trim() === '') {
      return;
    }
    dispatch(dataSourcesActions.executeQuery(replaceEmptyWithNull({
      dataSourceId,
      sqlStatement,
      inputParameters,
    })));
  };

  const saveConfiguration = () => {
    dispatch(dataSourcesActions.changeDataSourceSqlStatement(replaceEmptyWithNull({
      dataSourceId,
      sqlStatement,
    })));
  };

  const ErrorDisplay = ({ errors }) => {
    // Filter out null or undefined errors and map over them
    const validErrors = errors.filter(error => error);
    if (validErrors.length === 0) return null;
    return (
      <div className="text-danger text-center">
        {validErrors.map((error, index) => (
          <small key={index} className="d-block mt-2">Error: {error}</small> // Key is index because error messages might not be unique
        ))}
      </div>
    );
  };

  return (
    <div className="d-flex flex-column gap-2">
      <div className="inline-flex flex-column">
        <label htmlFor={`sqlStatement-${dataSourceId}`} className="x-small fw-bold">SQL statement</label>
        <InputTextarea
          id={`sqlStatement-${dataSourceId}`}
          value={sqlStatement || ''}
          onChange={handleSqlStatementChange}
          className="w-100 x-small"
          rows={4}
          style={{ resize: 'none' }}
        />
      </div>
      <div className="inline-flex flex-column">
        <label htmlFor={`inputParameters-${dataSourceId}`} className="x-small fw-bold">
          Input Parameters (for testing purposes only, this value will not be saved to the database)
        </label>
        <InputTextarea
          id={`inputParameters-${dataSourceId}`}
          value={inputParameters || ''}
          onChange={handleInputParametersChange}
          className="w-100 x-small"
          rows={5}
          style={{ resize: 'none' }}
        />
      </div>
      <div className="d-flex justify-content-end gap-3 x-small">
        <Button label="Execute Query"
          onClick={executeQuery}
          loading={executeQueryState.status === 'loading'}
          disabled={executeQueryState.status === 'loading'}
          outlined
        />
        <Button label="Save Configuration"
          onClick={saveConfiguration}
          loading={changeSqlStatementState.status === 'loading'}
          disabled={changeSqlStatementState.status === 'loading'}
          outlined
        />
      </div>
      {changeSqlStatementState.status === 'succeeded' && <small className="text-success text-center">Sql statement saved successfully</small>}
      <ErrorDisplay errors={[executeQueryState.error, changeSqlStatementState.error]} />
      <div className="inline-flex flex-column">
        <label className='x-small fw-bold'>Query Results</label>
        {queryResults.length > 0 ? (
          <DataTable
            value={queryResults}
            paginator={queryResults.length > 5}
            rows={5}
            first={first}
            onPage={onPage}
            className="w-100 x-small"
            showGridlines
          >
            {Object.keys(queryResults[0]).map((col, i) => (
              <Column
                key={i}
                field={col}
                header={col}
                headerStyle={{ padding: '.6rem' }}
                headerClassName='justify-content-center'
                alignHeader={'center'}
                bodyStyle={{ padding: '.6rem' }}
                align={'center'}
              />
            ))}
          </DataTable>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <small className="text-muted">No query results found</small>
          </div>
        )}
      </div>
    </div>
  );
};

export default DatabaseSourceConfiguration;
