import { useNavigate, useLocation } from 'react-router-dom';

import { history } from '../_helpers';

export function GlobalContext({ children }) {
  // init custom history object to allow navigation from
  // anywhere in the react app (inside or outside components)
  history.navigate = useNavigate();
  history.location = useLocation();

  return children;
}
