export const roundDecimal = (value, decimals = 2) => {
  return value ? Number(value).toFixed(decimals) : value;
};

// A template function for use in PrimeReact DataTable columns
export const decimalBodyTemplate = (rowData, field, decimals = 2) => {
  return roundDecimal(rowData[field], decimals);
};

export const isInteger = (value) => {
  return typeof value === 'string' && /^-?\d+$/.test(value);
};
