import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../_store';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

export const SessionExpiryDialog = ({ visible, onLogout }) => {
  const dispatch = useDispatch();
  const { authUser } = useSelector((state) => state.auth);
  const { status: refreshTokenStatus, error: refreshTokenError } = useSelector((state) => state.auth.refreshToken);

  const handleRefreshToken = () => {
    const { refreshToken: token } = authUser;
    dispatch(authActions.refreshToken({ token }));
  };

  const renderFooter = () => (
    <div>
      <Button
        label="Refresh Session"
        icon={refreshTokenStatus === 'loading' ? 'pi pi-spin pi-spinner' : 'pi pi-refresh'}
        onClick={handleRefreshToken}
        disabled={refreshTokenStatus === 'loading'}
      />
      <Button label="Logout" icon="pi pi-times" className="p-button-secondary ms-3" onClick={onLogout} />
    </div>
  );

  return (
    <Dialog
      header="Session Expiry Warning"
      visible={visible}
      className="responsive-dialog"
      footer={renderFooter}
      onHide={onLogout}
      blockScroll
      style={{ zIndex: 1100 }}  // Ensure it's higher than other dialogs
    >
      <p>Your session is about to expire. Do you want to refresh it?</p>
      {refreshTokenError && <p className="text-danger">Error refreshing token: {refreshTokenError.message}</p>}
    </Dialog>
  );
};
