import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper, isTokenExpired } from '../_helpers';
import { getUserFullNameFromCognitoToken } from '../_helpers';

const name = 'auth';
const initialState = CreateInitialState();
const reducers = CreateReducers();
const extraActions = CreateExtraActions();
const slice = createSlice({
  name: name,
  initialState: initialState,
  reducers: reducers,
  extraReducers: (builder) => {
    /* LOGIN */
    builder
      .addCase(extraActions.login.pending, (state) => {
        state.error = null;
      })
      .addCase(extraActions.login.fulfilled, (state, action) => {
        const auth = action.payload;

        // Redirect the user to the password change component
        if (auth.challengeName === 'NEW_PASSWORD_REQUIRED') {
          state.newPasswordData = {
            challengeName: auth.challengeName,
            username: auth.additionalData.username,
            session: auth.additionalData.session,
          };
          state.navigateToNewPassword = true;

          return;
        }

        state.userTokenAfterAuth = JSON.stringify(auth.message);
        state.authUser = auth.message;
      })
      .addCase(extraActions.login.rejected, (state, action) => {
        state.error = action.error;
      });

    /* NEW PASSWORD CHALLENGE */
    builder
      .addCase(extraActions.completeNewPasswordRequired.pending, (state) => {
        state.error = null;
      })
      .addCase(
        extraActions.completeNewPasswordRequired.fulfilled, (state, action) => {
          const auth = action.payload;
          state.userNewPassTokenAfterAuth = JSON.stringify(auth.message);
          state.authUser = auth.message;
        },
      )
      .addCase(
        extraActions.completeNewPasswordRequired.rejected, (state, action) => {
          state.error = action.error;
        },
      );

    /* CHECK PASSWORD POLICY */
    builder
      .addCase(extraActions.checkPasswordPolicy.pending, (state) => {
        state.error = null;
      })
      .addCase(
        extraActions.checkPasswordPolicy.fulfilled, (state, action) => {
          const auth = action.payload;
          state.passwordPolicy = auth.message;
        },
      )
      .addCase(
        extraActions.checkPasswordPolicy.rejected, (state, action) => {
          state.error = action.error;
        },
      );

    /* REFRESH TOKEN */
    builder
      .addCase(extraActions.refreshToken.pending, (state) => {
        state.refreshToken = { status: 'loading', error: null };
      })
      .addCase(
        extraActions.refreshToken.fulfilled, (state, action) => {
          // refresh successful, store user details and jwt token in local storage
          // to keep user logged in between page refreshes
          // Refresh token only if the user is logged in
          if (state.logout.status === 'idle' && !isTokenExpired(state.authUser)) {
            state.authUser = action.payload.message;
          }
          state.refreshToken = { status: 'success', error: null };
        },
      )
      .addCase(
        extraActions.refreshToken.rejected, (state, action) => {
          state.refreshToken = { status: 'error', error: action.error };
        },
      );

    /* LOGOUT */
    builder
      .addCase(extraActions.logout.pending, (state) => {
        state.logout = { status: 'loading', error: null };
      })
      .addCase(
        extraActions.logout.fulfilled, (state) => {
          // logout successful, remove user details and jwt token from local storage
          state.authUser = null;
          state.logout = { status: 'success', error: null };
          state.navigateToLogin = true;
        },
      )
      .addCase(
        extraActions.logout.rejected, (state, action) => {
          state.logout = { status: 'error', error: action.error };
        },
      );
  },
});

// exports
export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation
function CreateInitialState() {
  const authUser = localStorage.getItem('authUser');
  let parsedUser = null;
  if (authUser && typeof authUser !== 'undefined') {
    parsedUser = JSON.parse(authUser);
  }

  return {
    // initialize state from local storage to enable user to stay logged in
    authUser: parsedUser,
    passwordPolicy: {},
    userInfo: {},
    error: null,
    loading: false,
    refreshToken: {
      status: 'idle',
      error: null,
    },
    logout: {
      status: 'idle',
      error: null,
    },
  };
}

function CreateReducers() {
  return {
    setErrorMessage,
    resetNavigateToNewPassword,
    resetUserTokenAfterAuth,
    resetNewPassTokenAfterAuth,
    resetNavigateToLogin,
    resetRefreshTokenStatus,
    resetLogoutStatus,
    getUserInfo,
  };

  function setErrorMessage(state, action) {
    state.error = action.payload;
  }
  function resetNavigateToNewPassword(state) {
    state.navigateToNewPassword = false;
  }

  function resetUserTokenAfterAuth(state) {
    state.userTokenAfterAuth = null;
  }

  function resetNewPassTokenAfterAuth(state) {
    state.userNewPassTokenAfterAuth = null;
  }

  function resetNavigateToLogin(state) {
    state.navigateToLogin = false;
  }

  function resetRefreshTokenStatus(state) {
    state.refreshToken.status = 'idle';
  }

  function resetLogoutStatus(state) {
    state.logout.status = 'idle';
  }

  function getUserInfo(state) {
    state.userInfo = {};
    if (state.authUser) {
      state.userInfo.userName = getUserFullNameFromCognitoToken(state.authUser);
    }
  }
}

function CreateExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL ?? ''}/api`;

  return {
    login: login(),
    completeNewPasswordRequired: completeNewPasswordRequired(),
    checkPasswordPolicy: checkPasswordPolicy(),
    refreshToken: refreshToken(),
    logout: logout(),
  };

  function login() {
    return createAsyncThunk(
      `${name}/login`,
      async ({ email, password }) =>
        await fetchWrapper.post(`${baseUrl}/auth/login`, { email, password }),
    );
  }

  function completeNewPasswordRequired() {
    return createAsyncThunk(
      `${name}/complete-new-password-required`,
      async ({ username, session, newPassword }) =>
        await fetchWrapper.post(
          `${baseUrl}/auth/complete-new-password-required`,
          { username, session, newPassword },
        ),
    );
  }

  function checkPasswordPolicy() {
    return createAsyncThunk(
      `${name}/check-password-policy`,
      async ({ password }) =>
        await fetchWrapper.post(
          `${baseUrl}/auth/check-password-policy`,
          { password },
        ),
    );
  }

  function refreshToken() {
    return createAsyncThunk(
      `${name}/refresh-token`,
      async ({ token }) =>
        await fetchWrapper.post(`${baseUrl}/auth/refresh-token`, { token }),
    );
  }

  function logout() {
    return createAsyncThunk(
      `${name}/logout`,
      async () =>
        await fetchWrapper.post(`${baseUrl}/auth/logout`),
    );
  }
}
