const monthNames = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const defaultMaxFractionDigits = 3;

const CustomLink = ({ href, children, className = '', ...rest }) => {
  return (
    <a
      href={href}
      className={`p-component p-link ${className}`}
      {...rest}
    >
      {children}
    </a>
  );
};

// Function to format fields based on their type
const handleCustomField = (value, reportingProperties) => {
  const { fieldCustomType, fieldCustomTypeParsingValue } = reportingProperties;
  switch (fieldCustomType) {
    case 'link': {
      const url = fieldCustomTypeParsingValue.replace('<<col_value>>', value);
      return <CustomLink href={url} style={{ fontSize: '.88rem' }}>See Form</CustomLink>;
    }
    case 'month_name': {
      return monthNames[value - 1] || 'Invalid Month';
    }
    default: {
      console.warn(`Unsupported mask type: ${fieldCustomType}`);
      return value;
    }
  }
};

// Helper function to round floating-point numbers
export const roundNumber = (value, decimals = 2) => {
  if (!value || isNaN(value)) return value;
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
};

export const formatField = (value, fieldType, reportingProperties, keepOriginalType = false) => {
  switch (fieldType) {
    case 'dateonly': {
      return value
        ? new Date(value).toLocaleDateString('en-US', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
        : null;
    }

    case 'datetime': {
      return value ?
        new Date(value).toLocaleString('en-US', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        })
        : null;
    }

    case 'byte':
    case 'int16':
    case 'int32':
    case 'int64':
    case 'float':
    case 'double': {
      const formattedValue = value ? value.toLocaleString('en-US', { maximumFractionDigits: reportingProperties.decimals ?? defaultMaxFractionDigits }) : null;

      if (keepOriginalType) {
        return Number(value);
      }

      return formattedValue;
    }

    case 'currency': {
      return value
        ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
        : null;
    }

    case 'percent': {
      if (keepOriginalType) {
        return roundNumber(Number(value) * 100);
      }
      // const formattedValue = new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 2 }).format(value);

      return value
        ? new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 2 }).format(value)
        : null;
    }

    case 'custom': {
      return handleCustomField(value, reportingProperties);
    }

    default: {
      return value;
    }
  }
};