import { store } from "../_store";

export const fetchWrapper = {
  get: request("GET"),
  post: request("POST"),
  put: request("PUT"),
  delete: request("DELETE"),
  patch: request("PATCH"),
};

function request(method) {
  return async (url, body) => {
    const requestOptions = {
      method,
      credentials: "include",
      headers: authHeader(url),
    };
    if (body) {
      requestOptions.headers["Content-Type"] = "application/json";
      requestOptions.body = JSON.stringify(body);
    }
    const response = await fetch(url, requestOptions);
    return handleResponse(response);
  };
}

// helper functions

function authHeader(url) {
  // return auth header with jwt if user is logged in and request is to the api url
  const token = authToken();
  const isLoggedIn = !!token;
  const isApiUrl = url.startsWith(`${process.env.REACT_APP_API_URL ?? ""}/api`);
  if (isLoggedIn && isApiUrl) {
    return { Authorization: `Bearer ${token}` };
  } else {
    return {};
  }
}

function authToken() {
  return store.getState().auth.authUser?.idToken;
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text ? JSON.parse(text) : {};

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        const logout = () => { }; // no-op
        logout();
      }

      // Handle ASP.NET Core's validation error structure
      let error;
      if (data.errors) {
        // Aggregate all validation error messages into one string
        error = Object.entries(data.errors)
          .map(([key, value]) => `${key}: ${value.join(', ')}`)
          .join(', ');
      } else {
        // Fallback for other types of errors or different error message structures
        error = data.message || data.errorMessage || data.error || response.statusText;
      }
      return Promise.reject(new Error(error));
    } else if (data.errorMessage) {
      return Promise.reject(new Error(data.errorMessage));
    }

    return data;
  });
}
