import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers";

const name = "dataSourceMappings";

const initialState = {
  data: {}, // Data source mappings per data source id, internally stored as an array of mappings
  mappingTypes: [],
  fetchMappingsFromConnectionStatusStates: {},
  saveMappingsStatusStates: {},
};

const baseUrl = `${process.env.REACT_APP_API_URL ?? ""}/api`;
const fetchMappingsFromConnection = createAsyncThunk(
  `${name}/fetchMappingsFromConnection`,
  async ({ dataSourceId }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.post(`${baseUrl}/data-sources/${dataSourceId}/mappings-from-connection`);
      return { dataSourceId, response };
    } catch (error) {
      return rejectWithValue({ dataSourceId, error: error.message });
    }
  },
);
const saveMappings = createAsyncThunk(
  `${name}/saveMappings`,
  async ({ dataSourceId, mappings }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.patch(`${baseUrl}/data-sources/${dataSourceId}/mappings`, mappings);
      return { dataSourceId, response };
    } catch (error) {
      return rejectWithValue({ dataSourceId, error: error.message });
    }
  },
);

const dataSourceMappingsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setDataSourceMappings(state, action) {
      // Normalize the data by storing the mappings as an array of mappings per data source id
      const groupedDataSourceMappings = action.payload.reduce((obj, mapping) => {
        if (!obj[mapping.dataSourceId]) {
          obj[mapping.dataSourceId] = [];
        }
        obj[mapping.dataSourceId].push(mapping);
        return obj;
      }, {});

      state.data = groupedDataSourceMappings;
    },
    setDataSourceMappingTypes(state, action) {
      state.mappingTypes = action.payload;
    },
    deleteAllMappings(state, action) {
      delete state.data[action.payload.dataSourceId];
    },
    deleteMapping(state, action) {
      const { dataSourceId, columnName } = action.payload;
      const mappings = state.data[dataSourceId];
      const index = mappings.findIndex(mapping => mapping.columnName === columnName);

      if (index !== -1) {
        mappings.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch mappings from connection
      .addCase(fetchMappingsFromConnection.pending, (state, action) => {
        state.fetchMappingsFromConnectionStatusStates[action.meta.arg.dataSourceId] = { status: 'loading', error: null };
      })
      .addCase(fetchMappingsFromConnection.fulfilled, (state, action) => {
        const { dataSourceId, response } = action.payload;
        const updatedMappings = response.message;

        // Only add new mappings; keep existing ones intact
        const existingMappings = state.data[dataSourceId] || [];
        const newMappings = updatedMappings.filter(
          (updatedMapping) => !existingMappings.some((existingMapping) => existingMapping.columnName === updatedMapping.columnName)
        );

        state.data[dataSourceId] = [...existingMappings, ...newMappings];
        state.fetchMappingsFromConnectionStatusStates[dataSourceId] = { status: 'succeeded', error: null };
      })
      .addCase(fetchMappingsFromConnection.rejected, (state, action) => {
        const { dataSourceId, error } = action.payload;
        state.fetchMappingsFromConnectionStatusStates[dataSourceId] = { status: 'failed', error: error };
      })

      // Update mappings
      .addCase(saveMappings.pending, (state, action) => {
        state.saveMappingsStatusStates[action.meta.arg.dataSourceId] = { status: 'loading', error: null };
      })
      .addCase(saveMappings.fulfilled, (state, action) => {
        const { dataSourceId, response } = action.payload;
        // Update data with the new mappings
        state.data[dataSourceId] = response.message;
        state.saveMappingsStatusStates[dataSourceId] = { status: 'succeeded', error: null };
      })
      .addCase(saveMappings.rejected, (state, action) => {
        const { dataSourceId, error } = action.payload;
        state.saveMappingsStatusStates[dataSourceId] = { status: 'failed', error: error };
      });
  },
});

export const { setDataSourceMappings, setDataSourceMappingTypes, deleteAllMappings, deleteMapping } = dataSourceMappingsSlice.actions;
export const dataSourceMappingsReducer = dataSourceMappingsSlice.reducer;

// Bundle and export the actions
export const dataSourceMappingsActions = {
  setDataSourceMappings,
  setDataSourceMappingTypes,
  fetchMappingsFromConnection,
  saveMappings,
  deleteAllMappings,
  deleteMapping,
};
