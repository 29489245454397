import { useState } from 'react';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { renderInputField } from '../helpers/SearchBarHelpers';

const SearchBar = ({ componentId, data, mappings, filters, onFilterChange }) => {
  const [localFilters, setLocalFilters] = useState(filters);

  const handleInputChange = (name, value) => {
    const updatedFilters = {
      ...localFilters,
      [name]: value,
    };

    setLocalFilters(updatedFilters);
  };

  const handleApplyFilters = () => {
    onFilterChange(componentId, localFilters);
  };

  const handleClearFilters = () => {
    const clearedFilters = {};
    setLocalFilters(clearedFilters);
    onFilterChange(componentId, clearedFilters);
  };

  const visibleMappings = mappings ? Object.values(mappings)
    .filter((mapping) => mapping.filterType !== null)
    .sort((a, b) => a.seq - b.seq) : [];

  if (visibleMappings.length === 0) {
    return <div className={"text-center"}>No mappings available for filters</div>;
  }

  const panelFooter = (
    <div className="d-flex justify-content-end small">
      <Button label="Apply Filters" icon="pi pi-check" onClick={handleApplyFilters} className="p-button-primary" />
      <Button label="Clear Filters" icon="pi pi-times" onClick={handleClearFilters} className="p-button-secondary ms-3" />
    </div>
  );

  return (
    <Panel toggleable header="Search Filters" footer={panelFooter}>
      <div className="row">
        {visibleMappings.map(mapping => (
          <div key={mapping.id} className="col-12 col-md-6 col-lg-3 mb-3">
            <label className="form-label small" htmlFor={mapping.columnName}>{mapping.displayName}</label>
            {renderInputField(mapping, data, localFilters, handleInputChange, componentId)}
          </div>
        ))}
      </div>
    </Panel>
  );
};

export default SearchBar;