import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';
import { rulesActions } from "../../_store";
import { Panel } from 'primereact/panel';
import { Button } from "primereact/button";
import { InputText  } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { FloatLabel } from 'primereact/floatlabel';
import { Checkbox } from 'primereact/checkbox';

// Memoized selectors
const selectRuleChangeNameState = createSelector(
  state => state.rules.changeRuleNameStates,
  (_, ruleId) => ruleId,
  (changeRuleNameStates, ruleId) => changeRuleNameStates[ruleId] || { status: 'idle', error: null }
);

const selectChangeRuleEnabledState = createSelector(
  state => state.rules.changeRuleEnabledStates,
  (_, ruleId) => ruleId,
  (changeRuleEnabledStates, ruleId) => changeRuleEnabledStates[ruleId] || { status: 'idle', error: null }
);

const selectRuleDeleteState = createSelector(
  state => state.rules.deleteRuleStates,
  (_, ruleId) => ruleId,
  (deleteRuleStates, ruleId) => deleteRuleStates[ruleId] || { status: 'idle', error: null }
);

const selectCreateSubRuleState = createSelector(
  state => state.rules.createRuleStates,
  (_, ruleId) => ruleId,
  (createRuleStates, ruleId) => createRuleStates[`rule-${ruleId}`] || { status: 'idle', error: null }
);

const selectChangeRuleOperatorState = createSelector(
  state => state.rules.changeRuleOperatorStates,
  (_, ruleId) => ruleId,
  (changeRuleOperatorStates, ruleId) => changeRuleOperatorStates[ruleId] || { status: 'idle', error: null }
);

const selectChangeRuleExpressionState = createSelector(
  state => state.rules.changeRuleExpressionStates,
  (_, ruleId) => ruleId,
  (changeRuleExpressionStates, ruleId) => changeRuleExpressionStates[ruleId] || { status: 'idle', error: null }
);

const selectChangeRuleActionsState = createSelector(
  state => state.rules.changeRuleActionsStates,
  (_, ruleId) => ruleId,
  (changeRuleActionsStates, ruleId) => changeRuleActionsStates[ruleId] || { status: 'idle', error: null }
);

const selectChangeSuccessEventState = createSelector(
  state => state.rules.changeSuccessEventStates,
  (_, ruleId) => ruleId,
  (changeSuccessEventStates, ruleId) => changeSuccessEventStates[ruleId] || { status: 'idle', error: null }
);

const selectChangeErrorMessageState = createSelector(
  state => state.rules.changeErrorMessageStates,
  (_, ruleId) => ruleId,
  (changeErrorMessageStates, ruleId) => changeErrorMessageStates[ruleId] || { status: 'idle', error: null }
);

const Rule = ({ ruleId, depth = 0 }) => {
  const dispatch = useDispatch();
  const rule = useSelector(state => state.rules.ruleMap[`rule-${ruleId}`]);
  
  const ruleChangeNameState = useSelector(state => selectRuleChangeNameState(state, ruleId));
  const [ruleNameInputValue, setRuleNameInputValue] = useState(rule ? rule.ruleName : "");
  const [nameChangeValidationError, setNameChangeValidationError] = useState(null);

  const changeRuleEnabledState = useSelector(state => selectChangeRuleEnabledState(state, ruleId));

  const ruleDeleteState = useSelector(state => selectRuleDeleteState(state, ruleId));

  const changeRuleOperatorState = useSelector(state => selectChangeRuleOperatorState(state, ruleId));

  const [ruleExpressionInputValue, setRuleExpressionInputValue] = useState(rule ? rule.expression : "");
  const changeRuleExpressionState = useSelector(state => selectChangeRuleExpressionState(state, ruleId));

  const [ruleActionsInputValue, setRuleActionsInputValue] = useState(rule ? rule.actionsJson : "");
  const changeRuleActionsState = useSelector(state => selectChangeRuleActionsState(state, ruleId));

  const [successEventInputValue, setSuccessEventInputValue] = useState(rule ? rule.successEvent : "");
  const changeSuccessEventState = useSelector(state => selectChangeSuccessEventState(state, ruleId));

  const [errorMessageInputValue, setErrorMessageInputValue] = useState(rule ? rule.errorMessage : "");
  const changeErrorMessageState = useSelector(state => selectChangeErrorMessageState(state, ruleId));

  // Sub rules related states
  const createSubRuleState = useSelector(state => selectCreateSubRuleState(state, ruleId));

  const handleRuleNameChange = useCallback((e) => {
    const newName = e.target.value;
    setNameChangeValidationError(null);
    setRuleNameInputValue(newName);
  }, []);

  const handleRuleNameBlur = useCallback((e) => {
    if (!rule) return;    
    const inputValue = e.target.value.trim();
    setRuleNameInputValue(inputValue);
    if (inputValue.length < 1 || inputValue.length > 100) {
      setNameChangeValidationError('Name must be between 1 and 100 characters');
      return;
    }
    if (inputValue === rule.ruleName) return;

    dispatch(rulesActions.changeRuleName({ ruleId, newName: inputValue }));
  }, [dispatch, rule, ruleId]);

  const handleRuleEnabledChange = useCallback((e) => {
    dispatch(rulesActions.changeRuleEnabled({ ruleId, newEnabled: e.checked }));
  }, [dispatch, ruleId]);
  
  const handleRuleDelete = useCallback(() => {
    dispatch(rulesActions.deleteRule({ ruleId }));
  }, [dispatch, ruleId]);
  
  const handleAddSubRule = useCallback(() => {
    dispatch(rulesActions.createDefaultRule({ parentType: 'rule', parentId: ruleId, parentKey: `rule-${ruleId}` }));
  }, [dispatch, ruleId]);

  const handleRuleOperatorChange = useCallback((e) => {
    dispatch(rulesActions.changeRuleOperator({ ruleId, newOperator: e.value }));
  }, [dispatch, ruleId]);

  const handleRuleExpressionChange = useCallback((e) => {
    const newExpression = e.target.value;
    setRuleExpressionInputValue(newExpression);
  }, []);
  const handleRuleExpressionBlur = useCallback((e) => {
    const inputValue = e.target.value === '' ? null : e.target.value;
    if (!rule || inputValue === rule.expression) return;
    dispatch(rulesActions.changeRuleExpression({ ruleId, newExpression: inputValue }));
  }, [dispatch, rule, ruleId]);

  const handleRuleActionsChange = useCallback((e) => {
    const newRuleActions = e.target.value;
    setRuleActionsInputValue(newRuleActions);
  }, []);
  const handleRuleActionsBlur = useCallback((e) => {
    const inputValue = e.target.value === '' ? null : e.target.value;
    if (!rule || inputValue === rule.actionsJson) return;
    dispatch(rulesActions.changeRuleActions({ ruleId, newRuleActions: inputValue }));
  }, [dispatch, rule, ruleId]);

  const handleSuccessEventChange = useCallback((e) => {
    const newSuccessEvent = e.target.value;
    setSuccessEventInputValue(newSuccessEvent);
  }, []);
  const handleSuccessEventBlur = useCallback((e) => {
    const inputValue = e.target.value === '' ? null : e.target.value;
    if (!rule || inputValue === rule.successEvent) return;
    dispatch(rulesActions.changeSuccessEvent({ ruleId, newSuccessEvent: inputValue }));
  }, [dispatch, rule, ruleId]);

  const handleErrorMessageChange = useCallback((e) => {
    const newErrorMessage = e.target.value;
    setErrorMessageInputValue(newErrorMessage);
  }, []);
  const handleErrorMessageBlur = useCallback((e) => {
    const inputValue = e.target.value === '' ? null : e.target.value;
    if (!rule || inputValue === rule.errorMessage) return;
    dispatch(rulesActions.changeErrorMessage({ ruleId, newErrorMessage: inputValue }));
  }, [dispatch, rule, ruleId]);

  // Return null if rule is undefined to prevent rendering or further computation
  if (!rule) return null;

  const headerClass = `rule-header-${depth}`;
  
  const panelHeaderTemplate = (options) => {
    return (
      <div className={`${options.className} p-2 small`} style={{ cursor: 'grab' }}>
        {options.titleElement}
        {options.iconsElement}
      </div>
    );
  };

  const headerTitle = depth === 0 ? `Rule: ${rule.ruleName}` : `Sub Rule: ${rule.ruleName}`;
  const ruleOperators = [
    { label: "  ", value: null },
    { label: "And", value: "And" },
    { label: "AndAlso", value: "AndAlso" },
    { label: "Or", value: "Or" },
    { label: "OrElse", value: "OrElse" }
  ];
  
  return (
    <Panel       
      id={`rule-${ruleId}`} 
      header={headerTitle}
      headerTemplate={panelHeaderTemplate}
      toggleable
      className={`mb-1 ${headerClass} small-content-padding`}
    >
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column flex-grow-1">
          <label htmlFor={`rule-name-${ruleId}`} className="ms-2 x-small">Rule Name</label>
          <InputText
            id={`rule-name-${ruleId}`}
            disabled={ ruleChangeNameState.status === 'loading' }
            placeholder={ ruleChangeNameState.status === 'loading' ? 'Saving...' : '' }
            value={ ruleNameInputValue }
            onChange={handleRuleNameChange}
            onBlur={handleRuleNameBlur}
            invalid={ ruleChangeNameState.status === 'failed' }
            className='w-100 p-2 x-small'
          />
        </div>

        <div className="d-flex flex-column justify-content-start align-items-center ms-3">
          <label htmlFor={`rule-enabled-${ruleId}`} className="x-small">Enabled</label>
          <Checkbox
            inputId={`rule-enabled-${ruleId}`}
            checked={rule.enabled}
            onChange={handleRuleEnabledChange}
            disabled={ changeRuleEnabledState.status === 'loading' }
            className="checkbox-box-parent-span"
            style={{ width: '2em', height: '2em', marginTop: '0.2em' }}
          />
        </div>

        <div className="d-flex flex-column ms-3">
          <label className="x-small" style={{ minHeight: '1.1em' }}> </label>
          <Button             
            onClick={handleRuleDelete}
            tooltip="Delete Rule"
            text
            raised
            severity="danger"
            className="py-2"
            icon="pi pi-trash"
            disabled={ruleDeleteState.status === 'loading'}
            loading={ruleDeleteState.status === 'loading'}
          />
        </div>
      </div>
      {(ruleChangeNameState.error || changeRuleEnabledState.error || ruleDeleteState.error || nameChangeValidationError) && (
        <div className="d-flex text-danger justify-content-between">
          {nameChangeValidationError && <small className="text-start x-small">{nameChangeValidationError}</small>}
          {ruleChangeNameState.error && <small className="text-start x-small">{ruleChangeNameState.error}</small>}
          {changeRuleEnabledState.error && <small className="text-end x-small">{changeRuleEnabledState.error}</small>}
          {ruleDeleteState.error && <small className="text-end x-small">{ruleDeleteState.error}</small>}
        </div>
      )}
      <div className="row mt-4">
        <div className="col-md-6">
          <FloatLabel>
            <InputTextarea 
              id={`rule-expression-${ruleId}`} 
              rows={5}
              value={ ruleExpressionInputValue ?? '' }
              className="form-control x-small" 
              disabled={ changeRuleExpressionState.status === 'loading' }
              onChange={handleRuleExpressionChange}
              onBlur={handleRuleExpressionBlur}
              style={{ resize: 'none' }} 
            />
            <label htmlFor={`rule-expression-${ruleId}`} className="x-small">Expression</label>
          </FloatLabel>
          {changeRuleExpressionState.error && <small className="d-block text-danger x-small">{changeRuleExpressionState.error}</small>}
        </div>
        <div className="col-md-6">
          <FloatLabel>
            <InputTextarea 
              id={`rule-actions-${ruleId}`} 
              rows={5} 
              value={ ruleActionsInputValue ?? '' }
              className="form-control x-small"
              disabled={ changeRuleActionsState.status === 'loading' }
              onChange={handleRuleActionsChange}
              onBlur={handleRuleActionsBlur}
              style={{ resize: 'none' }} 
            />
            <label htmlFor={`rule-actions-${ruleId}`} className="x-small">Actions</label>
          </FloatLabel>
          {changeRuleActionsState.error && <small className="d-block text-danger x-small">{changeRuleActionsState.error}</small>}
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-6">
          <FloatLabel>
            <InputTextarea 
              id={`rule-success-event-${ruleId}`} 
              rows={2} 
              value={ successEventInputValue ?? '' }
              className="form-control x-small" 
              disabled={ changeSuccessEventState.status === 'loading' }
              onChange={handleSuccessEventChange}
              onBlur={handleSuccessEventBlur}
              style={{ resize: 'none' }} 
            />
            <label htmlFor={`rule-success-event-${ruleId}`} className="x-small">Success Event</label>
          </FloatLabel>
          {changeSuccessEventState.error && <small className="d-block text-danger x-small">{changeSuccessEventState.error}</small>}
        </div>
        <div className="col-md-6">
          <FloatLabel>
            <InputTextarea 
              id={`rule-error-message-${ruleId}`} 
              rows={2} 
              value={ errorMessageInputValue ?? '' }
              className="form-control x-small" 
              disabled={ changeErrorMessageState.status === 'loading' }
              onChange={handleErrorMessageChange}
              onBlur={handleErrorMessageBlur}
              style={{ resize: 'none' }} 
            />
            <label htmlFor={`rule-error-message-${ruleId}`} className="x-small">Error Message</label>
          </FloatLabel>
          {changeErrorMessageState.error && <small className="d-block text-danger x-small">{changeErrorMessageState.error}</small>}
        </div>
      </div>
      <div className="mt-2 mb-1">
        <div className="d-flex align-items-center">
          <h5 className="mb-0">Sub Rules</h5>
          <Dropdown
            options={ruleOperators}
            value={rule.operator}
            onChange={(e) => handleRuleOperatorChange(e, ruleId)}
            disabled={ changeRuleOperatorState.status === 'loading' }
            placeholder={ changeRuleOperatorState.status === 'loading' ? 'Saving...' : 'Select an Operator' }
            loading={ changeRuleOperatorState.status === 'loading' }
            className="small small-dropdown-content ms-3"
            panelClassName="small"
            scrollHeight="250px"
            style={{ width: '180px' }}
          />
          <Button 
            onClick={handleAddSubRule}
            label="New Sub Rule" 
            text
            raised
            className="ms-3 py-1 x-small"
            style={{ verticalAlign: 'middle' }}
          />
        </div>
        {createSubRuleState.error && <small className="d-block text-danger x-small">{createSubRuleState.error}</small>}
        {changeRuleOperatorState.error && <small className="d-block text-danger x-small">{changeRuleOperatorState.error}</small>}
      </div>
      {rule.rules.length > 0 ? (
        rule.rules && rule.rules.map((subRule) => (
          <Rule key={subRule.id} ruleId={subRule.id} depth={depth + 1} />
        ))
      ) : (
        <small className="d-flex justify-content-center x-small">
          No sub rules to display
        </small>
      )}
    </Panel>
  );
};

export default React.memo(Rule);
