import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';

// Helper to render input fields
export const renderInputField = (mapping, data, localFilters, handleInputChange, componentId) => {
  const { filterType, fieldType, displayName, columnName } = mapping;
  const columnData = data.map(item => item[columnName]).filter(Boolean);

  switch (filterType) {
    case 'Dropdown': {
      const distinctDropdownValues = [...new Set(columnData)].map(value => ({ label: value, value }));
      return (
        <Dropdown
          id={`${componentId}-${columnName}`}
          value={localFilters[columnName] || distinctDropdownValues[0]?.value}
          options={distinctDropdownValues}
          placeholder={`Select ${displayName}`}
          className="w-100"
          onChange={(e) => handleInputChange(columnName, e.value)}
        />
      );
    }
  }

  // Default rendering behavior (for other field types)
  switch (fieldType) {
    case 'string':
      return (
        <InputText
          placeholder={displayName}
          className='small w-100'
          value={localFilters[columnName] || ''}
          onChange={(e) => handleInputChange(columnName, e.target.value)}
        />
      );
    case 'byte':
    case 'int16':
    case 'int32':
    case 'int64':
    case 'float':
    case 'double':
    case 'percent':
      return (
        <InputNumber
          placeholder={displayName}
          className='small w-100'
          value={localFilters[columnName] || null}
          onChange={(e) => handleInputChange(columnName, e.value)}
        />
      );
    case 'dateonly': {
      const validDates = columnData
        .filter(date => date !== null && date !== undefined)
        .map(date => new Date(date));

      return (
        <Calendar
          placeholder={displayName}
          enabledDates={validDates}
          showButtonBar
          showIcon
          className='w-100'
          inputClassName='small'
          panelClassName='small'
          readOnlyInput
          hideOnRangeSelection
          value={localFilters[columnName]}
          onChange={(e) => handleInputChange(columnName, e.value)}
        />
      );
    }
    default:
      return (
        <InputText
          placeholder={displayName}
          className='small w-100'
          value={localFilters[columnName] || ''}
          onChange={(e) => handleInputChange(columnName, e.target.value)}
        />
      );
  }
};
