import { configureStore } from "@reduxjs/toolkit";

import { authReducer } from "./auth.slice";
import { workflowsReducer } from "./rules-engine-editor/workflows.slice";
import { rulesReducer } from "./rules-engine-editor/rules.slice";
import { dataSourcesReducer } from "./data-source-manager/dataSources.slice";
import { dataConnectionsReducer } from "./data-source-manager/dataConnections.slice";
import { dataSourceMappingsReducer } from "./data-source-manager/dataSourceMappings.slice";
import { reportsReducer } from "./reports/reports.slice";
import { preAuthorizationsReducer } from "./pre-authorizations/preAuthorizations.slice";

export * from "./auth.slice";
export * from "./rules-engine-editor/workflows.slice";
export * from "./rules-engine-editor/rules.slice";
export * from "./data-source-manager/dataSources.slice";
export * from "./data-source-manager/dataConnections.slice";
export * from "./data-source-manager/dataSourceMappings.slice";
export * from "./reports/reports.slice";
export * from "./pre-authorizations/preAuthorizations.slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    workflows: workflowsReducer,
    rules: rulesReducer,
    dataSources: dataSourcesReducer,
    dataConnections: dataConnectionsReducer,
    dataSourceMappings: dataSourceMappingsReducer,
    reports: reportsReducer,
    preAuthorizations: preAuthorizationsReducer,
  },
});
