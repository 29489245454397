import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { preAuthorizationsActions, reportsActions } from '../../_store';
import SearchBar from '../reports/templates/SearchBar';
import GridBase from '../reports/templates/GridBase';
import { ProgressSpinner } from 'primereact/progressspinner';

export const PreAuthorizations = () => {
  const dispatch = useDispatch();
  const reportId = 'PreAuthorizations';
  const filterComponentId = 'PreAuthorizationsSearchBar';

  const { filters } = useSelector((state) => state.reports);
  const { data: { results: preAuthorizationsData, mappings: dataMappings } = {} } = useSelector((state) => state.preAuthorizations);
  const { status: getPreAuthsStatus, error: getPreAuthsError } = useSelector((state) => state.preAuthorizations.getPreAuthorizations);

  useEffect(() => {
    dispatch(preAuthorizationsActions.getPreAuthorizations());
  }, [dispatch]);

  const handleFilterChange = (filterComponentId, updatedFilters) => {
    dispatch(reportsActions.setFilters({
      reportId,
      filterComponentId,
      filters: updatedFilters
    }));
  };

  const getComponentFilters = (componentId) => {
    return filters?.[reportId]?.[componentId] || {};
  };

  const sortedAndFilteredMappings = useMemo(() => {
    if (!dataMappings) return {};
    
    return Object.entries(dataMappings)
      .filter(([, mapping]) => mapping.fieldType)
      .sort(([, a], [, b]) => a.seq - b.seq)
      .reduce((acc, [key, mapping]) => {
        acc[key] = mapping;
        return acc;
      }, {});
  }, [dataMappings]);

  if (getPreAuthsStatus === 'loading') {
    return (
      <div className="d-flex flex-grow-1 flex-column justify-content-center align-items-center mt-2">
        <ProgressSpinner style={{ width: '80px', height: '80px', animationDuration: '4.0s' }} strokeWidth="4" />
      </div>
    );
  }

  if (getPreAuthsStatus === 'error') {
    return <div className="text-danger">Error fetching data: {getPreAuthsError}</div>;
  }

  return (
    <div className="d-flex flex-grow-1 flex-column h-100 gap-2 py-2">
      {preAuthorizationsData && (
        <>
          <SearchBar
            componentId={filterComponentId}
            data={preAuthorizationsData}
            mappings={sortedAndFilteredMappings}
            filters={getComponentFilters(filterComponentId)}
            onFilterChange={handleFilterChange}
          />
          <GridBase
            data={preAuthorizationsData}
            mappings={sortedAndFilteredMappings}
            filtering={getComponentFilters(filterComponentId)}
          />
        </>
      )}
    </div>
  );
};
