import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers";
import { dataConnectionsActions } from "./dataConnections.slice";
import { dataSourceMappingsActions } from "./dataSourceMappings.slice";

const name = "dataSources";

const initialState = {
  data: [], // Array to store data sources
  dataResultSetTypes: [],
  dataConnectionTypes: [],
  dataSourceTestResults: {}, // Object to store the results of each source test
  getDataSources: {
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed',
    error: null,
  },
  createDataSource: {
    status: "idle",
    error: null,
  },
  changeDataSourceNameStates: {},
  deleteDataSourceStates: {},
  changeDataSourceConnectionStates:{},
  deleteDataSourceConnectionStates: {},
  changeDataResultSetTypeStates: {},
  executeQueryStates: {},
  changeDataSourceSqlStatementStates: {},
};

// extra actions
const baseUrl = `${process.env.REACT_APP_API_URL ?? ""}/api`;
const getDataSources = createAsyncThunk(
  `${name}/getSources`,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchWrapper.get(`${baseUrl}/data-sources`);
      dispatch(dataConnectionsActions.setDataConnections(response.message.dataConnections));
      dispatch(dataSourceMappingsActions.setDataSourceMappingTypes(response.message.mappingTypes));
      dispatch(dataSourceMappingsActions.setDataSourceMappings(response.message.mappings));
      return response;
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  },
);
const createDefaultDataSource = createAsyncThunk(
  `${name}/createDefaultDataSource`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.post(`${baseUrl}/data-sources/create-default`);
      return response;
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  },
);
const changeDataSourceName = createAsyncThunk(
  `${name}/changeDataSourceName`,
  async ({ dataSourceId, newName }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.patch(`${baseUrl}/data-sources/${dataSourceId}`, { dataSourceName: newName, propertiesUpdated: ['dataSourceName'] });
      return { dataSourceId, response };
    } catch (error) {
      return rejectWithValue({ dataSourceId, error: error.message });
    }
  },
);
const deleteDataSource = createAsyncThunk(
  `${name}/deleteDataSource`,
  async ({ dataSourceId }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.delete(`${baseUrl}/data-sources/${dataSourceId}`);
      return { dataSourceId, response };
    } catch (error) {
      return rejectWithValue({ dataSourceId, error: error.message });
    }
  },
);
const changeDataResultSetType = createAsyncThunk(
  `${name}/changeDataResultSetType`,
  async ({ dataSourceId, newDataResulSetType }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.patch(`${baseUrl}/data-sources/${dataSourceId}`, { dataResultSetType: newDataResulSetType, propertiesUpdated: ['dataResulSetType'] });
      return { dataSourceId, response };
    } catch (error) {
      return rejectWithValue({ dataSourceId, error: error.message });
    }
  },
);
const changeDataSourceConnection = createAsyncThunk(
  `${name}/changeDataSourceConnection`,
  async ({ dataSourceId, newConnectionId }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.patch(`${baseUrl}/data-sources/${dataSourceId}`, { dataConnectionId: newConnectionId, propertiesUpdated: ['dataConnectionId'] });
      return { dataSourceId, response };
    } catch (error) {
      return rejectWithValue({ dataSourceId, error: error.message });
    }
  },
);
const executeQuery = createAsyncThunk(
  `${name}/executeQuery`,
  async ({ dataSourceId, sqlStatement, inputParameters }, { rejectWithValue }) => {
    try {
      if (typeof inputParameters === 'string') {
        inputParameters = JSON.parse(inputParameters);
      }
      const response = await fetchWrapper.post(`${baseUrl}/data-sources/${dataSourceId}/execute-query`, { sqlStatement, inputParameters });
      return { dataSourceId, response };
    } catch (error) {
      return rejectWithValue({ dataSourceId, error: error.message });
    }
  },
);
const changeDataSourceSqlStatement = createAsyncThunk(
  `${name}/changeDataSourceSqlStatement`,
  async ({ dataSourceId, sqlStatement }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.patch(`${baseUrl}/data-sources/${dataSourceId}`, { sqlStatement, propertiesUpdated: ['sqlStatement'] });
      return { dataSourceId, response };
    } catch (error) {
      return rejectWithValue({ dataSourceId, error: error.message });
    }
  },
);

const dataSourcesSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetExecuteQueryState(state, action) {
      const { dataSourceId } = action.payload;
      state.executeQueryStates[dataSourceId] = { status: 'idle', error: null, };
    },
    resetChangeDataSourceSqlStatementState(state, action) {
      const { dataSourceId } = action.payload;
      state.changeDataSourceSqlStatementStates[dataSourceId] = { status: 'idle', error: null };
    },
  },
  extraReducers: (builder) => {
    builder
      // getDataSources
      .addCase(getDataSources.pending, (state) => {
        state.getDataSources.status = "loading";
        state.getDataSources.error = null;
      })
      .addCase(getDataSources.fulfilled, (state, action) => {
        const { dataSources, dataResultSetTypes, dataConnectionTypes } = action.payload.message;
        state.data = dataSources;
        state.dataResultSetTypes = Object.entries(dataResultSetTypes).map(([key, value]) => ({ 'value': parseInt(key), 'label': value }));
        state.dataConnectionTypes = dataConnectionTypes.map(type => ({ 'value': type, 'label': type }));
        state.getDataSources.status = "succeeded";
      })
      .addCase(getDataSources.rejected, (state, action) => {
        state.getDataSources.status = "failed";
        state.getDataSources.error = action.payload.error;
      })
      
      // createDefaultDataSource
      .addCase(createDefaultDataSource.pending, (state) => {
        state.createDataSource.status = "loading";
        state.createDataSource.error = null;
      })
      .addCase(createDefaultDataSource.fulfilled, (state, action) => {
        state.data.push(action.payload.message.dataSource);
        state.createDataSource.status = "succeeded";
      })
      .addCase(createDefaultDataSource.rejected, (state, action) => {
        state.createDataSource.status = "failed";
        state.createDataSource.error = action.payload.error;
      })
      
      // changeDataSourceName
      .addCase(changeDataSourceName.pending, (state, action) => {
        state.changeDataSourceNameStates[action.meta.arg.dataSourceId] = { status: 'loading', error: null };
      })
      .addCase(changeDataSourceName.fulfilled, (state, action) => {
        const { id: dataSourceId, name: newName } = action.payload.response.message;
        state.data = state.data.map(dataSource =>
          dataSource.id === dataSourceId ? { ...dataSource, name: newName } : dataSource
        );
        state.changeDataSourceNameStates[dataSourceId] = { status: 'succeeded', error: null };
      })
      .addCase(changeDataSourceName.rejected, (state, action) => {
        const { dataSourceId, error } = action.payload;
        state.changeDataSourceNameStates[dataSourceId] = { status: 'failed', error: error };
      })

      // deleteDataSource
      .addCase(deleteDataSource.pending, (state, action) => {
        state.deleteDataSourceStates[action.meta.arg.dataSourceId] = { status: 'loading', error: null };
      })
      .addCase(deleteDataSource.fulfilled, (state, action) => {
        const dataSourceId = action.payload.dataSourceId;
        const index = state.data.findIndex(dataSource => dataSource.id === dataSourceId);
        if (index !== -1) {
          state.data.splice(index, 1);
        }
        state.deleteDataSourceStates[dataSourceId] = { status: 'succeeded', error: null };
      })
      .addCase(deleteDataSource.rejected, (state, action) => {
        const { dataSourceId, error } = action.payload;
        state.deleteDataSourceStates[dataSourceId] = { status: 'failed', error: error };
      })
      
      // changeDataResultSetType
      .addCase(changeDataResultSetType.pending, (state, action) => {
        state.changeDataResultSetTypeStates[action.meta.arg.dataSourceId] = { status: 'loading', error: null };
      })
      .addCase(changeDataResultSetType.fulfilled, (state, action) => {
        const { id: dataSourceId, dataResultSetType: newDataResultSetType } = action.payload.response.message;
        state.data = state.data.map(dataSource =>
          dataSource.id === dataSourceId ? { ...dataSource, dataResultSetType: newDataResultSetType } : dataSource
        );
        state.changeDataResultSetTypeStates[dataSourceId] = { status: 'succeeded', error: null };
      })
      .addCase(changeDataResultSetType.rejected, (state, action) => {
        const { dataSourceId, error } = action.payload;
        state.changeDataResultSetTypeStates[dataSourceId] = { status: 'failed', error: error };
      })
      
      // changeDataSourceConnection
      .addCase(changeDataSourceConnection.pending, (state, action) => {
        state.changeDataSourceConnectionStates[action.meta.arg.dataSourceId] = { status: 'loading', error: null };
      })
      .addCase(changeDataSourceConnection.fulfilled, (state, action) => {
        const { id: dataSourceId, dataConnectionId: newDataConnectionId } = action.payload.response.message;
        state.data = state.data.map(dataSource =>
          dataSource.id === dataSourceId ? { ...dataSource, dataConnectionId: newDataConnectionId } : dataSource
        );
        state.changeDataSourceConnectionStates[dataSourceId] = { status: 'succeeded', error: null };
      })
      .addCase(changeDataSourceConnection.rejected, (state, action) => {
        const { dataSourceId, error } = action.payload;
        state.changeDataSourceConnectionStates[dataSourceId] = { status: 'failed', error: error };
      })
      
      // deleteDataSourceConnection using dataConnectionsActions from dataConnections.slice.js
      .addCase(dataConnectionsActions.deleteConnection.pending, (state, action) => {
        const { dataSourceId } = action.meta.arg;
        state.deleteDataSourceConnectionStates[dataSourceId] = { status: 'loading', error: null };
      })
      .addCase(dataConnectionsActions.deleteConnection.fulfilled, (state, action) => {
        const { dataSourceId } = action.payload;
        state.deleteDataSourceConnectionStates[dataSourceId] = { status: 'succeeded', error: null };

        // Remove the connection from the related data sources
        state.data = state.data.map(dataSource =>
          dataSource.dataConnection?.id === action.payload.dataConnectionId ? { ...dataSource, dataConnection: null } : dataSource
        );
      })
      .addCase(dataConnectionsActions.deleteConnection.rejected, (state, action) => {
        const { dataSourceId, error } = action.payload;
        state.deleteDataSourceConnectionStates[dataSourceId] = { status: 'failed', error: error };
      })
      
      // executeQuery
      .addCase(executeQuery.pending, (state, action) => {
        state.executeQueryStates[action.meta.arg.dataSourceId] = { status: 'loading', error: null };
      })
      .addCase(executeQuery.fulfilled, (state, action) => {
        const { dataSourceId, response } = action.payload;
        state.executeQueryStates[dataSourceId] = { status: 'succeeded', error: null, results: response.message };
      })
      .addCase(executeQuery.rejected, (state, action) => {
        const { dataSourceId, error } = action.payload;
        state.executeQueryStates[dataSourceId] = { status: 'failed', error: error };
      })
      
      // changeDataSourceSqlStatement
      .addCase(changeDataSourceSqlStatement.pending, (state, action) => {
        state.changeDataSourceSqlStatementStates[action.meta.arg.dataSourceId] = { status: 'loading', error: null };
      })
      .addCase(changeDataSourceSqlStatement.fulfilled, (state, action) => {
        const { id: dataSourceId, sqlStatement: newSqlStatement } = action.payload.response.message;
        state.data = state.data.map(dataSource =>
          dataSource.id === dataSourceId ? { ...dataSource, sqlStatement: newSqlStatement } : dataSource
        );
        state.changeDataSourceSqlStatementStates[dataSourceId] = { status: 'succeeded', error: null };
      })
      .addCase(changeDataSourceSqlStatement.rejected, (state, action) => {
        const { dataSourceId, error } = action.payload;
        state.changeDataSourceSqlStatementStates[dataSourceId] = { status: 'failed', error: error };
      });
  },
});

export const { resetExecuteQueryState, resetChangeDataSourceSqlStatementState } = dataSourcesSlice.actions;
export const dataSourcesReducer = dataSourcesSlice.reducer;

// Bundle and export the actions
export const dataSourcesActions = {
  getDataSources,
  createDefaultDataSource,
  changeDataSourceName,
  deleteDataSource,
  changeDataResultSetType,
  changeDataSourceConnection,
  executeQuery,
  changeDataSourceSqlStatement,
  resetExecuteQueryState,
  resetChangeDataSourceSqlStatementState,
};
