export const firstName_validation = {
  name: 'firstName',
  label: 'firstName',
  type: 'text',
  id: 'firstName',
  placeholder: 'write your firstName ...',
  validation: {
    required: {
      value: true,
      message: 'First name is required',
    },
    maxLength: {
      value: 30,
      message: '30 characters max',
    },
  },
};

export const lastName_validation = {
  name: 'lastName',
  label: 'lastName',
  type: 'text',
  id: 'lastName',
  placeholder: 'write your lastName ...',
  validation: {
    required: {
      value: true,
      message: 'Last name is required',
    },
    maxLength: {
      value: 30,
      message: '30 characters max',
    },
  },
};

export const desc_validation = {
  name: 'description',
  label: 'description',
  multiline: true,
  id: 'description',
  placeholder: 'write description ...',
  validation: {
    required: {
      value: true,
      message: 'field required',
    },
    maxLength: {
      value: 200,
      message: '200 characters max',
    },
  },
};

export const password_validation = {
  name: 'password',
  label: 'password',
  type: 'password',
  id: 'password',
  placeholder: 'type password ...',
  validation: {
    required: {
      value: true,
      message: 'Password is required',
    },
    // minLength: {
    //     value: 6,
    //     message: 'min 6 characters',
    // },
  },
};

export const num_validation = {
  name: 'num',
  label: 'number',
  type: 'number',
  id: 'num',
  placeholder: 'write a random number',
  validation: {
    required: {
      value: true,
      message: 'field required',
    },
  },
};

export const email_validation = {
  name: 'email',
  label: 'email address',
  type: 'email',
  id: 'email',
  placeholder: 'email address',
  validation: {
    required: {
      value: true,
      message: 'Email is required',
    },
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'not valid email',
    },
  },
};
