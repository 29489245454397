import './Authentication.css';

export function Authentication({ children }) {
  return (
    <div className="row d-flex justify-content-center vh-100">
      <div className="col-12 col-md-10 col-lg-8 col-xl-7 d-flex align-items-center">
        {children}
      </div>
    </div>
  );
}
