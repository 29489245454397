import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers";
import { rulesActions } from "./rules.slice";

const name = "workflows";

const initialState = {
  data: [], // Array to store workflows
  ruleEngineInputText: {}, // Object to store the input text for the rules engine by workflow
  ruleEngineResults: {}, // Object to store the results of the rules engine by workflow
  getWorkflows: {
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed',
    error: null,
  },
  createWorkflow: {
    status: "idle",
    error: null,
  },
  changeWorkflowNameStates: {},
  deleteWorkflowStates: {},
  updateWorkflowsSequence: {
    status: 'idle',
    error: null,
  },
  runRulesEngineStates: {}
};

// extra actions
const baseUrl = `${process.env.REACT_APP_API_URL ?? ""}/api`;
const getWorkflows = createAsyncThunk(
  `${name}/getWorkflows`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.get(`${baseUrl}/workflows`);
      return response;
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  },
);
const createDefaultWorkflow = createAsyncThunk(
  `${name}/createDefaultWorkflow`,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchWrapper.post(`${baseUrl}/workflows/create-default`);
      const { workflow } = response.message;
      dispatch(rulesActions.addWorkflowToRulesMap({ workflowKey: `workflow-${workflow.id}`, workflow }));
      return response;
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  },
);
const changeWorkflowName = createAsyncThunk(
  `${name}/changeWorkflowName`,
  async ({ workflowId, newName }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.patch(`${baseUrl}/workflows/${workflowId}`, { workflowName: newName });
      return { workflowId, response };
    } catch (error) {
      return rejectWithValue({ workflowId, error: error.message });
    }
  },
);
const deleteWorkflow = createAsyncThunk(
  `${name}/deleteWorkflow`,
  async ({ workflowId }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchWrapper.delete(`${baseUrl}/workflows/${workflowId}`);
      dispatch(rulesActions.clearRulesForWorkflow(workflowId));
      return { workflowId, response };
    } catch (error) {
      return rejectWithValue({ workflowId, error: error.message });
    }
  },
);
const updateWorkflowsSequence = createAsyncThunk(
  `${name}/updateSequence`,
  async ({ workflowMoved, workflowsToUpdate }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.patch(`${baseUrl}/workflows/update-sequence`, { workflows: workflowsToUpdate });
      return { workflowMoved, response };
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  },
);
const runRulesEngine = createAsyncThunk(
  `${name}/runRulesEngine`,
  async ({ workflow, inputText }, { rejectWithValue }) => {
    try {
      const inputParameters = JSON.parse(inputText);
      const response = await fetchWrapper.post(`${baseUrl}/workflows/${workflow.id}/run-rules-engine`, { workflow, inputParameters });
      return { workflow, response };
    } catch (error) {
      return rejectWithValue({ workflow, error: error.message });
    }
  },
);

const workflowsSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetGetWorkflowStatus: (state) => {
      state.getWorkflows.status = 'idle';
    },
    setInputTextForRulesEngine: (state, action) => {
      state.ruleEngineInputText[action.payload.workflow.id] = action.payload.inputText;
    }
  },
  extraReducers: (builder) => {
    builder
      // getWorkflows
      .addCase(getWorkflows.pending, (state) => {
        state.getWorkflows.status = "loading";
        state.getWorkflows.error = null;
      })
      .addCase(getWorkflows.fulfilled, (state, action) => {
        state.data = action.payload.message;
        state.getWorkflows.status = "succeeded";
      })
      .addCase(getWorkflows.rejected, (state, action) => {
        state.getWorkflows.status = "failed";
        state.getWorkflows.error = action.payload.error;
      })

      // createDefaultWorkflow
      .addCase(createDefaultWorkflow.pending, (state) => {
        state.createWorkflow.status = "loading";
        state.createWorkflow.error = null;
      })
      .addCase(createDefaultWorkflow.fulfilled, (state, action) => {
        state.data.push(action.payload.message.workflow);
        state.createWorkflow.status = "succeeded";
      })
      .addCase(createDefaultWorkflow.rejected, (state, action) => {
        state.createWorkflow.status = "failed";
        state.createWorkflow.error = action.payload.error;
      })

      // changeWorkflowName
      .addCase(changeWorkflowName.pending, (state, action) => {
        state.changeWorkflowNameStates[action.meta.arg.workflowId] = { status: 'loading', error: null };
      })
      .addCase(changeWorkflowName.fulfilled, (state, action) => {
        const { id: workflowId, workflowName: newName } = action.payload.response.message;
        state.data = state.data.map(workflow =>
          workflow.id === workflowId ? { ...workflow, workflowName: newName } : workflow
        );
        state.changeWorkflowNameStates[workflowId] = { status: 'succeeded', error: null };
      })
      .addCase(changeWorkflowName.rejected, (state, action) => {
        const { workflowId, error } = action.payload;
        state.changeWorkflowNameStates[workflowId] = { status: 'failed', error: error };
      })

      // deleteWorkflow
      .addCase(deleteWorkflow.pending, (state, action) => {
        state.deleteWorkflowStates[action.meta.arg.workflowId] = { status: 'loading', error: null };
      })
      .addCase(deleteWorkflow.fulfilled, (state, action) => {
        const workflowId = action.payload.workflowId;
        const index = state.data.findIndex(workflow => workflow.id === workflowId);
        if (index !== -1) {
          state.data.splice(index, 1);
        }
        state.deleteWorkflowStates[workflowId] = { status: 'succeeded', error: null };
      })
      .addCase(deleteWorkflow.rejected, (state, action) => {
        const { workflowId, error } = action.payload;
        state.deleteWorkflowStates[workflowId] = { status: 'failed', error: error };
      })

      // updateWorkflowsSequence
      .addCase(updateWorkflowsSequence.pending, (state) => {
        state.updateWorkflowsSequence = { status: 'loading', error: null };
      })
      .addCase(updateWorkflowsSequence.fulfilled, (state, action) => {
        const { initialIndex, finalIndex } = action.payload.workflowMoved;

        // Create a new array for immutability
        const newData = [...state.data];
    
        // Remove the item from the initial position
        const [movedItem] = newData.splice(initialIndex, 1);
    
        // Insert it at the new position
        newData.splice(finalIndex, 0, movedItem);

        // Update the state
        state.data = newData;
        state.updateWorkflowsSequence.status = 'succeeded';
      })
      .addCase(updateWorkflowsSequence.rejected, (state, action) => {
        const { error } = action.payload;
        state.updateWorkflowsSequence = { status: 'failed', error: error };
      })
      
      // runRulesEngine
      .addCase(runRulesEngine.pending, (state, action) => {
        state.runRulesEngineStates[action.meta.arg.workflow.id] = { status: 'loading', error: null };
      })
      .addCase(runRulesEngine.fulfilled, (state, action) => {
        const { workflow, response } = action.payload;
        const ruleResults = response.message;        
        state.ruleEngineResults[workflow.id] = ruleResults.map(rr => ({ 
          ruleName: rr.ruleName, 
          isSuccess: rr.isSuccess, 
          exceptionMessage: rr.exceptionMessage 
        }));
        state.runRulesEngineStates[workflow.id] = { status: 'succeeded', error: null };
      })
      .addCase(runRulesEngine.rejected, (state, action) => {
        const { workflow, error } = action.payload;
        state.runRulesEngineStates[workflow.id] = { status: 'failed', error: error };
      });
  },
});

export const { resetGetWorkflowStatus, setInputTextForRulesEngine } = workflowsSlice.actions;
export const workflowsReducer = workflowsSlice.reducer;

// Bundle and export the actions
export const workflowsActions = {
  getWorkflows,
  createDefaultWorkflow,
  changeWorkflowName,
  deleteWorkflow,
  updateWorkflowsSequence,
  resetGetWorkflowStatus,
  runRulesEngine,
  setInputTextForRulesEngine,
};
