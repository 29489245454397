// Helper function to check if a string is valid JSON
function isValidJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

// Function to replace empty strings with null values in an object
export const replaceEmptyWithNull = (obj) => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === '') {
      newObj[key] = null;
    }
  });
  return newObj;
};

// Function to recursively sort object keys and attempt to parse JSON strings
function normalizeObjectKeys(obj, sortedColumns = false) {
  if (Array.isArray(obj)) {
    return obj.map(item => normalizeObjectKeys(item, sortedColumns)).filter(arr => arr && arr.length !== 0);
  } else if (obj !== null && typeof obj === 'object') {
    const keys = sortedColumns ? Object.keys(obj).sort() : Object.keys(obj);

    const normalized = keys.reduce((acc, key) => {
      let value = obj[key];

      // Automatically detect and parse JSON strings
      if (typeof value === 'string' && isValidJsonString(value)) {
        try {
          value = JSON.parse(value); // Parse the JSON string
        } catch (error) {
          console.error('Failed to parse JSON string:', error);
        }
      } else {
        value = normalizeObjectKeys(value, sortedColumns); // Pass sortedColumns through recursively
      }

      if (value !== null && !(Array.isArray(value) && value.length === 0)) {
        acc[key] = value;
      }
      return acc;
    }, {});

    // Return null if normalized object is empty, otherwise return the normalized object
    return Object.keys(normalized).length > 0 ? normalized : null;
  }
  return obj; // Return the value as is for non-object, non-array types
}

// Function to normalize JSON by sorting keys and removing nulls and empty arrays
export function normalizeJson(json, sortedColumns = false) {
  const parsed = JSON.parse(json);
  const normalized = normalizeObjectKeys(parsed, sortedColumns);
  return JSON.stringify(normalized, null, 2); // Pretty-print the JSON
}
