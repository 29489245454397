import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../_helpers';

const name = 'reports';

const initialState = {
  reportsList: [],
  reportDetails: {},
  getAllReports: {
    status: "idle",
    error: null,
  },
  getReport: {
    status: "idle",
    error: null,
  },
  // filters are stored in the format: { reportId: { filterComponentId: { filterName: filterValue } } }
  filters: {},
  getReportComponentDataSources: {},
};

const baseUrl = `${process.env.REACT_APP_API_URL ?? ''}/api`;
const getAllReports = createAsyncThunk(
  `${name}/getAllReports`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.get(`${baseUrl}/reports`);
      return response;
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  }
);

const getReport = createAsyncThunk(
  `${name}/getReport`,
  async ({ reportId, filters }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.post(`${baseUrl}/reports/${reportId}`, filters);
      return response;
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  }
);

const getReportComponentDataSources = createAsyncThunk(
  `${name}/getReportComponentDataSources`,
  async ({ componentIds, filters }, { rejectWithValue }) => {
    try {
      const response = await fetchWrapper.post(`${baseUrl}/reports/get-components-data-sources`, { componentIds, filters });
      return { componentIds, response };
    } catch (error) {
      return rejectWithValue({ componentIds, error: error.message });
    }
  }
);

const reportsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setFilters: (state, action) => {
      const { reportId, filterComponentId, filters } = action.payload;
      state.filters[reportId] = {
        ...state.filters[reportId],
        [filterComponentId]: filters
      };
    },
  },
  extraReducers: (builder) => {
    // list reports
    builder
      .addCase(getAllReports.pending, (state) => {
        state.getAllReports = { status: "loading", error: null };
      })
      .addCase(getAllReports.fulfilled, (state, action) => {
        state.reportsList = action.payload.message.rows;
        state.getAllReports.status = 'succeeded';
      })
      .addCase(getAllReports.rejected, (state, action) => {
        const { error } = action.payload;
        state.getAllReports = { status: "failed", error: error };
      })

      // get report details
      .addCase(getReport.pending, (state) => {
        state.getReport = { status: "loading", error: null };
      })
      .addCase(getReport.fulfilled, (state, action) => {
        state.reportDetails = action.payload.message;
        state.getReport.status = 'succeeded';
      })
      .addCase(getReport.rejected, (state, action) => {
        const { error } = action.payload;
        state.getReport = { status: "failed", error: error };
      })

      // get multiple report components filtered
      .addCase(getReportComponentDataSources.pending, (state, action) => {
        const { componentIds } = action.meta.arg;
        for (const componentId of componentIds) {
          state.getReportComponentDataSources[componentId] = { status: "loading", error: null };
        }
      })
      .addCase(getReportComponentDataSources.fulfilled, (state, action) => {
        const { componentIds, response } = action.payload;
        const componentsDataSources = response.message;

        // Populate the data source results with the filtered component data
        for (const componentId of componentIds) {
          const componentResult = componentsDataSources[componentId];
          if (componentResult) {
            state.reportDetails.reportDataSourceResults[componentId] = componentResult;
          }
          state.getReportComponentDataSources[componentId] = { status: 'succeeded', error: null };
        }
      })
      .addCase(getReportComponentDataSources.rejected, (state, action) => {
        const { componentIds, error } = action.payload;
        for (const componentId of componentIds) {
          state.getReportComponentDataSources[componentId] = { status: "failed", error: error };
        }
      });
  },
});

export const { setFilters } = reportsSlice.actions;
export const reportsReducer = reportsSlice.reducer;

// Bundle and export the actions
export const reportsActions = {
  getAllReports,
  getReport,
  setFilters,
  getReportComponentDataSources,
};
