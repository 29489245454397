import cn from 'classnames';
import { findInputError, isFormInvalid } from '../utils';
import { useFormContext } from 'react-hook-form';
import { AnimatePresence, motion } from 'framer-motion';
import { MdError } from 'react-icons/md';
import { useState } from 'react';

export const Input = ({
  name,
  label,
  type,
  id,
  validation,
  multiline,
  className,
  onInputChange,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputErrors = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputErrors);

  const input = 'input-control';

  const [isActive, setIsActive] = useState(false);
  const handleInput = (e) => {
    setIsActive(e.target.value !== '');

    if (onInputChange) {
      onInputChange(e);
    }
  };

  return (
    <div className={cn('input-outline position-relative mb-4', className)}>
      <AnimatePresence mode="wait" initial={false}>
        {isInvalid && (
          <InputError
            message={inputErrors.error.message}
            key={inputErrors.error.message}
          />
        )}
      </AnimatePresence>

      {multiline ? (
        <textarea
          id={id}
          type={type}
          className={cn(input, 'min-h-[10rem] max-h-[20rem] resize-y')}
          onInput={handleInput}
          {...register(name, validation)}
        ></textarea>
      ) : (
        <input
          id={id}
          type={type}
          className={cn(
            input,
            'display-block w-100 font-weight-400',
            isActive ? 'active' : '',
          )}
          onInput={handleInput}
          {...register(name, validation)}
        />
      )}

      <label htmlFor={id} className="input-label">
        {label}
      </label>
      <div className="input-notch"></div>
    </div>
  );
};

const InputError = ({ message }) => {
  return (
    <motion.p className="input-control-error text-danger" {...framer_error}>
      <MdError />
      {message}
    </motion.p>
  );
};

const framer_error = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { duration: 0.2 },
};
