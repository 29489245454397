import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { dataConnectionsActions } from "../../_store";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import DatabaseConnection from './DatabaseConnection';
import { replaceEmptyWithNull } from '../../_helpers';

const DataConnection = ({ visible, onHide, addOrModify, connectionTypeOptions, connection }) => {
  const dispatch = useDispatch();
  const [connectionDetails, setConnectionDetails] = useState({});
  const [connectionName, setConnectionName] = useState('');
  const [connectionType, setConnectionType] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const { status: testConnectionStatus, error: testConnectionError } = useSelector((state) => state.dataConnections.testConnection);
  const { status: createConnectionStatus, error: createConnectionError } = useSelector((state) => state.dataConnections.createConnection);
  const { status: updateConnectionStatus, error: updateConnectionError } = useSelector((state) => state.dataConnections.updateConnection);

  useEffect(() => {
    dispatch(dataConnectionsActions.resetTestConnection());
    dispatch(dataConnectionsActions.resetCreateConnection());
    dispatch(dataConnectionsActions.resetUpdateConnection());

    if (connection) {
      let unifiedConnectionType;
      switch (connection.dataConnectionType) {
        case 0:
          unifiedConnectionType = 'MySQL';
          break;
        default:
          unifiedConnectionType = null;
          break;
      }

      setConnectionName(connection.name);
      setConnectionType(unifiedConnectionType);
      setConnectionDetails(connection);
    } else {
      setConnectionDetails({});
      setConnectionName('New Connection');
      setConnectionType(null);
    }
  }, [dispatch, connection, visible]);

  useEffect(() => {
    if (createConnectionStatus === 'succeeded' || updateConnectionStatus === 'succeeded') {
      onHide(); // Close the dialog
    }
  }, [createConnectionStatus, updateConnectionStatus, onHide]);

  const handleDataConnectionNameChange = (e) => {
    const newName = e.target.value.replace(/[^a-zA-Z0-9\s-_]/g, ''); // Remove most of special characters
    setConnectionName(newName);
  };

  const handleConnectionTypeChange = (e) => {
    const newType = e.value;
    setConnectionType(newType);
    const initialDetails = newType === 'MySQL' ? { serverName: 'localhost', port: 3306, userName: 'root', password: 'mysql' } : {};
    setConnectionDetails(initialDetails); // Set initial connection details based on type
  };

  const handleTestConnection = () => {
    let name = connectionName;
    if (!name || name.trim() === '') {
      name = 'DefaultName';
    }
    const connectionTest = replaceEmptyWithNull({ ...connectionDetails, name, connectionType });
    dispatch(dataConnectionsActions.testConnection({ connectionTest }));
  };

  const handleSave = () => {
    if (Object.keys(formErrors).length > 0) {
      // Display or handle form errors before saving
      return;
    }
    const connectionId = connection ? connection.id : null;
    const name = connectionName;
    const connectionUpdate = replaceEmptyWithNull({ ...connectionDetails, id: connectionId, name, connectionType });

    if (connection) {
      dispatch(dataConnectionsActions.updateConnection({ dataConnectionId: connectionId, connectionUpdate }));
    } else {
      dispatch(dataConnectionsActions.createConnection({ connection: connectionUpdate }));
    }
  };

  const handleConnectionDetailsChange = useCallback((updatedDetails) => {
    setConnectionDetails((prevDetails) => ({ ...prevDetails, ...updatedDetails }));
  }, []);

  const handleFormErrors = useCallback((errors) => {
    setFormErrors(errors);
  }, []);

  const footerContent = (
    <div className="d-flex justify-content-between">
      <Button
        label="Test Connection"
        onClick={handleTestConnection}
        severity="secondary"
        outlined
        loading={testConnectionStatus === 'loading'}
        disabled={testConnectionStatus === 'loading'}
      />
      <div className="d-flex">
        <Button label="Cancel" icon="pi pi-times" onClick={onHide} text />
        <Button
          label={addOrModify}
          icon="pi pi-check"
          onClick={handleSave}
          autoFocus
          disabled={Object.keys(formErrors).length > 0}
        />
      </div>
    </div>
  );

  const ErrorDisplay = ({ errors }) => {
    // Filter out null or undefined errors and map over them
    const validErrors = errors.filter(error => error);
    if (validErrors.length === 0) return null;
    return (
      <div className="text-danger text-center">
        {validErrors.map((error, index) => (
          <small key={index} className="d-block mt-2">Error: {error}</small> // Key is index because error messages might not be unique
        ))}
      </div>
    );
  };

  return (
    <Dialog
      header="Manage Data Connection"
      visible={visible}
      style={{ width: '50vw', minHeight: '70vh' }}
      onHide={onHide}
      footer={footerContent}
    >
      <div className="d-flex flex-column gap-2">
        <div className="inline-flex flex-column">
          <label htmlFor="connectionName" className="small">Connection Name</label>
          <InputText
            id="connectionName"
            value={connectionName}
            onChange={handleDataConnectionNameChange}
            className="w-100"
          />
        </div>
        <div className="inline-flex flex-column">
          <label htmlFor="connectionType" className="small">Connection Type</label>
          <Dropdown
            id="connectionType"
            value={connectionType}
            options={connectionTypeOptions}
            onChange={handleConnectionTypeChange}
            placeholder="Select a Connection Type"
            className="w-100 mb-3"
          />
        </div>
      </div>
      {connectionType === 'MySQL' && (
        <DatabaseConnection connection={connectionDetails} onChange={handleConnectionDetailsChange} onError={handleFormErrors} />
      )}
      {testConnectionStatus === 'succeeded' && <small className="d-block text-success text-center mt-2">Connection test succeeded!</small>}
      <ErrorDisplay errors={[createConnectionError, updateConnectionError, testConnectionError]} />
    </Dialog>
  );
};

export default DataConnection;
