export const ErrorDisplay = ({ errors }) => {
  const getErrorMessage = (error) => {
    if (typeof error === 'object' && error !== null) {
      return error.message || 'Unknown error';
    }
    return error;
  };

  const validErrors = errors.filter(error => error).map(error => getErrorMessage(error));

  if (validErrors.length === 0) return null;

  return (
    <div className="alert alert-danger alert-dismissible fade show mt-4">
      {validErrors.map((error, index) => (
        <div key={index} className="d-block">Error: {error}</div>
      ))}
    </div>
  );
};