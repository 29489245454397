import { useEffect, lazy, Suspense, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { reportsActions } from '../../_store';
import { isInteger } from '../../utils/reportHelpers';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorBoundary from '../ErrorBoundary';

// Function to dynamically import the report component based on the reportTemplate
function loadComponent(name) {
  try {
    const Component = lazy(() => import(`./templates/${name}.js`));
    return Component;
  } catch (error) {
    console.error(`Component ${name} could not be loaded`, error);
    return null;
  }
}

export const ReportViewer = () => {
  const { reportId } = useParams();
  const dispatch = useDispatch();
  const { filters, getReportComponentDataSources } = useSelector((state) => state.reports);
  const { report, reportDataSourceResults } = useSelector((state) => state.reports.reportDetails);
  const { status: getReportStatus, error: getReportError } = useSelector((state) => state.reports.getReport);

  // Fetch the report data when the reportId changes (exclude filters from dependencies)
  useEffect(() => {
    if (isInteger(reportId)) {
      const reportFilters = filters && reportId in filters ? filters[reportId] : null;
      dispatch(reportsActions.getReport({ reportId, filters: reportFilters }));
    }
  }, [dispatch, reportId]);

  // Memoize the sorted reportComponents to avoid unnecessary sorting on each render
  const sortedComponents = useMemo(() => {
    return report?.reportComponents
      ? [...report.reportComponents].sort((a, b) => a.seq - b.seq)
      : [];
  }, [report?.reportComponents]);

  // Memoized component loading for all templates
  const memoizedLoadComponent = useMemo(() => {
    const loadedComponents = {};
    sortedComponents.forEach((component) => {
      loadedComponents[component.template] = loadComponent(component.template);
    });
    return loadedComponents;
  }, [sortedComponents]);

  const handleFilterChange = (filterComponentId, updatedFilters) => {
    // 1. Update the filters in the Redux store for the specific component
    dispatch(reportsActions.setFilters({
      reportId,
      filterComponentId,
      filters: updatedFilters
    }));

    // 2. Find the affected report components that have `isServerSideFiltering` set to true
    const affectedComponents = report?.reportComponents
      .filter(component =>
        component.filteredByComponentId === filterComponentId && component.isServerSideFiltering
      )
      .map(component => component.id);

    // 3. If there are any affected components, fetch the filtered data
    if (affectedComponents.length > 0) {
      dispatch(reportsActions.getReportComponentDataSources({
        componentIds: affectedComponents,
        filters: updatedFilters,
      }));
    }
  };

  // Fetch filters from Redux for each component by componentId
  const getComponentFilters = (filterComponentId) => {
    return filters?.[reportId]?.[filterComponentId] || {};
  };

  const SpinnerComponent = () => (
    <div className="d-flex flex-grow-1 align-items-center" style={{ minHeight: '200px' }}>
      <ProgressSpinner style={{ width: '80px', height: '80px', animationDuration: '4.0s' }} strokeWidth="4" />
    </div>
  );

  // Check for valid reportId
  if (!isInteger(reportId)) {
    return (
      <div className="d-flex flex-grow-1 align-items-center justify-content-center text-center text-danger">
        Report id must be a number, current value {reportId}
      </div>
    );
  }

  // Handle errors during data fetching
  if (getReportError) {
    return (
      <div className="d-flex flex-grow-1 align-items-center justify-content-center text-center text-danger">
        Error loading report:<br />
        {getReportError}
      </div>
    );
  }

  // Show loading spinner while data is being fetched
  if (getReportStatus === 'loading') {
    return <SpinnerComponent />;
  }

  return (
    <div className="d-flex flex-grow-1 flex-column h-100 gap-2 py-2">
      <h1>{report && report.name}</h1>
      {sortedComponents.map((component) => {
        const { className, style, pagination, pivotColumn } = component.layoutProperties;

        // Dynamically load the report component based on reportTemplate
        const ReportComponent = memoizedLoadComponent[component.template];
        const componentFilters = getComponentFilters(component.id);
        const componentDataSource = component.id in reportDataSourceResults ? reportDataSourceResults[component.id] : {};
        const sortedAndFilteredMappings = Object.entries(componentDataSource.mappings)
          .filter(([, mapping]) => mapping.fieldType)
          .sort(([, a], [, b]) => a.seq - b.seq)
          .reduce((acc, [key, mapping]) => {
            acc[key] = mapping;
            return acc;
          }, {});

        // For those components that require filtering and avoid double filtering when is server-side filtering
        const componentFiltering = !component.isServerSideFiltering ? getComponentFilters(component.filteredByComponentId) : {};

        // Fetch the status and error for the component data if server-side filtering is enabled
        const { status: componentDataFetchStatus, error: componentDataFetchError } = (getReportComponentDataSources[component.id] ?? {});

        return (
          <div key={component.id} className={className} style={style}>
            {componentDataFetchStatus === 'loading' ? (
              <SpinnerComponent />
            ) : componentDataFetchStatus === 'failed' ? (
              <div className="d-flex flex-grow-1 align-items-center">Error loading component: {componentDataFetchError}</div>
            ) : (
              <Suspense
                fallback={
                  <SpinnerComponent />
                }
              >
                <ErrorBoundary>
                  {ReportComponent ? (
                    <ReportComponent
                      reportId={reportId}
                      componentId={component.id}
                      data={componentDataSource.data}
                      mappings={sortedAndFilteredMappings}
                      filters={componentFilters}
                      filtering={componentFiltering}
                      onFilterChange={handleFilterChange}
                      pagination={pagination}
                      pivotColumn={pivotColumn}
                    />
                  ) : (
                    <div>Error: Template {component.template} could not be loaded</div>
                  )}
                </ErrorBoundary>
              </Suspense>
            )}
          </div>
        );
      })}
    </div>
  );
};
