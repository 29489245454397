import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { preAuthorizationsActions } from '../../_store';
import { DentalClaimForm } from '../claims/DentalClaimForm';
import { ProgressSpinner } from 'primereact/progressspinner';
import { isInteger } from '../../utils/reportHelpers';
import { isUserGroupAllowed, getUserFullNameFromCognitoToken } from '../../_helpers';
import ReviewConfirmationDialog from "./ReviewConfirmationDialog";

export const PreAuthorizationForm = () => {
  const dispatch = useDispatch();
  const { preAuthorizationId } = useParams();
  const { preAuthorizationDetails } = useSelector((state) => state.preAuthorizations);
  const { status: getPreAuthDetailsStatus, error: getPreAuthDetailsError } = useSelector((state) => state.preAuthorizations.getPreAuthorizationDetails);

  const { authUser } = useSelector((state) => state.auth);
  const [currentAction, setCurrentAction] = useState(null);
  const [isConfirmReviewDialogVisible, setConfirmReviewDialogVisible] = useState(false);

  const {
    status: reviewPreAuthorizationStatus,
    error: reviewPreAuthorizationError,
    reviewType: reviewPreAuthorizationType,
  } = useSelector((state) => state.preAuthorizations.reviewPreAuthorization);
  const isReviewLoading = reviewPreAuthorizationStatus === 'loading';

  useEffect(() => {
    if (isInteger(preAuthorizationId)) {
      dispatch(preAuthorizationsActions.getPreAuthorizationDetails({ preAuthorizationId }));
    }
  }, [dispatch, preAuthorizationId]);

  const handleReviewSubmit = (reason) => {
    const parsedReason = reason.trim() !== '' ? reason.trim() : null;
    if (currentAction === 'reject' && parsedReason === null) {
      return;
    }

    const userFullName = getUserFullNameFromCognitoToken(authUser);
    const nowDate = new Date().toISOString();

    const preAuthorizationReview = {
      reviewedByMonitorName: (preAuthorizationDetails.statusLevel === 2) ? userFullName : null,
      reviewedByMonitorDate: (preAuthorizationDetails.statusLevel === 2) ? nowDate : null,
      reviewedByMonitorNotes: (preAuthorizationDetails.statusLevel === 2) ? parsedReason : null,
      reviewedByDentistName: (preAuthorizationDetails.statusLevel === 3) ? userFullName : null,
      reviewedByDentistDate: (preAuthorizationDetails.statusLevel === 3) ? nowDate : null,
      reviewedByDentistNotes: (preAuthorizationDetails.statusLevel === 3) ? parsedReason : null,
      statusLevel: preAuthorizationDetails.statusLevel,
      reviewType: currentAction,
    };

    dispatch(
      preAuthorizationsActions.reviewPreAuthorization({
        preAuthorizationId,
        preAuthorizationReview,
      })
    );
  };

  const openConfirmDialog = (_, actionType) => {
    setCurrentAction(actionType);
    setConfirmReviewDialogVisible(true);
  };

  // Check for valid preAuthorizationId
  if (!isInteger(preAuthorizationId)) {
    return (
      <div className="d-flex flex-grow-1 align-items-center justify-content-center text-center text-danger">
        Pre Authorization id must be a number, current value {preAuthorizationId}
      </div>
    );
  }

  // Handle errors during data fetching
  if (getPreAuthDetailsError) {
    return (
      <div className="d-flex flex-grow-1 align-items-center justify-content-center text-center text-danger">
        Error loading report:<br />
        {getPreAuthDetailsError}
      </div>
    );
  }

  // Show loading spinner while data is being fetched
  if (getPreAuthDetailsStatus === 'loading') {
    return (
      <div className="d-flex flex-grow-1 align-items-center">
        <ProgressSpinner style={{ width: '80px', height: '80px', animationDuration: '4.0s' }} strokeWidth="4" />
      </div>
    );
  }

  if (!preAuthorizationDetails) return null;

  const isUserAllowedToReviewPreAuth = (
    // Automatic Approved - Waiting for biller
    (preAuthorizationDetails.statusLevel === 2 && isUserGroupAllowed(authUser, ['TPA-Monitors'])) ||
    // Biller Approved - Waiting Dentist
    (preAuthorizationDetails.statusLevel === 3 && isUserGroupAllowed(authUser, ['TPA-Dentists']))
  );

  let reviewButtonTooltip = null;
  if (!isUserAllowedToReviewPreAuth) {
    if (preAuthorizationDetails.statusLevel === 2) {
      reviewButtonTooltip = 'Only TPA Monitors can review this Pre Authorization';
    } else if (preAuthorizationDetails.statusLevel === 3) {
      reviewButtonTooltip = 'Only TPA Dentists can review this Pre Authorization';
    } else if ([5, 6, 8, 9].includes(preAuthorizationDetails.statusLevel)) {
      reviewButtonTooltip = 'This Pre Authorization has already been reviewed';
    } else {
      reviewButtonTooltip = 'Pre Authorization is in an unknown state';
    }
  }

  return (
    <>
      <DentalClaimForm
        formValues={preAuthorizationDetails}
        submitButton={{
          label: 'Approve',
          onClick: (data) => { openConfirmDialog(data, 'approve'); },
          loading: isReviewLoading && currentAction === 'approve',
          disabled: !isUserAllowedToReviewPreAuth || isReviewLoading,
          tooltip: reviewButtonTooltip,
        }}
        rejectButton={{
          label: 'Reject',
          onClick: (data) => { openConfirmDialog(data, 'reject'); },
          loading: isReviewLoading && currentAction === 'reject',
          disabled: !isUserAllowedToReviewPreAuth || isReviewLoading,
          tooltip: reviewButtonTooltip,
        }}
        reviewPreAuthorization={{
          status: reviewPreAuthorizationStatus,
          error: reviewPreAuthorizationError,
          reviewType: reviewPreAuthorizationType,
        }}
        readOnly={true}
        attachments={preAuthorizationDetails?.attachments || []}
        extraFormProps={{
          setTotalFeeAutomatically: false,
          compareTotalFeesBetweenInputAndUpdates: true,
          submitButtonText: 'Approve',
          showRejectButton: true,
        }}
      />
      <ReviewConfirmationDialog
        isVisible={isConfirmReviewDialogVisible}
        onHide={() => setConfirmReviewDialogVisible(false)}
        currentAction={currentAction}
        handleReviewSubmit={handleReviewSubmit} />
    </>
  );
};
